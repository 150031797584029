import React, {ReactElement} from "react";
import classNames from "classnames";
import {toParChecker} from "../utils/toParChecker";
import {Placing} from "client";

interface IProps {
	placing: Placing;
	incoming: boolean;
	resting: boolean;
	outbound: boolean;
}

function AnimatedLeaderBoard2RowContent(props: IProps): ReactElement {

	if (!props.placing) {
		return null;
	}

	return (
		<div
			className={classNames("animated-board2-row-content-swiper", {
				"animated-board2-row-content-swiper_incoming-start": props.incoming,
				"animated-board2-row-content-swiper_resting": props.resting,
				"animated-board2-row-content-swiper_outbound": props.outbound,
			})}
		>
			<div className="animated-board2-row-content-swiper_placing">
				<p>
					{props.placing.position.label.slice(0, -1)}
				</p>
			</div>

			<div className="animated-board2-row-content-swiper_details">
				<p className="animated-board2-row-content-swiper_details_label">
					{props.placing.name.label}

				</p>
				<p className="animated-board2-row-content-swiper_details_subtitle">
					{props.placing.name.subtitle}
				</p>
			</div>

			<div className="animated-board2-row-content-swiper_to-par">
				<p>
					{toParChecker(props.placing.toPar.label).display}
				</p>
			</div>

			<div className="animated-board2-row-content-swiper_score">
				<p>
					{props.placing.score.label}
				</p>
			</div>
		</div>
	);
}

export default AnimatedLeaderBoard2RowContent;