"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateGolfCourseBodyToJSON = exports.UpdateGolfCourseBodyFromJSONTyped = exports.UpdateGolfCourseBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function UpdateGolfCourseBodyFromJSON(json) {
    return UpdateGolfCourseBodyFromJSONTyped(json, false);
}
exports.UpdateGolfCourseBodyFromJSON = UpdateGolfCourseBodyFromJSON;
function UpdateGolfCourseBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'golfCourseID': json['golfCourseID'],
        'golfCourseType': _1.GolfCourseTypeFromJSON(json['golfCourseType']),
        'courseRating': !runtime_1.exists(json, 'courseRating') ? undefined : json['courseRating'],
        'menCourseRating': !runtime_1.exists(json, 'menCourseRating') ? undefined : json['menCourseRating'],
        'womenCourseRating': !runtime_1.exists(json, 'womenCourseRating') ? undefined : json['womenCourseRating'],
        'slopeRating': !runtime_1.exists(json, 'slopeRating') ? undefined : json['slopeRating'],
        'menSlopeRating': !runtime_1.exists(json, 'menSlopeRating') ? undefined : json['menSlopeRating'],
        'womenSlopeRating': !runtime_1.exists(json, 'womenSlopeRating') ? undefined : json['womenSlopeRating'],
        'holes': (runtime_1.mapValues(json['holes'], _1.HoleInfoFromJSON)),
        'address': !runtime_1.exists(json, 'address') ? undefined : json['address'],
        'country': !runtime_1.exists(json, 'country') ? undefined : json['country'],
        'province': !runtime_1.exists(json, 'province') ? undefined : json['province'],
        'golfClub': !runtime_1.exists(json, 'golfClub') ? undefined : json['golfClub'],
    };
}
exports.UpdateGolfCourseBodyFromJSONTyped = UpdateGolfCourseBodyFromJSONTyped;
function UpdateGolfCourseBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'golfCourseID': value.golfCourseID,
        'golfCourseType': _1.GolfCourseTypeToJSON(value.golfCourseType),
        'courseRating': value.courseRating,
        'menCourseRating': value.menCourseRating,
        'womenCourseRating': value.womenCourseRating,
        'slopeRating': value.slopeRating,
        'menSlopeRating': value.menSlopeRating,
        'womenSlopeRating': value.womenSlopeRating,
        'holes': (runtime_1.mapValues(value.holes, _1.HoleInfoToJSON)),
        'address': value.address,
        'country': value.country,
        'province': value.province,
        'golfClub': value.golfClub,
    };
}
exports.UpdateGolfCourseBodyToJSON = UpdateGolfCourseBodyToJSON;
