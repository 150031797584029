"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendUrlsBodyToJSON = exports.SendUrlsBodyFromJSONTyped = exports.SendUrlsBodyFromJSON = void 0;
function SendUrlsBodyFromJSON(json) {
    return SendUrlsBodyFromJSONTyped(json, false);
}
exports.SendUrlsBodyFromJSON = SendUrlsBodyFromJSON;
function SendUrlsBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tournamentId': json['tournamentId'],
        'useSms': json['useSms'],
        'useEmail': json['useEmail'],
    };
}
exports.SendUrlsBodyFromJSONTyped = SendUrlsBodyFromJSONTyped;
function SendUrlsBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tournamentId': value.tournamentId,
        'useSms': value.useSms,
        'useEmail': value.useEmail,
    };
}
exports.SendUrlsBodyToJSON = SendUrlsBodyToJSON;
