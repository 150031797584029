"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TournamentTypeToJSON = exports.TournamentTypeFromJSONTyped = exports.TournamentTypeFromJSON = exports.TournamentType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var TournamentType;
(function (TournamentType) {
    TournamentType["SCRAMBLE"] = "SCRAMBLE";
    TournamentType["BESTBALLSTROKEPLAY"] = "BEST_BALL_STROKE_PLAY";
    TournamentType["AUDI"] = "AUDI";
    TournamentType["STROKEPLAY"] = "STROKE_PLAY";
    TournamentType["MATCHPLAYINDIVIDUAL"] = "MATCH_PLAY_INDIVIDUAL";
    TournamentType["MATCHPLAYBETTERBALL"] = "MATCH_PLAY_BETTER_BALL";
    TournamentType["MATCHPLAYGREENSOME"] = "MATCH_PLAY_GREENSOME";
    TournamentType["MATCHPLAYFOURSOME"] = "MATCH_PLAY_FOURSOME";
    TournamentType["REDVSBLUERYDERSTYLEEVENT"] = "RED_VS_BLUE_RYDER_STYLE_EVENT";
})(TournamentType = exports.TournamentType || (exports.TournamentType = {}));
function TournamentTypeFromJSON(json) {
    return TournamentTypeFromJSONTyped(json, false);
}
exports.TournamentTypeFromJSON = TournamentTypeFromJSON;
function TournamentTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TournamentTypeFromJSONTyped = TournamentTypeFromJSONTyped;
function TournamentTypeToJSON(value) {
    return value;
}
exports.TournamentTypeToJSON = TournamentTypeToJSON;
