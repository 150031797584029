import React, {ReactElement, useEffect, useRef, useState} from "react";
import {TeamWithPlayersWithCalculatedHandicap} from "client";
import {timer} from "../pages/AnimatedLeaderBoardPage2";
import {offsetModifier} from "./AnimatedLeaderBoard2Row";
import AnimatedLeaderBoard2RowContent from "./AnimatedLeaderBoard2RowContent";
import AnimatedPlayerDetailsRowContent from "./AnimatedPlayerDetailsRowContent";

interface IProps {
	teams: Array<TeamWithPlayersWithCalculatedHandicap>;
	teamsPerPage: number;
	offset: number;
	animationDisabled: boolean;
	isShotgun: boolean;
}

function AnimatedPlayerDetailsRow(props: IProps): ReactElement {
	// if we're only dealing with 1 page of placings, disable timing function
	const localTimer = props.teams.length > 1 ? timer : 99999; //todo change logic
	const incomingTeamRef = useRef<TeamWithPlayersWithCalculatedHandicap>();
	const currentTeamRef = useRef<TeamWithPlayersWithCalculatedHandicap>();

	const [keyStrat, setKeyStrat] = useState(0);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [moveIncoming, setMoveIncoming] = useState(false);
	const [moveOutbound, setMoveOutbound] = useState(false);

	/**
	 * Run this logic for swapping the displayed placing & animating in/out the "old" & "new" placing whenever currentIndex
	 * changes - it should only be updated as a result of the final setTimeout in this useEffect based on the defined timer length.
	 * Any spots where we do: setKeyStrat(k => k + 1); is to force re-renders because we are trying to display data we store on refs.
	 */
	useEffect(() => {
		// First, set the 2 data refs to the correct values -> reset currentPlacingRef, and set incomingPlacingRef to the new one based on currentIndex.
		currentTeamRef.current = null;
		incomingTeamRef.current = props.teams[currentIndex];
		setKeyStrat(k => k + 1);

		// If this is the implementation on the TO portal dashboard in the iframe, after setting the initial item to render; stop,
		if (props.animationDisabled) {
			return;
		}

		// After a tiny delay, start animating in the new data, using props.offset to differentiate each instance of this component for the staggering.
		setTimeout(() => {
			setMoveIncoming(true);
			setMoveOutbound(false);
		}, 100 + (props.offset * offsetModifier));

		// A short bit before we swap the data over, we start animating out the "current" placing, and the props.offset allows staggering each instance of the component again.
		// Also subtract a little extra so no instant cut off of data changing.
		setTimeout(() => {
			setMoveIncoming(false);
			setMoveOutbound(true);
		}, localTimer - (props.teamsPerPage * offsetModifier) + (props.offset * offsetModifier) - 1250);

		// At some point before this useEffect runs again & after the incoming placing finishes moving but before the outgoing placing starts moving, we want to "swap" the
		// incomingPlacingRef into the currentPlacingRef, and reset incomingPlacingRef. This happens when both rendered UI instances of this component's content are "overlapped"
		// in the UI, and allows us to easily animate it in with one, but animate out with the other.
		setTimeout(() => {
			currentTeamRef.current = incomingTeamRef.current;
			incomingTeamRef.current = null;
			setKeyStrat(k => k + 1);
		}, localTimer / 2);

		// On this timeout we update which placing we want to display, resetting to the 0th one once we reach the end.
		// Effectively acts as an interval because this timeout activates this same useEffect with each update.
		setTimeout(() => {
			setCurrentIndex((c) => {
				if (c + 1 === props.teams.length) {
					return 0;
				}

				return c + 1;
			});
		}, localTimer);
	}, [currentIndex]);

	return (
		<div className="animated-player-details-row">
			<div className="animated-player-details-row_content">
				{props.animationDisabled ? (
					<AnimatedPlayerDetailsRowContent
						team={incomingTeamRef.current}
						incoming={false}
						resting={true}
						outbound={false}
						isShotgun={props.isShotgun}
					/>
				) : (
					<React.Fragment>
						<AnimatedPlayerDetailsRowContent
							team={incomingTeamRef.current}
							incoming={true}
							resting={moveIncoming}
							outbound={false}
							isShotgun={props.isShotgun}
						/>

						<AnimatedPlayerDetailsRowContent
							team={currentTeamRef.current}
							incoming={false}
							resting={true}
							outbound={moveOutbound}
							isShotgun={props.isShotgun}
						/>
					</React.Fragment>
				)}
			</div>
		</div>
	);
}

export default AnimatedPlayerDetailsRow;