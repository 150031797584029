import React, {ReactNode} from "react";
import {IToParCheck, toParChecker} from "../utils/toParChecker";

interface IProps {
	scoreToPar: number;
	isNet: boolean;
	counters: string[];
}

const ScoreCardFooter: React.FC<IProps> = (props: IProps) => {

	const {scoreToPar, isNet, counters} = props;

	const parCheck: IToParCheck = toParChecker(scoreToPar);

	function mapCounters(_counters: string[] = []): ReactNode {
		let fontSize: string = "1.2rem";
		switch (_counters.length) {
			case 1:
				fontSize = "1.2rem";
				break;
			case 2:
				fontSize = "1.1rem";
				break;
			case 3:
			default:
				fontSize = "1rem";
				break;
		}

		return _counters.map((_counter: string, i: number) => {
			return (
				<p key={`counter-string-${i}`} className="text-center mb-1 font-weight-semi-bold" style={{fontSize}}>
					{_counter}
				</p>
			);
		});
	}

	return (
		<div className="score-card-header rounded-score-card-header user-select-none">
			<div className="w-100 d-flex text-light score-card-header_bg">
				<div className="score-card-left-columns border-right border-light d-flex flex-column align-items-center justify-content-center" style={{padding: "0.75rem"}}>
					<p className="mb-0 text-center font-weight-semi-bold" style={{fontSize: "1rem"}}>
						{isNet ? `Team Net Score ` : `Team Gross Score `}
						<span className={parCheck.className} style={{fontSize: "1.3rem"}}>{parCheck.display}</span>
					</p>
				</div>

				<div className="score-card-right-columns" style={{padding: "0.75rem"}}>
					{mapCounters(counters)}
				</div>
			</div>
		</div>
	);
};

export default ScoreCardFooter;
