import React from "react";
import {FiX} from "react-icons/all";

interface IProps {
	title?: string;
	onClick(): void;
}

const CustomModalHeader: React.FC<IProps> = (props: IProps) => {

	function linkClickHelper(e?): void {
		if (e) {
			e.preventDefault();
		}

		props.onClick();
	}

	return (
		<div className="custom-modal-header">
			<div className="close-icon-container"><a className="text-light" href="#" onClick={linkClickHelper}><FiX className="close-icon"/></a></div>

			<div>
				<h5 className="mb-0 text-white text-center">
					{props?.title}
				</h5>
			</div>
		</div>
	);
};

export default CustomModalHeader;
