"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnauthenticatedGetLeaderboardsResponseToJSON = exports.UnauthenticatedGetLeaderboardsResponseFromJSONTyped = exports.UnauthenticatedGetLeaderboardsResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function UnauthenticatedGetLeaderboardsResponseFromJSON(json) {
    return UnauthenticatedGetLeaderboardsResponseFromJSONTyped(json, false);
}
exports.UnauthenticatedGetLeaderboardsResponseFromJSON = UnauthenticatedGetLeaderboardsResponseFromJSON;
function UnauthenticatedGetLeaderboardsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'animatedLeaderboardBanner': !runtime_1.exists(json, 'animatedLeaderboardBanner') ? undefined : _1.AssetFromJSON(json['animatedLeaderboardBanner']),
        'leaderboardCollection': _1.LeaderboardCollectionFromJSON(json['leaderboardCollection']),
    };
}
exports.UnauthenticatedGetLeaderboardsResponseFromJSONTyped = UnauthenticatedGetLeaderboardsResponseFromJSONTyped;
function UnauthenticatedGetLeaderboardsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'animatedLeaderboardBanner': _1.AssetToJSON(value.animatedLeaderboardBanner),
        'leaderboardCollection': _1.LeaderboardCollectionToJSON(value.leaderboardCollection),
    };
}
exports.UnauthenticatedGetLeaderboardsResponseToJSON = UnauthenticatedGetLeaderboardsResponseToJSON;
