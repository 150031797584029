import React, {useEffect} from "react";
import {connect} from "react-redux";
import {login} from "../redux/meta/MetaActions";

interface IProps {
	match?: any;
	dispatch?: any;
}

// this function will handle putting the token in redux
const TokenHandler: React.FC<IProps> = (props) => {

	const token = props.match.params.token;

	useEffect(() => {
		if (token && token !== "404" && token !== "animated-leader-board") {
			props.dispatch(login(token));
		}
	}, [token])

	return null
}

export default connect()(TokenHandler);