"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditTeamBodyToJSON = exports.EditTeamBodyFromJSONTyped = exports.EditTeamBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
function EditTeamBodyFromJSON(json) {
    return EditTeamBodyFromJSONTyped(json, false);
}
exports.EditTeamBodyFromJSON = EditTeamBodyFromJSON;
function EditTeamBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'teamID': json['teamID'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'startTime': !runtime_1.exists(json, 'startTime') ? undefined : json['startTime'],
        'startHole': !runtime_1.exists(json, 'startHole') ? undefined : json['startHole'],
        'identifier': !runtime_1.exists(json, 'identifier') ? undefined : json['identifier'],
        'teamHandicap': !runtime_1.exists(json, 'teamHandicap') ? undefined : json['teamHandicap'],
    };
}
exports.EditTeamBodyFromJSONTyped = EditTeamBodyFromJSONTyped;
function EditTeamBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'teamID': value.teamID,
        'name': value.name,
        'startTime': value.startTime,
        'startHole': value.startHole,
        'identifier': value.identifier,
        'teamHandicap': value.teamHandicap,
    };
}
exports.EditTeamBodyToJSON = EditTeamBodyToJSON;
