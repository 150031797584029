"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlacingToJSON = exports.PlacingFromJSONTyped = exports.PlacingFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PlacingFromJSON(json) {
    return PlacingFromJSONTyped(json, false);
}
exports.PlacingFromJSON = PlacingFromJSON;
function PlacingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'male': !runtime_1.exists(json, 'male') ? undefined : json['male'],
        'position': _1.PlacingDataPointFromJSON(json['position']),
        'name': _1.PlacingDataPointFromJSON(json['name']),
        'toPar': _1.PlacingDataPointFromJSON(json['toPar']),
        'thru': _1.PlacingDataPointFromJSON(json['thru']),
        'score': _1.PlacingDataPointFromJSON(json['score']),
        'scoreCard': _1.ScoreCardFromJSON(json['scoreCard']),
    };
}
exports.PlacingFromJSONTyped = PlacingFromJSONTyped;
function PlacingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'male': value.male,
        'position': _1.PlacingDataPointToJSON(value.position),
        'name': _1.PlacingDataPointToJSON(value.name),
        'toPar': _1.PlacingDataPointToJSON(value.toPar),
        'thru': _1.PlacingDataPointToJSON(value.thru),
        'score': _1.PlacingDataPointToJSON(value.score),
        'scoreCard': _1.ScoreCardToJSON(value.scoreCard),
    };
}
exports.PlacingToJSON = PlacingToJSON;
