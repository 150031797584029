"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalculatedHandicapToJSON = exports.CalculatedHandicapFromJSONTyped = exports.CalculatedHandicapFromJSON = void 0;
const runtime_1 = require("../runtime");
function CalculatedHandicapFromJSON(json) {
    return CalculatedHandicapFromJSONTyped(json, false);
}
exports.CalculatedHandicapFromJSON = CalculatedHandicapFromJSON;
function CalculatedHandicapFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'calculatedHandicap': !runtime_1.exists(json, 'calculatedHandicap') ? undefined : json['calculatedHandicap'],
    };
}
exports.CalculatedHandicapFromJSONTyped = CalculatedHandicapFromJSONTyped;
function CalculatedHandicapToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'calculatedHandicap': value.calculatedHandicap,
    };
}
exports.CalculatedHandicapToJSON = CalculatedHandicapToJSON;
