import React, {useState} from "react";
import {Placing, Tournament} from "client";
import LeaderboardTableDetailedScoreModal from "./LeaderboardTableDetailedScoreModal";

interface IProps {
	placing: Placing;
	showDetailedScores: boolean;
	tournament?: Tournament;
}

const LeaderboardTableNameCell: React.FC<IProps> = (props) => {

	const [showDetailedScoreModal, setShowDetailedScoreModal] = useState(false);

	/**
	 * Show or hide the modal.
	 *
	 * @param e
	 */
	function toggleDetailedScore(e?): void {
		e?.preventDefault();
		setShowDetailedScoreModal(!showDetailedScoreModal);
	}

	return (
		<React.Fragment>
			{props.showDetailedScores && (
				<LeaderboardTableDetailedScoreModal
					isOpen={showDetailedScoreModal}
					onClose={toggleDetailedScore}
					placing={props.placing}
					tournament={props.tournament}
				/>
			)}

			<div className="team-name-column">
				<p className="mb-0 font-weight-semi-bold">{props.placing?.name?.label}</p>
				<p className="mb-0 font-weight-light">{props.placing?.name?.subtitle}</p>

				{props.showDetailedScores && (
					<a
						href=""
						onClick={toggleDetailedScore}
						className="team-name-column_detailed-score-button"
					>
						View Scorecard
					</a>
				)}
			</div>
		</React.Fragment>
	);
};

export default LeaderboardTableNameCell;
