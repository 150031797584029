import InitialMetaStore, {IMetaStore} from "./meta/InitialMetaStore";
import InitialTournamentInformationStore, {ITournamentInformationStore} from "./tournamentInformation/InitialTournamentInformationStore";

export interface IStore {
	metaStore: IMetaStore;
	tournamentInformationStore: ITournamentInformationStore;
}

export default {
	metaStore: InitialMetaStore,
	tournamentInformationStore: InitialTournamentInformationStore,
} as IStore;
