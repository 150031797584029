"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerDataFromCSVToJSON = exports.PlayerDataFromCSVFromJSONTyped = exports.PlayerDataFromCSVFromJSON = void 0;
function PlayerDataFromCSVFromJSON(json) {
    return PlayerDataFromCSVFromJSONTyped(json, false);
}
exports.PlayerDataFromCSVFromJSON = PlayerDataFromCSVFromJSON;
function PlayerDataFromCSVFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'Team_Number': json['Team_Number'],
        'Starting_Hole': json['Starting_Hole'],
        'Start_Time': json['Start_Time'],
        'First_Name': json['First_Name'],
        'Last_Name': json['Last_Name'],
        'Team_Name': json['Team_Name'],
        'Phone_Number': json['Phone_Number'],
        'Email': json['Email'],
        'Gender': json['Gender'],
        'Handicap': json['Handicap'],
    };
}
exports.PlayerDataFromCSVFromJSONTyped = PlayerDataFromCSVFromJSONTyped;
function PlayerDataFromCSVToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'Team_Number': value.Team_Number,
        'Starting_Hole': value.Starting_Hole,
        'Start_Time': value.Start_Time,
        'First_Name': value.First_Name,
        'Last_Name': value.Last_Name,
        'Team_Name': value.Team_Name,
        'Phone_Number': value.Phone_Number,
        'Email': value.Email,
        'Gender': value.Gender,
        'Handicap': value.Handicap,
    };
}
exports.PlayerDataFromCSVToJSON = PlayerDataFromCSVToJSON;
