"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminPostMessageBodyToJSON = exports.AdminPostMessageBodyFromJSONTyped = exports.AdminPostMessageBodyFromJSON = void 0;
function AdminPostMessageBodyFromJSON(json) {
    return AdminPostMessageBodyFromJSONTyped(json, false);
}
exports.AdminPostMessageBodyFromJSON = AdminPostMessageBodyFromJSON;
function AdminPostMessageBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tournamentID': json['tournamentID'],
        'message': json['message'],
    };
}
exports.AdminPostMessageBodyFromJSONTyped = AdminPostMessageBodyFromJSONTyped;
function AdminPostMessageBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tournamentID': value.tournamentID,
        'message': value.message,
    };
}
exports.AdminPostMessageBodyToJSON = AdminPostMessageBodyToJSON;
