"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddNewGolfCourseBodyToJSON = exports.AddNewGolfCourseBodyFromJSONTyped = exports.AddNewGolfCourseBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AddNewGolfCourseBodyFromJSON(json) {
    return AddNewGolfCourseBodyFromJSONTyped(json, false);
}
exports.AddNewGolfCourseBodyFromJSON = AddNewGolfCourseBodyFromJSON;
function AddNewGolfCourseBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'golfCourseType': _1.GolfCourseTypeFromJSON(json['golfCourseType']),
        'courseRating': !runtime_1.exists(json, 'courseRating') ? undefined : json['courseRating'],
        'maleCourseRating': !runtime_1.exists(json, 'maleCourseRating') ? undefined : json['maleCourseRating'],
        'femaleCourseRating': !runtime_1.exists(json, 'femaleCourseRating') ? undefined : json['femaleCourseRating'],
        'slopeRating': !runtime_1.exists(json, 'slopeRating') ? undefined : json['slopeRating'],
        'maleSlopeRating': !runtime_1.exists(json, 'maleSlopeRating') ? undefined : json['maleSlopeRating'],
        'femaleSlopeRating': !runtime_1.exists(json, 'femaleSlopeRating') ? undefined : json['femaleSlopeRating'],
        'holes': (runtime_1.mapValues(json['holes'], _1.HoleInfoFromJSON)),
        'address': !runtime_1.exists(json, 'address') ? undefined : json['address'],
        'country': !runtime_1.exists(json, 'country') ? undefined : json['country'],
        'province': !runtime_1.exists(json, 'province') ? undefined : json['province'],
        'golfClub': json['golfClub'],
    };
}
exports.AddNewGolfCourseBodyFromJSONTyped = AddNewGolfCourseBodyFromJSONTyped;
function AddNewGolfCourseBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'golfCourseType': _1.GolfCourseTypeToJSON(value.golfCourseType),
        'courseRating': value.courseRating,
        'maleCourseRating': value.maleCourseRating,
        'femaleCourseRating': value.femaleCourseRating,
        'slopeRating': value.slopeRating,
        'maleSlopeRating': value.maleSlopeRating,
        'femaleSlopeRating': value.femaleSlopeRating,
        'holes': (runtime_1.mapValues(value.holes, _1.HoleInfoToJSON)),
        'address': value.address,
        'country': value.country,
        'province': value.province,
        'golfClub': value.golfClub,
    };
}
exports.AddNewGolfCourseBodyToJSON = AddNewGolfCourseBodyToJSON;
