"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaderboardTypeToJSON = exports.LeaderboardTypeFromJSONTyped = exports.LeaderboardTypeFromJSON = exports.LeaderboardType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var LeaderboardType;
(function (LeaderboardType) {
    LeaderboardType["TeamGross"] = "teamGross";
    LeaderboardType["TeamNet"] = "teamNet";
    LeaderboardType["TeamGrossStableford"] = "teamGrossStableford";
    LeaderboardType["TeamNetStableford"] = "teamNetStableford";
    LeaderboardType["IndividualGross"] = "individualGross";
    LeaderboardType["IndividualNet"] = "individualNet";
    LeaderboardType["IndividualGrossStableford"] = "individualGrossStableford";
    LeaderboardType["IndividualNetStableford"] = "individualNetStableford";
})(LeaderboardType = exports.LeaderboardType || (exports.LeaderboardType = {}));
function LeaderboardTypeFromJSON(json) {
    return LeaderboardTypeFromJSONTyped(json, false);
}
exports.LeaderboardTypeFromJSON = LeaderboardTypeFromJSON;
function LeaderboardTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.LeaderboardTypeFromJSONTyped = LeaderboardTypeFromJSONTyped;
function LeaderboardTypeToJSON(value) {
    return value;
}
exports.LeaderboardTypeToJSON = LeaderboardTypeToJSON;
