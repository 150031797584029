import React, {useEffect} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {AssetApi} from "client";
import getConfig from "../utils/getConfig";
import {clearTournamentAds, updateTournamentAds} from "../redux/tournamentInformation/TournamentInformationActions";

interface IProps {
	token?: string;
	dispatch?: any;
}

const AdChecker: React.FC<IProps> = (props: IProps) => {

	useEffect(() => {
		if (props.token) {
			readAds().then().catch();
		}
	}, [props.token]);

	async function readAds(): Promise<void> {
		try {
			const res = await new AssetApi(getConfig(props.token)).playerGetPairedTournamentAssets();
			props.dispatch(updateTournamentAds(res));
		} catch (e) {
			props.dispatch(clearTournamentAds());
		}
	}

	return null;
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(AdChecker);
