"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GolfCourseTypeToJSON = exports.GolfCourseTypeFromJSONTyped = exports.GolfCourseTypeFromJSON = exports.GolfCourseType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GolfCourseType;
(function (GolfCourseType) {
    GolfCourseType["NONHANDICAPNONGENDERED"] = "NONHANDICAP_NONGENDERED";
    GolfCourseType["NONHANDICAPGENDERED"] = "NONHANDICAP_GENDERED";
    GolfCourseType["HANDICAPNONGENDERED"] = "HANDICAP_NONGENDERED";
    GolfCourseType["HANDICAPGENDERED"] = "HANDICAP_GENDERED";
    GolfCourseType["HANDICAPGENDEREDSCRAMBLE"] = "HANDICAP_GENDERED_SCRAMBLE";
})(GolfCourseType = exports.GolfCourseType || (exports.GolfCourseType = {}));
function GolfCourseTypeFromJSON(json) {
    return GolfCourseTypeFromJSONTyped(json, false);
}
exports.GolfCourseTypeFromJSON = GolfCourseTypeFromJSON;
function GolfCourseTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GolfCourseTypeFromJSONTyped = GolfCourseTypeFromJSONTyped;
function GolfCourseTypeToJSON(value) {
    return value;
}
exports.GolfCourseTypeToJSON = GolfCourseTypeToJSON;
