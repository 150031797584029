import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError} from "../redux/meta/MetaActions";
import {ChatApi} from "client";
import getConfig from "../utils/getConfig";
import ChatInput from "../components/ChatInput";
import MessageList from "../components/MessageList";
import {useHistory} from "react-router";
import {
	updateLastReadMessageDate,
	updateUnreadMessageQuantity
} from "../redux/tournamentInformation/TournamentInformationActions";

interface IProps {
	token?: string;
	hasChat?: boolean;
	dispatch?: any;
}

const ChatPage: React.FC<IProps> = (props: IProps) => {

	const history = useHistory();
	const [messages, setMessages] = useState([]);
	const [initialLoad, setInitialLoad] = useState(true);
	const scrollRef: any = useRef();

	if (!props.hasChat) {
		history.replace(`/leader-board/${props.token}`);
	}

	useEffect(() => {
		props.dispatch(updateUnreadMessageQuantity("0"));

		readChat().then().catch();

		const interval = setInterval(() => {readChat().then().catch()}, 5000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		if (initialLoad && messages.length > 0) {
			scrollRef.current.scrollIntoView();
			setInitialLoad(false);
		} else {
			scrollRef.current.scrollIntoView({behavior: "smooth"});
		}
	}, [messages.length]);

	async function readChat(): Promise<void> {
		try {
			const res = await new ChatApi(getConfig(props.token)).getMessages();
			props.dispatch(updateLastReadMessageDate((res && res.length > 0 ) ? res[res.length - 1].chatMessage.creationDate : 0));
			setMessages(res);
		} catch (e) {
			if (!(e && e.message && (e.message.indexOf("connection") > -1))) {
				props.dispatch(addError(e));
			}
		}
	}

	async function sendMessage(m: string): Promise<void> {
		try {
			await new ChatApi(getConfig(props.token)).postMessage({
				postMessageBody: {
					message: m,
				},
			});

			readChat().then().catch();
		} catch (e) {
			props.dispatch(addError(e));
		}
	}

	return (
		<div>
			<MessageList messages={messages}/>
			<div ref={scrollRef}/>
			<ChatInput onSend={sendMessage}/>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		hasChat: store.tournamentInformationStore.hasChat,
		...props,
	}
})(ChatPage);
