"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateTournamentBodyToJSON = exports.CreateTournamentBodyFromJSONTyped = exports.CreateTournamentBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CreateTournamentBodyFromJSON(json) {
    return CreateTournamentBodyFromJSONTyped(json, false);
}
exports.CreateTournamentBodyFromJSON = CreateTournamentBodyFromJSON;
function CreateTournamentBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'startTime': json['startTime'],
        'timeZone': json['timeZone'],
        'allowAnytimeAccess': json['allowAnytimeAccess'],
        'golfCourse': json['golfCourse'],
        'isMixedGender': !runtime_1.exists(json, 'isMixedGender') ? undefined : json['isMixedGender'],
        'teamSize': !runtime_1.exists(json, 'teamSize') ? undefined : json['teamSize'],
        'startType': _1.TournamentStartTypeFromJSON(json['startType']),
        'teeTimeStartType': !runtime_1.exists(json, 'teeTimeStartType') ? undefined : _1.TeeTimeStartTypeFromJSON(json['teeTimeStartType']),
        'menTeeColour': !runtime_1.exists(json, 'menTeeColour') ? undefined : json['menTeeColour'],
        'womenTeeColour': !runtime_1.exists(json, 'womenTeeColour') ? undefined : json['womenTeeColour'],
        'showAdvertisementsAfterEachEntry': !runtime_1.exists(json, 'showAdvertisementsAfterEachEntry') ? undefined : json['showAdvertisementsAfterEachEntry'],
        'advertisementOnEntryLength': !runtime_1.exists(json, 'advertisementOnEntryLength') ? undefined : json['advertisementOnEntryLength'],
        'sponsorLabel': !runtime_1.exists(json, 'sponsorLabel') ? undefined : json['sponsorLabel'],
        'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
        'useIFrame': json['useIFrame'],
        'hasBirdieCount': json['hasBirdieCount'],
        'birdieCountMoneyPer': !runtime_1.exists(json, 'birdieCountMoneyPer') ? undefined : json['birdieCountMoneyPer'],
        'hasParCount': json['hasParCount'],
        'parCountMoneyPer': !runtime_1.exists(json, 'parCountMoneyPer') ? undefined : json['parCountMoneyPer'],
        'hasEagleCount': json['hasEagleCount'],
        'eagleCountMoneyPer': !runtime_1.exists(json, 'eagleCountMoneyPer') ? undefined : json['eagleCountMoneyPer'],
        'hasChat': json['hasChat'],
        'skipOpeningVideo': !runtime_1.exists(json, 'skipOpeningVideo') ? undefined : json['skipOpeningVideo'],
        'primaryGame': _1.GameFromJSON(json['primaryGame']),
        'sideGame': !runtime_1.exists(json, 'sideGame') ? undefined : _1.GameFromJSON(json['sideGame']),
    };
}
exports.CreateTournamentBodyFromJSONTyped = CreateTournamentBodyFromJSONTyped;
function CreateTournamentBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'startTime': value.startTime,
        'timeZone': value.timeZone,
        'allowAnytimeAccess': value.allowAnytimeAccess,
        'golfCourse': value.golfCourse,
        'isMixedGender': value.isMixedGender,
        'teamSize': value.teamSize,
        'startType': _1.TournamentStartTypeToJSON(value.startType),
        'teeTimeStartType': _1.TeeTimeStartTypeToJSON(value.teeTimeStartType),
        'menTeeColour': value.menTeeColour,
        'womenTeeColour': value.womenTeeColour,
        'showAdvertisementsAfterEachEntry': value.showAdvertisementsAfterEachEntry,
        'advertisementOnEntryLength': value.advertisementOnEntryLength,
        'sponsorLabel': value.sponsorLabel,
        'url': value.url,
        'useIFrame': value.useIFrame,
        'hasBirdieCount': value.hasBirdieCount,
        'birdieCountMoneyPer': value.birdieCountMoneyPer,
        'hasParCount': value.hasParCount,
        'parCountMoneyPer': value.parCountMoneyPer,
        'hasEagleCount': value.hasEagleCount,
        'eagleCountMoneyPer': value.eagleCountMoneyPer,
        'hasChat': value.hasChat,
        'skipOpeningVideo': value.skipOpeningVideo,
        'primaryGame': _1.GameToJSON(value.primaryGame),
        'sideGame': _1.GameToJSON(value.sideGame),
    };
}
exports.CreateTournamentBodyToJSON = CreateTournamentBodyToJSON;
