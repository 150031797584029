"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerToJSON = exports.PlayerFromJSONTyped = exports.PlayerFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PlayerFromJSON(json) {
    return PlayerFromJSONTyped(json, false);
}
exports.PlayerFromJSON = PlayerFromJSON;
function PlayerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'disabled': json['disabled'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'urlExtension': json['urlExtension'],
        'shortenedURL': !runtime_1.exists(json, 'shortenedURL') ? undefined : json['shortenedURL'],
        'tournament': _1.TournamentFromJSON(json['tournament']),
        'team': !runtime_1.exists(json, 'team') ? undefined : _1.TeamFromJSON(json['team']),
        'phoneNumber': !runtime_1.exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
        'lastDateEmailWasSent': !runtime_1.exists(json, 'lastDateEmailWasSent') ? undefined : json['lastDateEmailWasSent'],
        'lastDateMessageWasSent': !runtime_1.exists(json, 'lastDateMessageWasSent') ? undefined : json['lastDateMessageWasSent'],
        'creationDate': json['creationDate'],
        'male': !runtime_1.exists(json, 'male') ? undefined : json['male'],
        'handicap': !runtime_1.exists(json, 'handicap') ? undefined : json['handicap'],
        'handicapIndex': !runtime_1.exists(json, 'handicapIndex') ? undefined : json['handicapIndex'],
        'skipIntroductionVideo': json['skipIntroductionVideo'],
    };
}
exports.PlayerFromJSONTyped = PlayerFromJSONTyped;
function PlayerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'disabled': value.disabled,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'urlExtension': value.urlExtension,
        'shortenedURL': value.shortenedURL,
        'tournament': _1.TournamentToJSON(value.tournament),
        'team': _1.TeamToJSON(value.team),
        'phoneNumber': value.phoneNumber,
        'email': value.email,
        'lastDateEmailWasSent': value.lastDateEmailWasSent,
        'lastDateMessageWasSent': value.lastDateMessageWasSent,
        'creationDate': value.creationDate,
        'male': value.male,
        'handicap': value.handicap,
        'handicapIndex': value.handicapIndex,
        'skipIntroductionVideo': value.skipIntroductionVideo,
    };
}
exports.PlayerToJSON = PlayerToJSON;
