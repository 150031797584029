
export interface IToParCheck {
	display: any;
	className: string;
}

export function toParChecker(v: number | string): IToParCheck {
	let r: IToParCheck = {
		display: v,
		className: "",
	};

	if (v === 0 || v === "E") {
		r.display = "E";
	}

	if (v > 0) {
		r.className = "text-positiveScore";
		r.display = "+" + v;
	} else if (v < 0) {
		r.className = "text-danger";
	}

	return r;
}
