import React, {ReactElement, useEffect, useRef, useState} from "react";
import {Placing} from "client";
import {timer} from "../pages/AnimatedLeaderBoardPage2";
import AnimatedLeaderBoard2RowContent from "./AnimatedLeaderBoard2RowContent";

export const offsetModifier = 250;

interface IProps {
	placings: Array<Placing>; // The nth item from every array of pagination placings. Ie. every 0th item from each array inside the array of renderGroups in the parent.
	placingsPerPage: number; // Total number of items shown per paginated page, used for our animated timing for outbound placings.
	offset: number; // So we know how much to delay animations by
	onEndReached: () => Promise<void>;
	animationDisabled: boolean;
}

function AnimatedLeaderBoard2Row(props: IProps): ReactElement {

	// if we're only dealing with 1 page of placings, let's increase the time between api calls for a less jarring experience visually.
	const localTimer = props.placings.length > 1 ? timer : timer * 3;
	const incomingPlacingRef = useRef<Placing>();
	const currentPlacingRef = useRef<Placing>();

	const [keyStrat, setKeyStrat] = useState(0);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [moveIncoming, setMoveIncoming] = useState(false);
	const [moveOutbound, setMoveOutbound] = useState(false);

	/**
	 * Run this logic for swapping the displayed placing & animating in/out the "old" & "new" placing whenever currentIndex
	 * changes - it should only be updated as a result of the final setTimeout in this useEffect based on the defined timer length.
	 * Any spots where we do: setKeyStrat(k => k + 1); is to force re-renders because we are trying to display data we store on refs.
	 */
	useEffect(() => {
		// First, set the 2 data refs to the correct values -> reset currentPlacingRef, and set incomingPlacingRef to the new one based on currentIndex.
		currentPlacingRef.current = null;
		incomingPlacingRef.current = props.placings[currentIndex];
		setKeyStrat(k => k + 1);

		// If this is the implementation on the TO portal dashboard in the iframe, after setting the initial item to render; stop,
		if (props.animationDisabled) {
			return;
		}

		// After a tiny delay, start animating in the new data, using props.offset to differentiate each instance of this component for the staggering.
		setTimeout(() => {
			setMoveIncoming(true);
			setMoveOutbound(false);
		}, 100 + (props.offset * offsetModifier));

		// A short bit before we swap the data over, we start animating out the "current" placing, and the props.offset allows staggering each instance of the component again.
		// Also subtract a little extra so no instant cut off of data changing.
		setTimeout(() => {
			setMoveIncoming(false);
			setMoveOutbound(true);
		}, localTimer - (props.placingsPerPage * offsetModifier) + (props.offset * offsetModifier) - 1250);

		// At some point before this useEffect runs again & after the incoming placing finishes moving but before the outgoing placing starts moving, we want to "swap" the
		// incomingPlacingRef into the currentPlacingRef, and reset incomingPlacingRef. This happens when both rendered UI instances of this component's content are "overlapped"
		// in the UI, and allows us to easily animate it in with one, but animate out with the other.
		setTimeout(() => {
			currentPlacingRef.current = incomingPlacingRef.current;
			incomingPlacingRef.current = null;
			setKeyStrat(k => k + 1);
		}, localTimer / 2);

		// On this timeout we update which placing we want to display, resetting to the 0th one once we reach the end.
		// Effectively acts as an interval because this timeout activates this same useEffect with each update.
		setTimeout(() => {
			setCurrentIndex((c) => {
				if (c + 1 === props.placings.length) {
					// return 0;

					// only want to do this once
					if (props.offset === 0) {
						console.log("reached end & want new data");
						void props.onEndReached();
					}
					return c;
				}

				return c + 1;
			});
		}, localTimer);
	}, [currentIndex]);

	return (
		<div className="animated-board2-row">
			<div className="animated-board2-row_content">
				{props.animationDisabled ? (
					<AnimatedLeaderBoard2RowContent
						placing={incomingPlacingRef.current}
						incoming={false}
						resting={true}
						outbound={false}
					/>
				) : (
					<React.Fragment>
						<AnimatedLeaderBoard2RowContent
							placing={incomingPlacingRef.current}
							incoming={true}
							resting={moveIncoming}
							outbound={false}
						/>

						<AnimatedLeaderBoard2RowContent
							placing={currentPlacingRef.current}
							incoming={false}
							resting={true}
							outbound={moveOutbound}
						/>
					</React.Fragment>
				)}
			</div>
		</div>
	);
}

export default AnimatedLeaderBoard2Row;