"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TournamentApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class TournamentApi extends runtime.BaseAPI {
    /**
     * Used by tournament organizers to add players to a team
     */
    async addPlayerRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/add_player`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.AddPlayerBodyToJSON(requestParameters.addPlayerBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by tournament organizers to add players to a team
     */
    async addPlayer(requestParameters) {
        await this.addPlayerRaw(requestParameters);
    }
    /**
     * Used by tournament organizers to add teams to their tournaments
     */
    async addTeamRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/add_team`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.AddTeamBodyToJSON(requestParameters.addTeamBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by tournament organizers to add teams to their tournaments
     */
    async addTeam(requestParameters) {
        await this.addTeamRaw(requestParameters);
    }
    /**
     * Used by tournament organizers to add unassigned players to teams automatically
     */
    async autoPairUnassignedPlayersRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/auto_pair_unassigned_players`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by tournament organizers to add unassigned players to teams automatically
     */
    async autoPairUnassignedPlayers(requestParameters) {
        await this.autoPairUnassignedPlayersRaw(requestParameters);
    }
    /**
     */
    async closeScoringRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Player", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/close_scoring`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async closeScoring() {
        await this.closeScoringRaw();
    }
    /**
     */
    async closeScoringForTeamRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/close_scoring_for_team`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async closeScoringForTeam(requestParameters) {
        await this.closeScoringForTeamRaw(requestParameters);
    }
    /**
     */
    async createTournamentRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/create_tournament`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.CreateTournamentBodyToJSON(requestParameters.createTournamentBody),
        });
        return new runtime.TextApiResponse(response);
    }
    /**
     */
    async createTournament(requestParameters) {
        const response = await this.createTournamentRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async deletePlayerRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/delete_player`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deletePlayer(requestParameters) {
        await this.deletePlayerRaw(requestParameters);
    }
    /**
     */
    async deleteScoresForTournamentRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/delete_scores_for_tournament`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteScoresForTournament(requestParameters) {
        await this.deleteScoresForTournamentRaw(requestParameters);
    }
    /**
     */
    async deleteTeamRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/delete_team`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteTeam(requestParameters) {
        await this.deleteTeamRaw(requestParameters);
    }
    /**
     * Used by tournament organizers to edit an existing player.
     */
    async editPlayerRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/edit_player`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.EditPlayerBodyToJSON(requestParameters.editPlayerBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by tournament organizers to edit an existing player.
     */
    async editPlayer(requestParameters) {
        await this.editPlayerRaw(requestParameters);
    }
    /**
     * Used by tournament organizers to edit an existing team.
     */
    async editTeamRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/edit_team`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.EditTeamBodyToJSON(requestParameters.editTeamBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by tournament organizers to edit an existing team.
     */
    async editTeam(requestParameters) {
        await this.editTeamRaw(requestParameters);
    }
    /**
     * Used by tournament organizers to end the tournament
     */
    async endTournamentRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/end_tournament`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by tournament organizers to end the tournament
     */
    async endTournament(requestParameters) {
        await this.endTournamentRaw(requestParameters);
    }
    /**
     */
    async enterScoreRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Player", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/enter_score`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.EnterScoreBodyToJSON(requestParameters.enterScoreBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetScoresResponseFromJSON(jsonValue));
    }
    /**
     */
    async enterScore(requestParameters) {
        const response = await this.enterScoreRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getAllTournamentsRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.isActive !== undefined) {
            queryParameters['isActive'] = requestParameters.isActive;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/get_all_tournaments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.TournamentWithActiveFromJSON));
    }
    /**
     */
    async getAllTournaments(requestParameters) {
        const response = await this.getAllTournamentsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getCartsignsPrintOutRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling getCartsignsPrintOut.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        if (requestParameters.qr !== undefined) {
            queryParameters['qr'] = requestParameters.qr;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/tournament/get_cartsigns_print_out`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.BlobApiResponse(response);
    }
    /**
     */
    async getCartsignsPrintOut(requestParameters) {
        const response = await this.getCartsignsPrintOutRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getFinalIndividualResultsPrintOutRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling getFinalIndividualResultsPrintOut.');
        }
        if (requestParameters.handicap === null || requestParameters.handicap === undefined) {
            throw new runtime.RequiredError('handicap', 'Required parameter requestParameters.handicap was null or undefined when calling getFinalIndividualResultsPrintOut.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        if (requestParameters.handicap !== undefined) {
            queryParameters['handicap'] = requestParameters.handicap;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/tournament/get_final_individual_results_print_out`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.BlobApiResponse(response);
    }
    /**
     */
    async getFinalIndividualResultsPrintOut(requestParameters) {
        const response = await this.getFinalIndividualResultsPrintOutRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getFinalResultsPrintOutRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling getFinalResultsPrintOut.');
        }
        if (requestParameters.handicap === null || requestParameters.handicap === undefined) {
            throw new runtime.RequiredError('handicap', 'Required parameter requestParameters.handicap was null or undefined when calling getFinalResultsPrintOut.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        if (requestParameters.handicap !== undefined) {
            queryParameters['handicap'] = requestParameters.handicap;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/tournament/get_final_results_print_out`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.BlobApiResponse(response);
    }
    /**
     */
    async getFinalResultsPrintOut(requestParameters) {
        const response = await this.getFinalResultsPrintOutRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getLeaderboardsRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Player", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/get_leaderboards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.LeaderboardCollectionFromJSON(jsonValue));
    }
    /**
     */
    async getLeaderboards() {
        const response = await this.getLeaderboardsRaw();
        return await response.value();
    }
    /**
     */
    async getOwnTournamentsRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.isActive !== undefined) {
            queryParameters['isActive'] = requestParameters.isActive;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/get_own_tournaments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.TournamentFromJSON));
    }
    /**
     */
    async getOwnTournaments(requestParameters) {
        const response = await this.getOwnTournamentsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getPairingsRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling getPairings.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/get_pairings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.PlayerFromJSON));
    }
    /**
     */
    async getPairings(requestParameters) {
        const response = await this.getPairingsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getPairingsPrintOutRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling getPairingsPrintOut.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/tournament/get_pairings_print_out`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.BlobApiResponse(response);
    }
    /**
     */
    async getPairingsPrintOut(requestParameters) {
        const response = await this.getPairingsPrintOutRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getPlayerHandicapCalculationRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling getPlayerHandicapCalculation.');
        }
        if (requestParameters.handicapIndex === null || requestParameters.handicapIndex === undefined) {
            throw new runtime.RequiredError('handicapIndex', 'Required parameter requestParameters.handicapIndex was null or undefined when calling getPlayerHandicapCalculation.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        if (requestParameters.handicapIndex !== undefined) {
            queryParameters['handicapIndex'] = requestParameters.handicapIndex;
        }
        if (requestParameters.isMale !== undefined) {
            queryParameters['isMale'] = requestParameters.isMale;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/get_player_handicap_calculation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.TextApiResponse(response);
    }
    /**
     */
    async getPlayerHandicapCalculation(requestParameters) {
        const response = await this.getPlayerHandicapCalculationRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getPlayersRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling getPlayers.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/tournament/get_players`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.PlayerGroupingsFromJSON(jsonValue));
    }
    /**
     */
    async getPlayers(requestParameters) {
        const response = await this.getPlayersRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getPlayersAlphaPrintOutRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling getPlayersAlphaPrintOut.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/tournament/get_players_alpha_print_out`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.BlobApiResponse(response);
    }
    /**
     */
    async getPlayersAlphaPrintOut(requestParameters) {
        const response = await this.getPlayersAlphaPrintOutRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getScorecardPrintOutsRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling getScorecardPrintOuts.');
        }
        if (requestParameters.handicap === null || requestParameters.handicap === undefined) {
            throw new runtime.RequiredError('handicap', 'Required parameter requestParameters.handicap was null or undefined when calling getScorecardPrintOuts.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        if (requestParameters.handicap !== undefined) {
            queryParameters['handicap'] = requestParameters.handicap;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/tournament/get_scorecard_print_outs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.BlobApiResponse(response);
    }
    /**
     */
    async getScorecardPrintOuts(requestParameters) {
        const response = await this.getScorecardPrintOutsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getScoresRaw(requestParameters) {
        if (requestParameters.isPolling === null || requestParameters.isPolling === undefined) {
            throw new runtime.RequiredError('isPolling', 'Required parameter requestParameters.isPolling was null or undefined when calling getScores.');
        }
        const queryParameters = {};
        if (requestParameters.isPolling !== undefined) {
            queryParameters['isPolling'] = requestParameters.isPolling;
        }
        if (requestParameters.currentHole !== undefined) {
            queryParameters['currentHole'] = requestParameters.currentHole;
        }
        if (requestParameters.currentHoleHasScore !== undefined) {
            queryParameters['currentHoleHasScore'] = requestParameters.currentHoleHasScore;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Player", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/get_scores`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetScoresResponseFromJSON(jsonValue));
    }
    /**
     */
    async getScores(requestParameters) {
        const response = await this.getScoresRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getTournamentRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling getTournament.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/get_tournament`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TournamentFromJSON(jsonValue));
    }
    /**
     */
    async getTournament(requestParameters) {
        const response = await this.getTournamentRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getTournamentDetailsRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling getTournamentDetails.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/get_tournament_details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TournamentDetailsFromJSON(jsonValue));
    }
    /**
     */
    async getTournamentDetails(requestParameters) {
        const response = await this.getTournamentDetailsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getTournamentLogsRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling getTournamentLogs.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/get_tournament_logs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetTournamentLogsResponseFromJSON(jsonValue));
    }
    /**
     */
    async getTournamentLogs(requestParameters) {
        const response = await this.getTournamentLogsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getTournamentOptionsLookupRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/get_tournament_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TournamentOptionsLookupFromJSON(jsonValue));
    }
    /**
     */
    async getTournamentOptionsLookup() {
        const response = await this.getTournamentOptionsLookupRaw();
        return await response.value();
    }
    /**
     */
    async getTournamentSummaryRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling getTournamentSummary.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenDependant", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/get_tournament_summary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TournamentSummaryFromJSON(jsonValue));
    }
    /**
     */
    async getTournamentSummary(requestParameters) {
        const response = await this.getTournamentSummaryRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getTournamentsCreatedByRaw(requestParameters) {
        if (requestParameters.userID === null || requestParameters.userID === undefined) {
            throw new runtime.RequiredError('userID', 'Required parameter requestParameters.userID was null or undefined when calling getTournamentsCreatedBy.');
        }
        const queryParameters = {};
        if (requestParameters.userID !== undefined) {
            queryParameters['userID'] = requestParameters.userID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/get_tournaments_created_by`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.TournamentFromJSON));
    }
    /**
     */
    async getTournamentsCreatedBy(requestParameters) {
        const response = await this.getTournamentsCreatedByRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async hasPlayerSkippedIntroductionVideoRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Player", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/has_player_skipped_introduction_video`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.HasPlayerSkippedIntroductionVideoResponseFromJSON(jsonValue));
    }
    /**
     */
    async hasPlayerSkippedIntroductionVideo() {
        const response = await this.hasPlayerSkippedIntroductionVideoRaw();
        return await response.value();
    }
    /**
     */
    async isChatAndURLEnabledForTournamentRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Player", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/is_chat_and_url_enabled_for_tournament`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ChatAndURLFromJSON(jsonValue));
    }
    /**
     */
    async isChatAndURLEnabledForTournament() {
        const response = await this.isChatAndURLEnabledForTournamentRaw();
        return await response.value();
    }
    /**
     */
    async isStartedRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Player", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/is_started`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.IsStartedResponseFromJSON(jsonValue));
    }
    /**
     */
    async isStarted() {
        const response = await this.isStartedRaw();
        return await response.value();
    }
    /**
     */
    async reopenScoringForTeamRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/reopen_scoring_for_team`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async reopenScoringForTeam(requestParameters) {
        await this.reopenScoringForTeamRaw(requestParameters);
    }
    /**
     * Used by tournament organizers to restart an ended tournament
     */
    async restartTournamentRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/restart_tournament`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by tournament organizers to restart an ended tournament
     */
    async restartTournament(requestParameters) {
        await this.restartTournamentRaw(requestParameters);
    }
    /**
     */
    async sendPlayerURLRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/send_player_url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.SendPlayerURLBodyToJSON(requestParameters.sendPlayerURLBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async sendPlayerURL(requestParameters) {
        await this.sendPlayerURLRaw(requestParameters);
    }
    /**
     */
    async sendURLsToAllPlayersRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/send_urls_to_all_players`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.SendUrlsBodyToJSON(requestParameters.sendUrlsBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async sendURLsToAllPlayers(requestParameters) {
        await this.sendURLsToAllPlayersRaw(requestParameters);
    }
    /**
     * Used when a player has selected to skip the introduction video
     */
    async skipIntroductionVideoRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Player", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/skip_introduction_video`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used when a player has selected to skip the introduction video
     */
    async skipIntroductionVideo() {
        await this.skipIntroductionVideoRaw();
    }
    /**
     */
    async toggleDisableTournamentRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/toggle_disable_tournament`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async toggleDisableTournament(requestParameters) {
        await this.toggleDisableTournamentRaw(requestParameters);
    }
    /**
     */
    async unauthenticatedGetLeaderboardsRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling unauthenticatedGetLeaderboards.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/tournament/unauthenticated_get_leaderboards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.UnauthenticatedGetLeaderboardsResponseFromJSON(jsonValue));
    }
    /**
     */
    async unauthenticatedGetLeaderboards(requestParameters) {
        const response = await this.unauthenticatedGetLeaderboardsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async updatePlayersRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/update_players`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.UpdatePlayersBodyToJSON(requestParameters.updatePlayersBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async updatePlayers(requestParameters) {
        await this.updatePlayersRaw(requestParameters);
    }
    /**
     */
    async updateTournamentRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/update_tournament`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.UpdateTournamentBodyToJSON(requestParameters.updateTournamentBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async updateTournament(requestParameters) {
        await this.updateTournamentRaw(requestParameters);
    }
    /**
     * Wipes all player and team data and replaces them with new data from the csv.
     */
    async uploadCSVPlayerDataRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling uploadCSVPlayerData.');
        }
        if (requestParameters.csv === null || requestParameters.csv === undefined) {
            throw new runtime.RequiredError('csv', 'Required parameter requestParameters.csv was null or undefined when calling uploadCSVPlayerData.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.tournamentID !== undefined) {
            formParams.append('tournamentID', requestParameters.tournamentID);
        }
        if (requestParameters.csv !== undefined) {
            formParams.append('csv', requestParameters.csv);
        }
        const response = await this.request({
            path: `/tournament/upload_csv_player_data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Wipes all player and team data and replaces them with new data from the csv.
     */
    async uploadCSVPlayerData(requestParameters) {
        await this.uploadCSVPlayerDataRaw(requestParameters);
    }
    /**
     */
    async userDisableTournamentRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/user_disable_tournament`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async userDisableTournament(requestParameters) {
        await this.userDisableTournamentRaw(requestParameters);
    }
    /**
     */
    async validateTournamentRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling validateTournament.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournament/validate_tournament`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.APIErrorFromJSON(jsonValue));
    }
    /**
     */
    async validateTournament(requestParameters) {
        const response = await this.validateTournamentRaw(requestParameters);
        return await response.value();
    }
}
exports.TournamentApi = TournamentApi;
