"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaderboardToJSON = exports.LeaderboardFromJSONTyped = exports.LeaderboardFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function LeaderboardFromJSON(json) {
    return LeaderboardFromJSONTyped(json, false);
}
exports.LeaderboardFromJSON = LeaderboardFromJSON;
function LeaderboardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'header': !runtime_1.exists(json, 'header') ? undefined : _1.LeaderboardHeaderFromJSON(json['header']),
        'labels': _1.PlacingLabelsFromJSON(json['labels']),
        'placings': (json['placings'].map(_1.PlacingFromJSON)),
    };
}
exports.LeaderboardFromJSONTyped = LeaderboardFromJSONTyped;
function LeaderboardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'header': _1.LeaderboardHeaderToJSON(value.header),
        'labels': _1.PlacingLabelsToJSON(value.labels),
        'placings': (value.placings.map(_1.PlacingToJSON)),
    };
}
exports.LeaderboardToJSON = LeaderboardToJSON;
