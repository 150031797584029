import React, {MutableRefObject, ReactElement, ReactNode, useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {useQuery} from "../utils/useQuery";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {chunk, omit} from "lodash";
import {findDefaultSelectedGameType, ILeaderboardCollectionNoTournamentDetails} from "./LeaderBoardPage";
import {
	Asset,
	LeaderboardCollection,
	Placing,
	TournamentApi,
	Leaderboard,
	Tournament,
} from "client";
import AnimatedLeaderBoard2Row from "../components/AnimatedLeaderBoard2Row";
import AnimatedPageHeader from "../components/AnimatedPageHeader";
import AnimatedPageVideoBackground from "../components/AnimatedPageVideoBackground";

export const timer = 15000; // 15 seconds

interface IProps {
	dispatch?: any;
}

function AnimatedLeaderBoardPage2(props: IProps): ReactElement {

	const query = useQuery();
	const tournamentID: string = query.get("tournamentID");
	const animationDisabled: string = query.get("animationDisabled"); // used to disabled animation & height calculations in the TO portal due to iframe & doc height issues.
	const tableRowsRef = useRef<HTMLDivElement>();

	const [leaderBoards, setLeaderBoards] = useState<ILeaderboardCollectionNoTournamentDetails>();
	const [selectedGame, setSelectedGame] = useState<keyof LeaderboardCollection>(); // track which of the primary / secondary / gross / net is selected
	const [animatedPageBannerAd, setAnimatedPageBannerAd] = useState<Asset & { url: string }>(undefined);
	const [tournament, setTournament] = useState<Tournament>(undefined);
	const [initialTableRowsHeight, setInitialTableRowsHeight] = useState(0);
	const [renderGroups, setRenderGroups] = useState<Array<Array<Placing>>>([]);
	const [updateKey, setUpdateKey] = useState(0); // used to get the child components to update when we want them to with the new data each time we check the leaderboards

	/**
	 * On initial render request data from our api.
	 *
	 */
	useEffect(() => {
		if (tournamentID) {
			void getLeaderBoardData();
		}
	}, []);

	/**
	 * Make a reference to the "original" height of the content container, which should be the entire available space
	 * underneath the top, "fixed" content of this UI. This will give us the real value before the content renders in
	 * dynamically & alters the height of the ref'd div (the content will overflow, but be hidden, but if we check the
	 * height at that point the returned value will include all the content & the overflow, or "true" height so to speak.
	 *
	 */
	useEffect(() => {
		if (tableRowsRef && initialTableRowsHeight === 0) {
			setInitialTableRowsHeight(tableRowsRef.current.clientHeight);
		}
	}, [tableRowsRef]);

	/**
	 * When the leaderboards & selected game are determined, divide the placings into "groups" we can cycle through in the UI.
	 *
	 */
	useEffect(() => {
		const placings = (leaderBoards?.[selectedGame] as Leaderboard)?.placings;
		if (placings?.length > 0) {
			const _renderGroups = chunk<Placing>(placings, Math.floor(initialTableRowsHeight / 99) - 1); //todo use css variable
			setRenderGroups(_renderGroups);
		}
	}, [JSON.stringify(leaderBoards), selectedGame]);

	/**
	 * Call our api to get the leaderboard data that we will display.
	 *
	 */
	async function getLeaderBoardData(): Promise<void> {
		try {
			const res = await new TournamentApi(getConfig()).unauthenticatedGetLeaderboards({tournamentID});
			const cleanLeaderBoardRes = omit(res?.leaderboardCollection, "tournament");
			const selectedGame = findDefaultSelectedGameType(res.leaderboardCollection)

			setLeaderBoards(cleanLeaderBoardRes);
			setSelectedGame(selectedGame);
			setAnimatedPageBannerAd(res?.animatedLeaderboardBanner as Asset & { url: string });
			setTournament(res?.leaderboardCollection?.tournament);
			setUpdateKey(k => k + 1);
		} catch (e) {
			props.dispatch(addError(e));
		}
	}

	/**
	 * Render each table row & save refs to array for height calculations.
	 * Last item will proc calculations via the useEffect knowing that we have all the refs.
	 *
	 * @param placing
	 * @param i
	 */
	function renderTableRow(placing: Placing, i: number): ReactNode {
		const rawPlacings: Array<Placing> = (leaderBoards?.[selectedGame] as Leaderboard)?.placings;
		const _placings: Array<Placing> = [];

		for (let j = 0; j < renderGroups.length; j++) {
			_placings.push(renderGroups[j][i]);
		}

		return (
			<AnimatedLeaderBoard2Row
				key={`placing_${i}_${updateKey}`}
				placings={animationDisabled ? [rawPlacings?.[i]] : _placings}
				placingsPerPage={animationDisabled ? rawPlacings?.length : renderGroups[0].length}
				offset={i}
				onEndReached={getLeaderBoardData}
				animationDisabled={animationDisabled === "true"}
			/>
		);
	}

	/**
	 * Generate the string for the "PAR X" label underneath the tournament name.
	 *
	 */
	function getParLabel(): string {
		const holes = tournament?.golfCourse?.holes;
		if (holes == undefined) {
			return "";
		}

		return "PAR " + Object.values(holes)?.reduce((acc, hole) => {
			return acc + hole.par;
		}, 0);
	}


	return (
		<div className="animated-leaderboard-page">
			<AnimatedPageVideoBackground
				videoSrc={process.env.PUBLIC_URL + "/videos/animated-board-background.mp4"}
				videpType="video/mp4"
			/>

			<div className="animated-leaderboard-page_container">
				<div className="animated-leaderboard-page_content">
					<AnimatedPageHeader
						tournament={tournament}
						title={tournament?.golfCourse?.golfClub}
						name={tournament?.name}
						subtitle={getParLabel()}
						animatedLeaderboardBannerURL={animatedPageBannerAd?.url}
					/>

					<div className="animated-leaderboard-page_content_bottom">
						<div className="animated-leaderboard-page_content_bottom_table-labels">
							<div className="animated-leaderboard-page_content_bottom_table-labels_to-par">
								<p>TO PAR</p>
							</div>

							<div className="animated-leaderboard-page_content_bottom_table-labels_score">
								<p>SCORE</p>
							</div>
						</div>

						{animationDisabled ? (
							<div
								ref={tableRowsRef}
								className="animated-leaderboard-page_content_bottom_table-rows"
							>
								{(leaderBoards?.[selectedGame] as Leaderboard)?.placings?.map(renderTableRow)}
							</div>
						) : (
							<div
								ref={tableRowsRef}
								className="animated-leaderboard-page_content_bottom_table-rows"
							>
								{renderGroups[0]?.map(renderTableRow)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
	}
})(AnimatedLeaderBoardPage2);
