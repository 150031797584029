import {HoleLabel} from "client";
import {findIndex} from "lodash";

/**
 * find the index of the current hole inside the holeLabels array
 *
 */
export function findIndexOfCurrentHole(labels: Array<HoleLabel>, _current: number): number {
	return findIndex(labels, {holeNumber: _current});
}
