"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetTournamentLogsResponseToJSON = exports.GetTournamentLogsResponseFromJSONTyped = exports.GetTournamentLogsResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function GetTournamentLogsResponseFromJSON(json) {
    return GetTournamentLogsResponseFromJSONTyped(json, false);
}
exports.GetTournamentLogsResponseFromJSON = GetTournamentLogsResponseFromJSON;
function GetTournamentLogsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'timeZone': json['timeZone'],
        'logs': !runtime_1.exists(json, 'logs') ? undefined : (json['logs'].map(_1.TournamentLogFromJSON)),
    };
}
exports.GetTournamentLogsResponseFromJSONTyped = GetTournamentLogsResponseFromJSONTyped;
function GetTournamentLogsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'timeZone': value.timeZone,
        'logs': value.logs === undefined ? undefined : (value.logs.map(_1.TournamentLogToJSON)),
    };
}
exports.GetTournamentLogsResponseToJSON = GetTournamentLogsResponseToJSON;
