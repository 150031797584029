import React, {ReactNode, useEffect, useState} from "react";
import {EnterScoreBody, EnterScoreRequest, HoleLabel, PlayerEntry, Score} from "client";
import {findIndexOfCurrentHole} from "../utils/findIndexOfCurrentHole";
import ScoreEntryModal from "./ScoreEntryModal";
import {determineHoles} from "../utils/holeIndexCycling";

interface IProps {
	currentHole: number;
	holeLabels: Array<HoleLabel>;
	entries: Array<PlayerEntry>;
	isNet: boolean;
	enableScoreEntry: boolean;

	onDone(enterScoreRequest: EnterScoreRequest): Promise<void>;
}

const ScoresDisplay: React.FC<IProps> = (props: IProps) => {

	const {currentHole, holeLabels, entries, isNet} = props;

	function getHoleMessage(): string {
		const index: number = findIndexOfCurrentHole(holeLabels, currentHole);
		return holeLabels[index].message;
	}

	function makeEntries(_entries: Array<PlayerEntry> = []): ReactNode {
		return _entries.map((_entry: PlayerEntry, i: number) => {

			return (
				<DynamicEntries
					key={`dynamic-entry-${i}`}
					entry={_entry}
					currentHole={currentHole}
					holeLabels={holeLabels}
					holeMessage={getHoleMessage()}
					onDone={props.onDone}
					isNet={isNet}
					enableScoreEntry={props.enableScoreEntry}
				/>
			);
		});
	}

	return (
		<div>
			{makeEntries(entries)}
		</div>
	)
};

interface IDynamicEntriesProps {
	entry: PlayerEntry;
	currentHole: number;
	holeLabels: Array<HoleLabel>;
	holeMessage?: string;
	isNet: boolean;
	enableScoreEntry: boolean;

	onDone(enterScoreRequest: EnterScoreRequest): Promise<void>;
}

const DynamicEntries: React.FC<IDynamicEntriesProps> = (props: IDynamicEntriesProps) => {

	const {entry, currentHole, holeLabels, holeMessage, isNet} = props;
	const [modalKey, setModalKey] = useState(1);

	useEffect(() => {
		setModalKey(modalKey + 1);
	}, [currentHole]);

	const [showScoreEntry, setShowScoreEntry] = useState(false);

	function toggleScoreEntryModal(): void {
		if (props.enableScoreEntry) {
			setShowScoreEntry(!showScoreEntry);
		}
	}

	async function onDoneHelper(enterScoreRequest: EnterScoreRequest): Promise<void> {
		setShowScoreEntry(false);
		await props.onDone(enterScoreRequest);
	}

	const holeScoresToDisplay: Array<HoleLabel> = determineHoles(holeLabels, currentHole);
	const previousHoleScore: Score = entry.dynamicValue[holeScoresToDisplay[0].holeNumber.toString()];
	const thisHoleScore: Score = entry.dynamicValue[currentHole.toString()];
	// const nextHoleScore: Score = entry.dynamicValue[holeScoresToDisplay[2].holeNumber.toString()];

	const previousClass: string = previousHoleScore && previousHoleScore.strokePlayCounted ? " text-yellow" : " text-dark";
	const currentClass: string = thisHoleScore && thisHoleScore.strokePlayCounted ? " text-yellow" : "text-dark";
	// const nextClass: string = nextHoleScore && nextHoleScore.strokePlayCounted ? " text-positiveScore" : " text-dark";

	return (
		<React.Fragment>
			<ScoreEntryModal
				key={modalKey}
				isOpen={showScoreEntry}
				score={thisHoleScore && thisHoleScore}
				team={entry.label}
				hole={currentHole}
				playerID={entry.playerID}
				closeModal={toggleScoreEntryModal}
				onDone={onDoneHelper}
			/>

			<div className="d-flex score-card-main-display user-select-none" style={{marginTop: "0.5rem", marginBottom: "0.5rem", marginLeft: "0.15rem", marginRight: "0.15rem"}}>
				<div className="score-card-left-columns border-right border-gray d-flex align-items-center justify-content-between px-2 py-3">
					<div className="">
						<h6 className="text-left text-break">{entry.label + (entry.handicap !== undefined ? `(${entry.handicap})` : "")}</h6>
						{entry.subtitle && (
							<h6 className="text-center mb-0" style={{fontSize: "0.8rem"}}>{entry.subtitle}</h6>)}
					</div>

					<div className="ml-2">
						<h5>{entry.score}</h5>
					</div>
				</div>

				<div className="score-card-right-columns px-2" style={{paddingBottom: 8}}>

					<div style={{minHeight: 8}} className="d-flex align-items-center">
						{holeMessage && (
							<div className="w-100 text-center" style={{lineHeight: "1.3rem"}}>
								{holeMessage}
							</div>
						)}
					</div>
					<div className="d-flex align-items-center score-card-display-column-wrapper">
						<div className="w-5"/>

						{/*<div className="w-45 h-100 d-flex justify-content-center align-items-center">*/}
						{/*	<h5 className={"mb-0 " + previousClass}>{previousHoleScore ? previousHoleScore.gross : ""}</h5>*/}
						{/*	{isNet && (*/}
						{/*		<p className={"mb-0 net-score-sub-script " + previousClass}>{previousHoleScore ? previousHoleScore.net : ""}</p>*/}
						{/*	)}*/}
						{/*</div>*/}

						<div className="w-100 d-flex justify-content-center">
							<div className="score-entry-input-display-container align-items-center m-0" onClick={toggleScoreEntryModal}>
								<img
									src={thisHoleScore ? `${process.env.PUBLIC_URL}/icons/borderhex.png` : `${process.env.PUBLIC_URL}/icons/noborderhex.png`}
									style={{height: 80}}
									className={"cursor-pointer" + (thisHoleScore ? "" : " pulsating-score-card-hexagon")}
									draggable={false}
								/>

								<div className="score-display d-flex align-items-center">
									<h5 className={"text-white mb-0 " + currentClass}>
										{thisHoleScore ? thisHoleScore.gross : ""}
									</h5>
									{isNet && (
										<p className={"text-white mb-0 net-score-sub-script " + currentClass}>{thisHoleScore ? thisHoleScore.net : ""}</p>
									)}
								</div>
							</div>
						</div>

						{/*<div className="w-45 d-flex justify-content-center">*/}
						{/*	<div className="hexagon cursor-pointer" onClick={toggleScoreEntryModal}>*/}
						{/*		<h5 className={"text-white mb-0 " + currentClass}>{thisHoleScore ? thisHoleScore.gross : ""}</h5>*/}
						{/*		{isNet && (*/}
						{/*			<p className={"text-white mb-0 net-score-sub-script " + currentClass}>{thisHoleScore ? thisHoleScore.net : ""}</p>*/}
						{/*		)}*/}
						{/*	</div>*/}
						{/*</div>*/}

						{/*<div className="w-30 h-100 d-flex justify-content-center align-items-center">*/}
						{/*	<h5 className={"mb-0 " + nextClass}>{nextHoleScore ? nextHoleScore.gross : ""}</h5>*/}
						{/*	<p className={"mb-0 net-score-sub-script " + nextClass}>{nextHoleScore ? nextHoleScore.net : ""}</p>*/}
						{/*</div>*/}

						<div className="w-5"/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ScoresDisplay;
