import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

interface IProps {
	bannerAdURL?: string;
}

const TopBanner: React.FC<IProps> = (props: IProps) => {

	const location = useLocation();
	const [renderKey, setRenderKey] = useState(1);

	useEffect(() => {
		setRenderKey(renderKey + 1);
	}, [props.bannerAdURL]);

	if (!props.bannerAdURL) {
		return null;
	}

	if (["/", "/404", "/animated-leader-board"].includes(location.pathname)) {
		return null;
	}

	return (
		<div className="d-flex justify-content-center top-banner-container" key={renderKey}>
			<div className="banner-container">
				<img
					src={props.bannerAdURL}
					style={{userSelect: "none", opacity: 0}}
				/>
			</div>

			<div className="banner-container" style={{position: "fixed", top: 0, left: 0, zIndex: 1000}}>
				<img
					src={props.bannerAdURL}
				/>
			</div>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		bannerAdURL: store.tournamentInformationStore.bannerAdURL,
		...props,
	}
})(TopBanner);
