"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PairTournamentAssetsBodyToJSON = exports.PairTournamentAssetsBodyFromJSONTyped = exports.PairTournamentAssetsBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
function PairTournamentAssetsBodyFromJSON(json) {
    return PairTournamentAssetsBodyFromJSONTyped(json, false);
}
exports.PairTournamentAssetsBodyFromJSON = PairTournamentAssetsBodyFromJSON;
function PairTournamentAssetsBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tournamentID': json['tournamentID'],
        'bannerID': !runtime_1.exists(json, 'bannerID') ? undefined : json['bannerID'],
        'animatedLeaderboardBannerID': !runtime_1.exists(json, 'animatedLeaderboardBannerID') ? undefined : json['animatedLeaderboardBannerID'],
        'scoreCardAdID': !runtime_1.exists(json, 'scoreCardAdID') ? undefined : json['scoreCardAdID'],
        'timedAdIDs': !runtime_1.exists(json, 'timedAdIDs') ? undefined : json['timedAdIDs'],
    };
}
exports.PairTournamentAssetsBodyFromJSONTyped = PairTournamentAssetsBodyFromJSONTyped;
function PairTournamentAssetsBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tournamentID': value.tournamentID,
        'bannerID': value.bannerID,
        'animatedLeaderboardBannerID': value.animatedLeaderboardBannerID,
        'scoreCardAdID': value.scoreCardAdID,
        'timedAdIDs': value.timedAdIDs,
    };
}
exports.PairTournamentAssetsBodyToJSON = PairTournamentAssetsBodyToJSON;
