import React, {useEffect} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";

interface IProps {
	sponsorURL?: string;
}

const SponsorPage: React.FC<IProps> = (props: IProps) => {

	return (
		<iframe
			src={props.sponsorURL}
			style={{width: "100%", height: "100%"}}
		/>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		sponsorURL: store.tournamentInformationStore.sponsorURL,
		...props,
	}
})(SponsorPage);
