"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TournamentSummaryToJSON = exports.TournamentSummaryFromJSONTyped = exports.TournamentSummaryFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TournamentSummaryFromJSON(json) {
    return TournamentSummaryFromJSONTyped(json, false);
}
exports.TournamentSummaryFromJSON = TournamentSummaryFromJSON;
function TournamentSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'startTime': json['startTime'],
        'timeZone': json['timeZone'],
        'formatName': json['formatName'],
        'golfCourse': _1.GolfCourseFromJSON(json['golfCourse']),
        'numberOfPlayers': json['numberOfPlayers'],
        'numberOfTeams': json['numberOfTeams'],
        'counterArray': !runtime_1.exists(json, 'counterArray') ? undefined : json['counterArray'],
        'averageScore': _1.AverageScoreFromJSON(json['averageScore']),
        'averageHoleScores': (runtime_1.mapValues(json['averageHoleScores'], _1.AverageScoreFromJSON)),
        'topTeams': (json['topTeams'].map(_1.TopTeamFromJSON)),
        'tournamentStatus': json['tournamentStatus'],
    };
}
exports.TournamentSummaryFromJSONTyped = TournamentSummaryFromJSONTyped;
function TournamentSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'startTime': value.startTime,
        'timeZone': value.timeZone,
        'formatName': value.formatName,
        'golfCourse': _1.GolfCourseToJSON(value.golfCourse),
        'numberOfPlayers': value.numberOfPlayers,
        'numberOfTeams': value.numberOfTeams,
        'counterArray': value.counterArray,
        'averageScore': _1.AverageScoreToJSON(value.averageScore),
        'averageHoleScores': (runtime_1.mapValues(value.averageHoleScores, _1.AverageScoreToJSON)),
        'topTeams': (value.topTeams.map(_1.TopTeamToJSON)),
        'tournamentStatus': value.tournamentStatus,
    };
}
exports.TournamentSummaryToJSON = TournamentSummaryToJSON;
