import React, {ReactElement} from "react";
import {Player, TeamWithPlayersWithCalculatedHandicap} from "client";
import classNames from "classnames";
import moment from "moment";

interface IProps {
	team: TeamWithPlayersWithCalculatedHandicap;
	incoming: boolean;
	resting: boolean;
	outbound: boolean;
	isShotgun: boolean;
}

function AnimatedPlayerDetailsRowContent(props: IProps): ReactElement {

	if (!props.team) {
		return null;
	}

	function generateNames(players: Array<Player>): string {
		const playerNames = players.map((player: Player, i: number) => {
			return player.firstName + " " + player.lastName;
		});

		return playerNames.join(", ");
	}

	return (
		<div
			className={classNames("animated-player-details-row-content-swiper", {
				"animated-player-details-row-content-swiper_incoming-start": props.incoming,
				"animated-player-details-row-content-swiper_resting": props.resting,
				"animated-player-details-row-content-swiper_outbound": props.outbound,
			})}
		>
			<div
				className={classNames("animated-player-details-row-content-swiper_details", {
					"animated-player-details-row-content-swiper_details-shotgun": props.isShotgun,
				})}
			>
				<p className="animated-player-details-row-content-swiper_details_label">
					{props.team?.name}
				</p>
				<p className="animated-player-details-row-content-swiper_details_subtitle">
					{generateNames(props.team?.players)}
				</p>
			</div>

			{!props.isShotgun && (
				<div className="animated-player-details-row-content-swiper_time">
					<p>
						{moment(props.team?.startTime).format("h:mm A")}
					</p>
				</div>
			)}

			<div
				className={classNames("animated-player-details-row-content-swiper_hole", {
					"animated-player-details-row-content-swiper_hole-shotgun": props.isShotgun,
				})}
			>
				<p>
					{props.team?.startHole}
				</p>
			</div>
		</div>
	);
}

export default AnimatedPlayerDetailsRowContent;