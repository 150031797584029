"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TournamentOptionsLookupToJSON = exports.TournamentOptionsLookupFromJSONTyped = exports.TournamentOptionsLookupFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TournamentOptionsLookupFromJSON(json) {
    return TournamentOptionsLookupFromJSONTyped(json, false);
}
exports.TournamentOptionsLookupFromJSON = TournamentOptionsLookupFromJSON;
function TournamentOptionsLookupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'SCRAMBLE': !runtime_1.exists(json, 'SCRAMBLE') ? undefined : _1.TournamentOptionsFromJSON(json['SCRAMBLE']),
        'BEST_BALL_STROKE_PLAY': !runtime_1.exists(json, 'BEST_BALL_STROKE_PLAY') ? undefined : _1.TournamentOptionsFromJSON(json['BEST_BALL_STROKE_PLAY']),
        'AUDI': !runtime_1.exists(json, 'AUDI') ? undefined : _1.TournamentOptionsFromJSON(json['AUDI']),
        'STROKE_PLAY': !runtime_1.exists(json, 'STROKE_PLAY') ? undefined : _1.TournamentOptionsFromJSON(json['STROKE_PLAY']),
        'MATCH_PLAY_INDIVIDUAL': !runtime_1.exists(json, 'MATCH_PLAY_INDIVIDUAL') ? undefined : _1.TournamentOptionsFromJSON(json['MATCH_PLAY_INDIVIDUAL']),
        'MATCH_PLAY_BETTER_BALL': !runtime_1.exists(json, 'MATCH_PLAY_BETTER_BALL') ? undefined : _1.TournamentOptionsFromJSON(json['MATCH_PLAY_BETTER_BALL']),
        'MATCH_PLAY_GREENSOME': !runtime_1.exists(json, 'MATCH_PLAY_GREENSOME') ? undefined : _1.TournamentOptionsFromJSON(json['MATCH_PLAY_GREENSOME']),
        'MATCH_PLAY_FOURSOME': !runtime_1.exists(json, 'MATCH_PLAY_FOURSOME') ? undefined : _1.TournamentOptionsFromJSON(json['MATCH_PLAY_FOURSOME']),
        'RED_VS_BLUE_RYDER_STYLE_EVENT': !runtime_1.exists(json, 'RED_VS_BLUE_RYDER_STYLE_EVENT') ? undefined : _1.TournamentOptionsFromJSON(json['RED_VS_BLUE_RYDER_STYLE_EVENT']),
    };
}
exports.TournamentOptionsLookupFromJSONTyped = TournamentOptionsLookupFromJSONTyped;
function TournamentOptionsLookupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'SCRAMBLE': _1.TournamentOptionsToJSON(value.SCRAMBLE),
        'BEST_BALL_STROKE_PLAY': _1.TournamentOptionsToJSON(value.BEST_BALL_STROKE_PLAY),
        'AUDI': _1.TournamentOptionsToJSON(value.AUDI),
        'STROKE_PLAY': _1.TournamentOptionsToJSON(value.STROKE_PLAY),
        'MATCH_PLAY_INDIVIDUAL': _1.TournamentOptionsToJSON(value.MATCH_PLAY_INDIVIDUAL),
        'MATCH_PLAY_BETTER_BALL': _1.TournamentOptionsToJSON(value.MATCH_PLAY_BETTER_BALL),
        'MATCH_PLAY_GREENSOME': _1.TournamentOptionsToJSON(value.MATCH_PLAY_GREENSOME),
        'MATCH_PLAY_FOURSOME': _1.TournamentOptionsToJSON(value.MATCH_PLAY_FOURSOME),
        'RED_VS_BLUE_RYDER_STYLE_EVENT': _1.TournamentOptionsToJSON(value.RED_VS_BLUE_RYDER_STYLE_EVENT),
    };
}
exports.TournamentOptionsLookupToJSON = TournamentOptionsLookupToJSON;
