import React, {ReactNode, useEffect, useState} from "react";
import {Container, Nav, Navbar, NavItem} from "reactstrap";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {FiClipboard, FiGlobe, FiList, FiLogOut, FiMessageCircle} from "react-icons/all";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {TournamentApi} from "client";
import {updateChatAndSponsor} from "../redux/tournamentInformation/TournamentInformationActions";
import ChatChecker from "./ChatChecker";

interface IProps {
	token?: string;
	hasChat?: string;
	sponsorLabel?: string;
	sponsorURL?: string;
	useIFrame?: boolean;
	unreadMessages?: number;
	dispatch?: any;
	height: number;
}

const NavigationBar: React.FC<IProps> = (props: IProps) => {

	let location = useLocation();
	const {token} = props;
	const [checkedApiOnce, setCheckedApiOnce] = useState(false);

	useEffect(() => {
		if (token) {
			checkNavBarInfo().then().catch();
		}
	}, [JSON.stringify(location)]);

	async function checkNavBarInfo(): Promise<void> {
		if (!checkedApiOnce) {
			props.dispatch(incrementLoading());
		}

		try {
			const res = await new TournamentApi(getConfig(token)).isChatAndURLEnabledForTournament();
			props.dispatch(updateChatAndSponsor(res));
			setCheckedApiOnce(true);

		} catch (e) {
			props.dispatch(addError(e));
		}


		props.dispatch(decrementLoading());
	}

	/**
	 * helper function to create the nav links
	 *
	 * @param link
	 * @param label
	 * @param Icon
	 * @param externalURL
	 */
	function makeNavLink(link: string, label: string, Icon: any, externalURL?: string): ReactNode {

		let showMessageAlert: boolean = false;
		if (link === `/chat/${props.token}` && location.pathname !== `/chat/${props.token}` && props.unreadMessages) {
			showMessageAlert = true;
		}

		return (
			<NavItem className="nav-button text-center h-100 position-relative">
				{showMessageAlert && (
					<div className="chat-alert-icon">
						{props.unreadMessages < 100 ? props.unreadMessages : "99+"}
					</div>
				)}

				{externalURL ? (
					<a
						href={externalURL}
						target="_blank"
						rel="noreferrer noopener"
						className="nav-link text-decoration-none nav-link d-flex flex-column align-items-center nav-button-link h-100"
					>
						<Icon size="1.2rem" style={{maxHeight: 20}} className="mb-1"/>
						{label}
					</a>
				) : (
					<NavLink exact to={link} className="text-decoration-none nav-link d-flex flex-column align-items-center nav-button-link h-100">
						<Icon size="1.2rem" style={{maxHeight: 20}} className="mb-1"/>
						{label}
					</NavLink>
				)}
			</NavItem>
		)
	}

	if (!props.token) {
		return null;
	}

	return (
		<React.Fragment>
			{props.hasChat && (
				<ChatChecker/>
			)}

			<div style={{height: props.height}} className="user-select-none">
				<Navbar color="black" dark className="bg-dark p-0 h-100">
					{checkedApiOnce && (
						<Container className="p-0 h-100">
							<Nav navbar className="d-flex justify-content-center flex-row w-100 h-100">
								{makeNavLink(`/score-card/${props.token}`, "ScoreCard", FiClipboard)}
								{makeNavLink(`/leader-board/${props.token}`, "LeaderBoard", FiList)}
								{props.hasChat && (
									<>
										{makeNavLink(`/chat/${props.token}`, "Chat", FiMessageCircle)}
									</>
								)}
								{props.sponsorURL && (
									<>
										{makeNavLink(`/sponsor/${props.token}`, props.sponsorLabel.length > 10 ? props.sponsorLabel.slice(0, 10) + "..." : props.sponsorLabel, FiGlobe, props.useIFrame === false && props.sponsorURL)}
									</>
								)}
								{/*{makeNavLink("/", "Log Out", FiLogOut)}*/}
							</Nav>
						</Container>
					)}
				</Navbar>
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		hasChat: store.tournamentInformationStore.hasChat,
		sponsorLabel: store.tournamentInformationStore.sponsorLabel,
		sponsorURL: store.tournamentInformationStore.sponsorURL,
		useIFrame: store.tournamentInformationStore.useIFrame,
		unreadMessages: store.tournamentInformationStore.unreadMessages,
		...props,
	}
})(NavigationBar);
