"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaderboardCollectionToJSON = exports.LeaderboardCollectionFromJSONTyped = exports.LeaderboardCollectionFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function LeaderboardCollectionFromJSON(json) {
    return LeaderboardCollectionFromJSONTyped(json, false);
}
exports.LeaderboardCollectionFromJSON = LeaderboardCollectionFromJSON;
function LeaderboardCollectionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tournament': _1.TournamentFromJSON(json['tournament']),
        'teamGross': _1.LeaderboardFromJSON(json['teamGross']),
        'teamNet': !runtime_1.exists(json, 'teamNet') ? undefined : _1.LeaderboardFromJSON(json['teamNet']),
        'teamGrossStableford': !runtime_1.exists(json, 'teamGrossStableford') ? undefined : _1.LeaderboardFromJSON(json['teamGrossStableford']),
        'teamNetStableford': !runtime_1.exists(json, 'teamNetStableford') ? undefined : _1.LeaderboardFromJSON(json['teamNetStableford']),
        'individualGross': !runtime_1.exists(json, 'individualGross') ? undefined : _1.LeaderboardFromJSON(json['individualGross']),
        'individualNet': !runtime_1.exists(json, 'individualNet') ? undefined : _1.LeaderboardFromJSON(json['individualNet']),
        'individualGrossStableford': !runtime_1.exists(json, 'individualGrossStableford') ? undefined : _1.LeaderboardFromJSON(json['individualGrossStableford']),
        'individualNetStableford': !runtime_1.exists(json, 'individualNetStableford') ? undefined : _1.LeaderboardFromJSON(json['individualNetStableford']),
    };
}
exports.LeaderboardCollectionFromJSONTyped = LeaderboardCollectionFromJSONTyped;
function LeaderboardCollectionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tournament': _1.TournamentToJSON(value.tournament),
        'teamGross': _1.LeaderboardToJSON(value.teamGross),
        'teamNet': _1.LeaderboardToJSON(value.teamNet),
        'teamGrossStableford': _1.LeaderboardToJSON(value.teamGrossStableford),
        'teamNetStableford': _1.LeaderboardToJSON(value.teamNetStableford),
        'individualGross': _1.LeaderboardToJSON(value.individualGross),
        'individualNet': _1.LeaderboardToJSON(value.individualNet),
        'individualGrossStableford': _1.LeaderboardToJSON(value.individualGrossStableford),
        'individualNetStableford': _1.LeaderboardToJSON(value.individualNetStableford),
    };
}
exports.LeaderboardCollectionToJSON = LeaderboardCollectionToJSON;
