import React, {useRef, useState} from "react";
import {FiArrowUp} from "react-icons/all";
import {navHeight} from "../AppContent";

interface IProps {
	onSend(v: string): Promise<void>;
}

const ChatInput: React.FC<IProps> = (props: IProps) => {

	const [v, setV] = useState("");
	let inputRef: any = useRef();

	function onChange(e): void {
		setV(e.target.value);
	}

	async function send(e?): Promise<void> {
		if (e) {
			e.preventDefault();
		}

		if (v.length < 1) {
			inputRef.current.focus();
			return;
		}

		await props.onSend(v);
		setV("");
		inputRef.current.focus();
	}

	function listenForEnterPress(e): void {
		if (e.keyCode == 13) {
			send().then().catch();
		}
	}

	return (
		<div style={{position: "fixed", bottom: navHeight, left: 0,}} className="w-100">
			<div className="chat-input-container-inner d-flex align-items-center justify-content-center px-2 py-2 w-100 border-top">
				<input
					placeholder="Type your message..."
					className="w-100 form-control"
					value={v}
					onChange={onChange}
					maxLength={250}
					ref={inputRef}
					id="test"
					onKeyDown={listenForEnterPress}
				/>

				<div className="ml-3">
					<a href="#" onClick={send}>
						<div className={"chat-send-button bg-secondary " + (v.toString().length > 0 ? "chat-op-1" : "chat-op-06")}>
							<FiArrowUp className="chat-send-icon text-white"/>
						</div>
					</a>
				</div>
			</div>
		</div>
	);
};

export default ChatInput;
