"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScoreToJSON = exports.ScoreFromJSONTyped = exports.ScoreFromJSON = void 0;
const runtime_1 = require("../runtime");
function ScoreFromJSON(json) {
    return ScoreFromJSONTyped(json, false);
}
exports.ScoreFromJSON = ScoreFromJSON;
function ScoreFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gross': json['gross'],
        'net': json['net'],
        'grossToPar': !runtime_1.exists(json, 'grossToPar') ? undefined : json['grossToPar'],
        'netToPar': !runtime_1.exists(json, 'netToPar') ? undefined : json['netToPar'],
        'stableford': !runtime_1.exists(json, 'stableford') ? undefined : json['stableford'],
        'netStableford': !runtime_1.exists(json, 'netStableford') ? undefined : json['netStableford'],
        'strokePlayCounted': !runtime_1.exists(json, 'strokePlayCounted') ? undefined : json['strokePlayCounted'],
    };
}
exports.ScoreFromJSONTyped = ScoreFromJSONTyped;
function ScoreToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'gross': value.gross,
        'net': value.net,
        'grossToPar': value.grossToPar,
        'netToPar': value.netToPar,
        'stableford': value.stableford,
        'netStableford': value.netStableford,
        'strokePlayCounted': value.strokePlayCounted,
    };
}
exports.ScoreToJSON = ScoreToJSON;
