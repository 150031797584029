import React, {ReactNode} from "react";
import {HoleLabel} from "client";
import {FiChevronLeft, FiChevronRight} from "react-icons/all";
import {findIndexOfCurrentHole} from "../utils/findIndexOfCurrentHole";
import {determineHoles} from "../utils/holeIndexCycling";

interface IProps {
	headerText: string;
	currentHole: number;
	holeLabels: Array<HoleLabel>;

	onCycle(newHole: number): void;
}

const ScoreCardHeader: React.FC<IProps> = (props: IProps) => {

	const {headerText, currentHole, holeLabels} = props;


	/**
	 * cycle the labels forward or backwards by updating the currentHole
	 *
	 * @param forward
	 */
	function onCycleHelper(forward: boolean): (e) => void {
		return (e) => {
			if (e) {e.preventDefault()}

			const i: number = findIndexOfCurrentHole(holeLabels, currentHole);
			const finalHole: number = holeLabels.length - 1;
			let newHole: number = i;

			if (forward && i < finalHole) {
				newHole = i + 1;
			} else if (forward) {
				newHole = 0;
			} else if (!forward && i > 0) {
				newHole = i - 1;
			} else if (!forward) {
				newHole = finalHole;
			}

			props.onCycle(holeLabels[newHole].holeNumber);
		}
	}

	function makeHoles(holes: Array<HoleLabel> = []): ReactNode {
		return holes.map((hole: HoleLabel, i: number) => {
			return (
				<div className="w-100">
					<h6 className="text-center font-weight-bold mb-0 score-card-hole-number-label">Hole {hole.holeNumber}</h6>
					<p className="text-center mb-0 text-nowrap score-card-hole-par-label font-weight-normal" style={{fontSize: 16}}>Par {hole.par}</p>
				</div>
			);
		});
	}

	function makeTitles(_titles: string[] = []): ReactNode {
		return _titles.map((_title: string, i: number) => {
			return (
				<p className="w-100 mb-0 text-center font-weight-bold score-card-header-message text-ellipsis" key={`score-card-header-message-${i}`}>
					{_title}
				</p>
			);
		});
	}

	return (
		<div className="score-card-header rounded-score-card-header indented-page-spacer-bottom user-select-none">
			<div className="w-100 d-flex text-light score-card-header_bg">
				<div className="score-card-left-columns border-right border-light d-flex flex-column align-items-center justify-content-center px-2" style={{padding: "0.75rem"}}>
					{makeTitles([headerText])}
				</div>

				<div className="score-card-right-columns d-flex align-items-center" style={{padding: "0.75rem"}}>
					<div className="w-5">
						<a href="#" onClick={onCycleHelper(false)} className="text-light hole-selector-arrow-container d-flex justify-content-center">
							<FiChevronLeft/>
						</a>
					</div>

					{/*<div className="d-flex w-100">*/}
						{makeHoles(determineHoles(props.holeLabels, currentHole))}
					{/*</div>*/}

					<div className="w-5">
						<a href="#" onClick={onCycleHelper(true)} className="text-light hole-selector-arrow-container d-flex justify-content-center">
							<FiChevronRight/>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ScoreCardHeader;
