import {LeaderboardCollection} from "client";
import {ILeaderboardCollectionNoTournamentDetails} from "../pages/LeaderBoardPage";

/**
 * Call to get information  about how many of the returned leader boards have statistics instead of just being "undefined".
 * Used to determine if the buttons for toggling between different leader boards should be visible.
 *
 * @param _leaderBoards
 */
export function getReturnedLeaderBoardInfo(_leaderBoards: ILeaderboardCollectionNoTournamentDetails): number {
	let i: number = 0;

	for (const _board in _leaderBoards) {
		if (_leaderBoards.hasOwnProperty(_board)) {
			if (_leaderBoards[_board]) {
				i++;
			}
		}
	}

	return i;
}
