"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineObject6ToJSON = exports.InlineObject6FromJSONTyped = exports.InlineObject6FromJSON = void 0;
function InlineObject6FromJSON(json) {
    return InlineObject6FromJSONTyped(json, false);
}
exports.InlineObject6FromJSON = InlineObject6FromJSON;
function InlineObject6FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tournamentID': json['tournamentID'],
        'csv': json['csv'],
    };
}
exports.InlineObject6FromJSONTyped = InlineObject6FromJSONTyped;
function InlineObject6ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tournamentID': value.tournamentID,
        'csv': value.csv,
    };
}
exports.InlineObject6ToJSON = InlineObject6ToJSON;
