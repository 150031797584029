"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrimaryTieBreakMethodToJSON = exports.PrimaryTieBreakMethodFromJSONTyped = exports.PrimaryTieBreakMethodFromJSON = exports.PrimaryTieBreakMethod = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var PrimaryTieBreakMethod;
(function (PrimaryTieBreakMethod) {
    PrimaryTieBreakMethod["HANDICAP"] = "HANDICAP";
    PrimaryTieBreakMethod["COUNTBACK"] = "COUNTBACK";
    PrimaryTieBreakMethod["USGA"] = "USGA";
})(PrimaryTieBreakMethod = exports.PrimaryTieBreakMethod || (exports.PrimaryTieBreakMethod = {}));
function PrimaryTieBreakMethodFromJSON(json) {
    return PrimaryTieBreakMethodFromJSONTyped(json, false);
}
exports.PrimaryTieBreakMethodFromJSON = PrimaryTieBreakMethodFromJSON;
function PrimaryTieBreakMethodFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.PrimaryTieBreakMethodFromJSONTyped = PrimaryTieBreakMethodFromJSONTyped;
function PrimaryTieBreakMethodToJSON(value) {
    return value;
}
exports.PrimaryTieBreakMethodToJSON = PrimaryTieBreakMethodToJSON;
