"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenBodyToJSON = exports.TokenBodyFromJSONTyped = exports.TokenBodyFromJSON = void 0;
function TokenBodyFromJSON(json) {
    return TokenBodyFromJSONTyped(json, false);
}
exports.TokenBodyFromJSON = TokenBodyFromJSON;
function TokenBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'token': json['token'],
    };
}
exports.TokenBodyFromJSONTyped = TokenBodyFromJSONTyped;
function TokenBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'token': value.token,
    };
}
exports.TokenBodyToJSON = TokenBodyToJSON;
