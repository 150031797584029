"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TournamentToJSON = exports.TournamentFromJSONTyped = exports.TournamentFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TournamentFromJSON(json) {
    return TournamentFromJSONTyped(json, false);
}
exports.TournamentFromJSON = TournamentFromJSON;
function TournamentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'disabled': json['disabled'],
        'creationDate': json['creationDate'],
        'tournamentOrganizer': _1.UserFromJSON(json['tournamentOrganizer']),
        'name': json['name'],
        'startTime': json['startTime'],
        'endTime': !runtime_1.exists(json, 'endTime') ? undefined : json['endTime'],
        'timeZone': json['timeZone'],
        'allowAnytimeAccess': json['allowAnytimeAccess'],
        'golfCourse': _1.GolfCourseFromJSON(json['golfCourse']),
        'isMixedGender': json['isMixedGender'],
        'teamSize': !runtime_1.exists(json, 'teamSize') ? undefined : json['teamSize'],
        'startType': _1.TournamentStartTypeFromJSON(json['startType']),
        'teeTimeStartType': !runtime_1.exists(json, 'teeTimeStartType') ? undefined : _1.TeeTimeStartTypeFromJSON(json['teeTimeStartType']),
        'menTeeColour': !runtime_1.exists(json, 'menTeeColour') ? undefined : json['menTeeColour'],
        'womenTeeColour': !runtime_1.exists(json, 'womenTeeColour') ? undefined : json['womenTeeColour'],
        'showAdvertisementsAfterEachEntry': !runtime_1.exists(json, 'showAdvertisementsAfterEachEntry') ? undefined : json['showAdvertisementsAfterEachEntry'],
        'advertisementOnEntryLength': !runtime_1.exists(json, 'advertisementOnEntryLength') ? undefined : json['advertisementOnEntryLength'],
        'banner': !runtime_1.exists(json, 'banner') ? undefined : _1.AssetFromJSON(json['banner']),
        'animatedLeaderboardBanner': !runtime_1.exists(json, 'animatedLeaderboardBanner') ? undefined : _1.AssetFromJSON(json['animatedLeaderboardBanner']),
        'scoreCardAd': !runtime_1.exists(json, 'scoreCardAd') ? undefined : _1.AssetFromJSON(json['scoreCardAd']),
        'timedAd': !runtime_1.exists(json, 'timedAd') ? undefined : json['timedAd'],
        'sponsorLabel': !runtime_1.exists(json, 'sponsorLabel') ? undefined : json['sponsorLabel'],
        'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
        'useIFrame': !runtime_1.exists(json, 'useIFrame') ? undefined : json['useIFrame'],
        'hasBirdieCount': json['hasBirdieCount'],
        'birdieCountMoneyPer': !runtime_1.exists(json, 'birdieCountMoneyPer') ? undefined : json['birdieCountMoneyPer'],
        'hasParCount': json['hasParCount'],
        'parCountMoneyPer': !runtime_1.exists(json, 'parCountMoneyPer') ? undefined : json['parCountMoneyPer'],
        'hasEagleCount': json['hasEagleCount'],
        'eagleCountMoneyPer': !runtime_1.exists(json, 'eagleCountMoneyPer') ? undefined : json['eagleCountMoneyPer'],
        'hasChat': json['hasChat'],
        'skipOpeningVideo': !runtime_1.exists(json, 'skipOpeningVideo') ? undefined : json['skipOpeningVideo'],
        'primaryGame': _1.GameFromJSON(json['primaryGame']),
        'sideGame': !runtime_1.exists(json, 'sideGame') ? undefined : _1.GameFromJSON(json['sideGame']),
    };
}
exports.TournamentFromJSONTyped = TournamentFromJSONTyped;
function TournamentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'disabled': value.disabled,
        'creationDate': value.creationDate,
        'tournamentOrganizer': _1.UserToJSON(value.tournamentOrganizer),
        'name': value.name,
        'startTime': value.startTime,
        'endTime': value.endTime,
        'timeZone': value.timeZone,
        'allowAnytimeAccess': value.allowAnytimeAccess,
        'golfCourse': _1.GolfCourseToJSON(value.golfCourse),
        'isMixedGender': value.isMixedGender,
        'teamSize': value.teamSize,
        'startType': _1.TournamentStartTypeToJSON(value.startType),
        'teeTimeStartType': _1.TeeTimeStartTypeToJSON(value.teeTimeStartType),
        'menTeeColour': value.menTeeColour,
        'womenTeeColour': value.womenTeeColour,
        'showAdvertisementsAfterEachEntry': value.showAdvertisementsAfterEachEntry,
        'advertisementOnEntryLength': value.advertisementOnEntryLength,
        'banner': _1.AssetToJSON(value.banner),
        'animatedLeaderboardBanner': _1.AssetToJSON(value.animatedLeaderboardBanner),
        'scoreCardAd': _1.AssetToJSON(value.scoreCardAd),
        'timedAd': value.timedAd,
        'sponsorLabel': value.sponsorLabel,
        'url': value.url,
        'useIFrame': value.useIFrame,
        'hasBirdieCount': value.hasBirdieCount,
        'birdieCountMoneyPer': value.birdieCountMoneyPer,
        'hasParCount': value.hasParCount,
        'parCountMoneyPer': value.parCountMoneyPer,
        'hasEagleCount': value.hasEagleCount,
        'eagleCountMoneyPer': value.eagleCountMoneyPer,
        'hasChat': value.hasChat,
        'skipOpeningVideo': value.skipOpeningVideo,
        'primaryGame': _1.GameToJSON(value.primaryGame),
        'sideGame': _1.GameToJSON(value.sideGame),
    };
}
exports.TournamentToJSON = TournamentToJSON;
