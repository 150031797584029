"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionToJSON = exports.TransactionFromJSONTyped = exports.TransactionFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TransactionFromJSON(json) {
    return TransactionFromJSONTyped(json, false);
}
exports.TransactionFromJSON = TransactionFromJSON;
function TransactionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'transactionType': _1.TransactionTypeFromJSON(json['transactionType']),
        'transactionOwnerType': !runtime_1.exists(json, 'transactionOwnerType') ? undefined : _1.TransactionOwnerTypeFromJSON(json['transactionOwnerType']),
        'tournament': _1.TournamentFromJSON(json['tournament']),
        'owner': json['owner'],
        'creationDate': json['creationDate'],
        'scoreEntry': !runtime_1.exists(json, 'scoreEntry') ? undefined : _1.ScoreEntryTransactionFromJSON(json['scoreEntry']),
    };
}
exports.TransactionFromJSONTyped = TransactionFromJSONTyped;
function TransactionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'transactionType': _1.TransactionTypeToJSON(value.transactionType),
        'transactionOwnerType': _1.TransactionOwnerTypeToJSON(value.transactionOwnerType),
        'tournament': _1.TournamentToJSON(value.tournament),
        'owner': value.owner,
        'creationDate': value.creationDate,
        'scoreEntry': _1.ScoreEntryTransactionToJSON(value.scoreEntry),
    };
}
exports.TransactionToJSON = TransactionToJSON;
