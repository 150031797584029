import moment from 'moment-timezone';

const defaultTimeZone = moment.tz.guess();
export function generateOffsetDate(timezone: string = defaultTimeZone, d?: number | Date): number {
    if (!d) {
        d = new Date()
    }
    const value: number = d instanceof Date ? d.getTime() : d
    const destinationTimezone = (timezone && timezone.length > 0) ? moment().tz(timezone).utcOffset() : 0;
    const localTimezone = moment().tz(defaultTimeZone).utcOffset();
    const adjustValue = (destinationTimezone - localTimezone) * 60000;
    return value + adjustValue;
}

export function convertOffsetDate(timezone: string = defaultTimeZone, d?: number | Date): number {
    if (!d) {
        d = new Date()
    }
    const value: number = d instanceof Date ? d.getTime() : d
    const destinationTimezone = (timezone && timezone.length > 0) ? moment().tz(timezone).utcOffset() : 0;
    const localTimezone = moment().tz(defaultTimeZone).utcOffset();
    const adjustValue = (destinationTimezone - localTimezone) * 60000;
    return value - adjustValue;
}
