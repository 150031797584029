import {HoleLabel} from "client";
import {findIndexOfCurrentHole} from "./findIndexOfCurrentHole";

// This function looks useless right now, but that's because originally it was used to get an array
// of 3 holes, where the "current" hole was the middle item in the array. Then, we eventually changed the UI to only
// render the current hole. Just decided to leave the function with the first & third items commented out for ease of use/re-enabling in the future.

/**
 * determine which holes to show before & after the current hole
 *
 * @param _labels
 * @param _currentHole
 */
export function determineHoles(_labels: Array<HoleLabel> = [], _currentHole: number): Array<HoleLabel> {
	const i: number = findIndexOfCurrentHole(_labels, _currentHole);
	const finalHole: number = _labels.length - 1;
	return [
		// _labels[i === 0 ? finalHole : i - 1], // removed at Troy's request to switch to 1-column instead of 2 (Completed April 25 2022)
		_labels[i],
		// _labels[i === finalHole ? 0 : i + 1], // removed at Troy's request to switch to 2-columns instead of 3 (date unknown)
	];
}
