import React, {useEffect, useState} from "react";
import {Card, CardBody, Container} from "reactstrap";
import {connect} from "react-redux";
import {addError, decrementLoading, incrementLoading, login, logout} from "../redux/meta/MetaActions";
import {IsStartedResponse, TournamentApi} from "client";
import getConfig from "../utils/getConfig";
import {useHistory} from "react-router";
import {IStore} from "../redux/defaultStore";
import moment from "moment";
import {generateOffsetDate} from "../utils/dateUtils";
import ScoringInstructionModal from "../components/ScoringInstructionModal";

interface IProps {
	token?: string;
	dispatch?: any;
	match?: any;
}

const LandingPage: React.FC<IProps> = (props: IProps) => {

	const history = useHistory();
	const [startedRes, setStartedRes] = useState<IsStartedResponse>();
	const [showInstructionVideo, setShowInstructionVideo] = useState(false);

	useEffect(() => {
		if (props.token) {
			prepareInstructionVideo().then().catch();
			// checkTournamentStats().then().catch();
		}
	}, [props.token]);

	useEffect(() => {
		if (startedRes) {
			if (startedRes.allowAccess) {
				handleStarted().then().catch();
			}
		}
	}, [JSON.stringify(startedRes)]);

	async function prepareInstructionVideo(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new TournamentApi(getConfig(props.token)).hasPlayerSkippedIntroductionVideo();

			if (!res.skipIntroductionVideo) {
				setShowInstructionVideo(true);
			}

			await checkTournamentStats();
		} catch (e) {
		}

		props.dispatch(decrementLoading());
	}

	async function checkTournamentStats(): Promise<void> {
		if (!props.token) {
			return;
		}

		// if token exists in redux and does not match the token in the query, log out first before determining what to do
		props.dispatch(incrementLoading());
		try {
			const res = await new TournamentApi(getConfig(props.token)).isStarted();
			setStartedRes(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	async function handleStarted(): Promise<void> {
		history.push(`/score-card/${props.token}`);
	}

	function dismissInstructionVideo(): void {
		setShowInstructionVideo(false);
	}

	return (
		<React.Fragment>
			<ScoringInstructionModal
				isOpen={showInstructionVideo}
				onClose={dismissInstructionVideo}
			/>

			{startedRes ? (
				<div className="landing-bg">
					<div className="landing-bg-image"/>
					<div className="position-absolute h-100 w-100">
						<Container className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">

							{/*<div style={{maxWidth: 400}} className="mb-2 d-flex justify-content-center align-items-start">*/}
							{/*	<img*/}
							{/*		src={process.env.PUBLIC_URL + "/images/iscore-golf-logo-color.png"}*/}
							{/*		style={{maxWidth: "80%"}}*/}
							{/*	/>*/}
							{/*</div>*/}

							<Card className="w-100">
								<CardBody className="d-flex flex-column align-items-center">
									<div style={{maxWidth: 400}} className="mb-2 d-flex justify-content-center align-items-start">
										<img
											src={process.env.PUBLIC_URL + "/images/iscore-golf-logo-color.png"}
											style={{maxWidth: "80%"}}
										/>
									</div>

									<h4 className="text-center mb-3">
										iScoreGolfPlus Scoring will begin soon!
									</h4>

									<p className="text-center mb-1">
										Event:
									</p>
									<p className="text-center font-weight-bold">
										{startedRes.tournamentName}
									</p>

									<p className="text-center mb-1">
										Location:
									</p>
									<p className="text-center font-weight-bold">
										{startedRes.golfCourseName}
									</p>

									<p className="text-center mb-1">
										The tournament begins at:
									</p>
									<p className="text-center mb-0 font-weight-bold">
										{moment(generateOffsetDate(startedRes.timeZone, startedRes.startTimeUTC)).format("h:mm A, MMMM Do YYYY")}
									</p>
									<p className="text-center font-weight-bold">
										({startedRes.timeZone} time)
									</p>
								</CardBody>
							</Card>
						</Container>
					</div>
				</div>
			) : (
				<div className="d-flex justify-content-center align-items-center w-100 h-100">
				</div>
			)}
		</React.Fragment>
	)
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(LandingPage);
