"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatAndURLToJSON = exports.ChatAndURLFromJSONTyped = exports.ChatAndURLFromJSON = void 0;
const runtime_1 = require("../runtime");
function ChatAndURLFromJSON(json) {
    return ChatAndURLFromJSONTyped(json, false);
}
exports.ChatAndURLFromJSON = ChatAndURLFromJSON;
function ChatAndURLFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hasChat': json['hasChat'],
        'sponsorLabel': !runtime_1.exists(json, 'sponsorLabel') ? undefined : json['sponsorLabel'],
        'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
        'useIFrame': !runtime_1.exists(json, 'useIFrame') ? undefined : json['useIFrame'],
    };
}
exports.ChatAndURLFromJSONTyped = ChatAndURLFromJSONTyped;
function ChatAndURLToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hasChat': value.hasChat,
        'sponsorLabel': value.sponsorLabel,
        'url': value.url,
        'useIFrame': value.useIFrame,
    };
}
exports.ChatAndURLToJSON = ChatAndURLToJSON;
