"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsStartedResponseToJSON = exports.IsStartedResponseFromJSONTyped = exports.IsStartedResponseFromJSON = void 0;
function IsStartedResponseFromJSON(json) {
    return IsStartedResponseFromJSONTyped(json, false);
}
exports.IsStartedResponseFromJSON = IsStartedResponseFromJSON;
function IsStartedResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tournamentName': json['tournamentName'],
        'golfCourseName': json['golfCourseName'],
        'started': json['started'],
        'allowAccess': json['allowAccess'],
        'startTimeUTC': json['startTimeUTC'],
        'timeZone': json['timeZone'],
        'skipOpeningVideo': json['skipOpeningVideo'],
    };
}
exports.IsStartedResponseFromJSONTyped = IsStartedResponseFromJSONTyped;
function IsStartedResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tournamentName': value.tournamentName,
        'golfCourseName': value.golfCourseName,
        'started': value.started,
        'allowAccess': value.allowAccess,
        'startTimeUTC': value.startTimeUTC,
        'timeZone': value.timeZone,
        'skipOpeningVideo': value.skipOpeningVideo,
    };
}
exports.IsStartedResponseToJSON = IsStartedResponseToJSON;
