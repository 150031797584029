import {Asset} from "client";

export interface ITournamentInformationStore {
	hasChat: boolean;
	sponsorLabel: string;
	sponsorURL: string;
	useIFrame: boolean;
	lastReadMessageDate: number;
	unreadMessages?: number;
	bannerAdURL?: string;
	animatedLeaderboardBannerURL?: string;
	scoreCardAdURL?: string;
	timedAds?: { [key: number]: Asset };
}

export default {
	hasChat: false,
	sponsorLabel: "Sponsor",
	sponsorURL: "",
	useIFrame: false,
	lastReadMessageDate: 0,
	bannerAdURL: "",
	animatedLeaderboardBannerURL: "",
	scoreCardAdURL: "",
	timedAds: {},
} as ITournamentInformationStore;
