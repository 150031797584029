"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PosterTypeToJSON = exports.PosterTypeFromJSONTyped = exports.PosterTypeFromJSON = exports.PosterType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var PosterType;
(function (PosterType) {
    PosterType["Admin"] = "Admin";
    PosterType["User"] = "User";
    PosterType["Player"] = "Player";
})(PosterType = exports.PosterType || (exports.PosterType = {}));
function PosterTypeFromJSON(json) {
    return PosterTypeFromJSONTyped(json, false);
}
exports.PosterTypeFromJSON = PosterTypeFromJSON;
function PosterTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.PosterTypeFromJSONTyped = PosterTypeFromJSONTyped;
function PosterTypeToJSON(value) {
    return value;
}
exports.PosterTypeToJSON = PosterTypeToJSON;
