"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineObject5ToJSON = exports.InlineObject5FromJSONTyped = exports.InlineObject5FromJSON = void 0;
const runtime_1 = require("../runtime");
function InlineObject5FromJSON(json) {
    return InlineObject5FromJSONTyped(json, false);
}
exports.InlineObject5FromJSON = InlineObject5FromJSON;
function InlineObject5FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': !runtime_1.exists(json, '_id') ? undefined : json['_id'],
        'asset': !runtime_1.exists(json, 'asset') ? undefined : json['asset'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
    };
}
exports.InlineObject5FromJSONTyped = InlineObject5FromJSONTyped;
function InlineObject5ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'asset': value.asset,
        'name': value.name,
        'description': value.description,
    };
}
exports.InlineObject5ToJSON = InlineObject5ToJSON;
