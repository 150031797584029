"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./APIError"), exports);
__exportStar(require("./AddNewGolfCourseBody"), exports);
__exportStar(require("./AddPlayerBody"), exports);
__exportStar(require("./AddScoresToTeamsBody"), exports);
__exportStar(require("./AddTeamBody"), exports);
__exportStar(require("./Admin"), exports);
__exportStar(require("./AdminLoginBody"), exports);
__exportStar(require("./AdminPostMessageBody"), exports);
__exportStar(require("./AdminRole"), exports);
__exportStar(require("./Asset"), exports);
__exportStar(require("./AssetBase64Response"), exports);
__exportStar(require("./AssetCategory"), exports);
__exportStar(require("./AssetOwnerType"), exports);
__exportStar(require("./AverageScore"), exports);
__exportStar(require("./CalculatedHandicap"), exports);
__exportStar(require("./ChangeAdminPasswordBody"), exports);
__exportStar(require("./ChangePasswordBody"), exports);
__exportStar(require("./ChangeUserPasswordBody"), exports);
__exportStar(require("./ChatAndURL"), exports);
__exportStar(require("./ChatMessage"), exports);
__exportStar(require("./ChatMessageResponseElement"), exports);
__exportStar(require("./CheckTokenExpirationResponse"), exports);
__exportStar(require("./CreateAdminBody"), exports);
__exportStar(require("./CreateTournamentBody"), exports);
__exportStar(require("./EditPlayerBody"), exports);
__exportStar(require("./EditTeamBody"), exports);
__exportStar(require("./EnterScoreBody"), exports);
__exportStar(require("./Game"), exports);
__exportStar(require("./GetPairedTournamentAssetsResponse"), exports);
__exportStar(require("./GetScoresResponse"), exports);
__exportStar(require("./GetTournamentLogsResponse"), exports);
__exportStar(require("./GolfCourse"), exports);
__exportStar(require("./GolfCourseSource"), exports);
__exportStar(require("./GolfCourseType"), exports);
__exportStar(require("./HasPlayerSkippedIntroductionVideoResponse"), exports);
__exportStar(require("./HoleInfo"), exports);
__exportStar(require("./HoleLabel"), exports);
__exportStar(require("./IDBody"), exports);
__exportStar(require("./InlineObject"), exports);
__exportStar(require("./InlineObject1"), exports);
__exportStar(require("./InlineObject2"), exports);
__exportStar(require("./InlineObject3"), exports);
__exportStar(require("./InlineObject4"), exports);
__exportStar(require("./InlineObject5"), exports);
__exportStar(require("./InlineObject6"), exports);
__exportStar(require("./IsStartedResponse"), exports);
__exportStar(require("./Leaderboard"), exports);
__exportStar(require("./LeaderboardCollection"), exports);
__exportStar(require("./LeaderboardHeader"), exports);
__exportStar(require("./LeaderboardType"), exports);
__exportStar(require("./LoginBody"), exports);
__exportStar(require("./PairTournamentAssetsBody"), exports);
__exportStar(require("./PhoneNumberVerification"), exports);
__exportStar(require("./Placing"), exports);
__exportStar(require("./PlacingDataPoint"), exports);
__exportStar(require("./PlacingLabels"), exports);
__exportStar(require("./Player"), exports);
__exportStar(require("./PlayerDataFromCSV"), exports);
__exportStar(require("./PlayerEntry"), exports);
__exportStar(require("./PlayerGroupings"), exports);
__exportStar(require("./PlayerScoreCard"), exports);
__exportStar(require("./PlayerScoreCardAllOf"), exports);
__exportStar(require("./PlayersArray"), exports);
__exportStar(require("./PostMessageBody"), exports);
__exportStar(require("./PosterType"), exports);
__exportStar(require("./PrimaryTieBreakMethod"), exports);
__exportStar(require("./RequestFullAccountBody"), exports);
__exportStar(require("./Score"), exports);
__exportStar(require("./ScoreCard"), exports);
__exportStar(require("./ScoreEntryTransaction"), exports);
__exportStar(require("./SendPlayerURLBody"), exports);
__exportStar(require("./SendUrlsBody"), exports);
__exportStar(require("./SignUpBody"), exports);
__exportStar(require("./StorageDetails"), exports);
__exportStar(require("./Team"), exports);
__exportStar(require("./TeamScoreCard"), exports);
__exportStar(require("./TeamScoreCardAllOf"), exports);
__exportStar(require("./TeamWithPlayers"), exports);
__exportStar(require("./TeamWithPlayersWithCalculatedHandicap"), exports);
__exportStar(require("./TeeTimeStartType"), exports);
__exportStar(require("./Token"), exports);
__exportStar(require("./TokenBody"), exports);
__exportStar(require("./TokenType"), exports);
__exportStar(require("./TopTeam"), exports);
__exportStar(require("./TopTeamAllOf"), exports);
__exportStar(require("./Tournament"), exports);
__exportStar(require("./TournamentDetails"), exports);
__exportStar(require("./TournamentGameType"), exports);
__exportStar(require("./TournamentLog"), exports);
__exportStar(require("./TournamentOptions"), exports);
__exportStar(require("./TournamentOptionsLookup"), exports);
__exportStar(require("./TournamentStartType"), exports);
__exportStar(require("./TournamentSummary"), exports);
__exportStar(require("./TournamentType"), exports);
__exportStar(require("./TournamentWithActive"), exports);
__exportStar(require("./Transaction"), exports);
__exportStar(require("./TransactionOwnerType"), exports);
__exportStar(require("./TransactionType"), exports);
__exportStar(require("./UnauthenticatedGetLeaderboardsResponse"), exports);
__exportStar(require("./UpdateGolfCourseBody"), exports);
__exportStar(require("./UpdatePlayersBody"), exports);
__exportStar(require("./UpdateTournamentBody"), exports);
__exportStar(require("./User"), exports);
__exportStar(require("./UserNote"), exports);
__exportStar(require("./UserPostMessageBody"), exports);
__exportStar(require("./UserRequest"), exports);
__exportStar(require("./VersionResponse"), exports);
