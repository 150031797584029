"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GolfCourseSourceToJSON = exports.GolfCourseSourceFromJSONTyped = exports.GolfCourseSourceFromJSON = exports.GolfCourseSource = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GolfCourseSource;
(function (GolfCourseSource) {
    GolfCourseSource["User"] = "User";
    GolfCourseSource["Admin"] = "Admin";
})(GolfCourseSource = exports.GolfCourseSource || (exports.GolfCourseSource = {}));
function GolfCourseSourceFromJSON(json) {
    return GolfCourseSourceFromJSONTyped(json, false);
}
exports.GolfCourseSourceFromJSON = GolfCourseSourceFromJSON;
function GolfCourseSourceFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GolfCourseSourceFromJSONTyped = GolfCourseSourceFromJSONTyped;
function GolfCourseSourceToJSON(value) {
    return value;
}
exports.GolfCourseSourceToJSON = GolfCourseSourceToJSON;
