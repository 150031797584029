"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddTeamBodyToJSON = exports.AddTeamBodyFromJSONTyped = exports.AddTeamBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
function AddTeamBodyFromJSON(json) {
    return AddTeamBodyFromJSONTyped(json, false);
}
exports.AddTeamBodyFromJSON = AddTeamBodyFromJSON;
function AddTeamBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tournamentID': json['tournamentID'],
        'name': json['name'],
        'startTime': json['startTime'],
        'startHole': json['startHole'],
        'identifier': !runtime_1.exists(json, 'identifier') ? undefined : json['identifier'],
        'teamHandicap': !runtime_1.exists(json, 'teamHandicap') ? undefined : json['teamHandicap'],
    };
}
exports.AddTeamBodyFromJSONTyped = AddTeamBodyFromJSONTyped;
function AddTeamBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tournamentID': value.tournamentID,
        'name': value.name,
        'startTime': value.startTime,
        'startHole': value.startHole,
        'identifier': value.identifier,
        'teamHandicap': value.teamHandicap,
    };
}
exports.AddTeamBodyToJSON = AddTeamBodyToJSON;
