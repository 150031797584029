import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {Placing, PlacingDataPoint, PlacingLabels, Tournament} from "client";
import {IToParCheck, toParChecker} from "../utils/toParChecker";
import LeaderboardTableNameCell from "./LeaderboardTableNameCell";

interface IProps {
	data: Array<Placing>;
	labels: PlacingLabels;
	showDetailedScores: boolean;
	tournament?: Tournament;
}

const LeaderBoardTable: React.FC<IProps> = (props: IProps) => {

	const {data, labels} = props;

	function headerFormatter(c): any {
		return c.text;
	}

	return (
		<div>
			<div className="leader-board-table-styler">
				<BootstrapTable
					bootstrap4={true}
					striped={true}
					condensed={true}
					wrapperClasses="table-responsive"
					rowStyle={{whiteSpace: "nowrap"}}
					noDataIndication={props.data === undefined ? "Loading..." : "No data yet."}
					defaultSorted={[{dataField: "position", order: "asc"}]}
					data={data ? data : []}
					keyField="_id"
					bodyStyle={{overflow: 'overlay'}}
					columns={[
						{
							dataField: "position[label]",
							text: labels.position,
							headerFormatter,
							classes: "positions-column font-weight-semi-bold"
						},
						{
							dataField: "name",
							text: labels.name,
							headerFormatter,
							formatter: (placingDataPoint: PlacingDataPoint, placing: Placing) => {
								return (
									<LeaderboardTableNameCell
										placing={placing}
										showDetailedScores={props.showDetailedScores}
										tournament={props.tournament}
									/>
								);
							},
							classes: "p-0",
						},
						{
							dataField: "toPar",
							text: labels.toPar,
							headerFormatter,
							classes: "text-center score-column",
							headerClasses: "text-center",
							formatter: (c) => {
								const parCheck: IToParCheck = toParChecker(c.label);
								return (<span className={parCheck.className}>{parCheck.display}</span>);
							},
						},
						{
							dataField: "thru[label]",
							text: labels.thru,
							headerFormatter,
							classes: "text-center score-column",
							headerClasses: "text-center",
						},
						{
							dataField: "score[label]",
							text: labels.score,
							headerFormatter,
							classes: "text-center score-column",
							headerClasses: "text-center",
						},
					]}
				/>

				<div className="table-bottom-border-helper">
					<div className="table-bottom-border-helper-inner"/>
				</div>
			</div>
		</div>
	);
};

export default LeaderBoardTable;
