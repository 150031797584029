"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TournamentOptionsToJSON = exports.TournamentOptionsFromJSONTyped = exports.TournamentOptionsFromJSON = void 0;
const runtime_1 = require("../runtime");
function TournamentOptionsFromJSON(json) {
    return TournamentOptionsFromJSONTyped(json, false);
}
exports.TournamentOptionsFromJSON = TournamentOptionsFromJSON;
function TournamentOptionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'description': json['description'],
        'useHandicap': json['useHandicap'],
        'applyHandicapPercentage': json['applyHandicapPercentage'],
        'teamHandicapPercentages': !runtime_1.exists(json, 'teamHandicapPercentages') ? undefined : json['teamHandicapPercentages'],
        'strokePlayPlayersCounted': !runtime_1.exists(json, 'strokePlayPlayersCounted') ? undefined : json['strokePlayPlayersCounted'],
        'redVsBluesMode': json['redVsBluesMode'],
        'primaryTieBreakMethod': json['primaryTieBreakMethod'],
    };
}
exports.TournamentOptionsFromJSONTyped = TournamentOptionsFromJSONTyped;
function TournamentOptionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'useHandicap': value.useHandicap,
        'applyHandicapPercentage': value.applyHandicapPercentage,
        'teamHandicapPercentages': value.teamHandicapPercentages,
        'strokePlayPlayersCounted': value.strokePlayPlayersCounted,
        'redVsBluesMode': value.redVsBluesMode,
        'primaryTieBreakMethod': value.primaryTieBreakMethod,
    };
}
exports.TournamentOptionsToJSON = TournamentOptionsToJSON;
