"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRequestToJSON = exports.UserRequestFromJSONTyped = exports.UserRequestFromJSON = void 0;
const _1 = require("./");
function UserRequestFromJSON(json) {
    return UserRequestFromJSONTyped(json, false);
}
exports.UserRequestFromJSON = UserRequestFromJSON;
function UserRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'user': _1.UserFromJSON(json['user']),
        'requestMessage': json['requestMessage'],
        'creationDate': json['creationDate'],
    };
}
exports.UserRequestFromJSONTyped = UserRequestFromJSONTyped;
function UserRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'user': _1.UserToJSON(value.user),
        'requestMessage': value.requestMessage,
        'creationDate': value.creationDate,
    };
}
exports.UserRequestToJSON = UserRequestToJSON;
