import React, {useEffect, useRef, useState} from "react";
import {Modal} from "reactstrap";

interface IProps {
	isOpen: boolean
	src: string;
	time: number;
	onClose(): void;
}

const TimedAd: React.FC<IProps> = (props: IProps) => {

	const {isOpen, src, time} = props;
	const [loaded, setLoaded] = useState(false);
	const loadedRef = useRef(loaded);
	loadedRef.current = loaded;
	const [displayTime, setDisplayTime] = useState({n: 0}); // nested in an object because can't user ref "current" value with numbers
	const displayTimeRef = useRef(displayTime);
	displayTimeRef.current = displayTime;
	const [opacity, setOpacity] = useState(0);

	/**
	 * start timer for 10 seconds and if the image hasn't loaded by then, call the onClose function
	 *
	 */
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (!loadedRef.current) {
				props.onClose();
			}
		}, 10000);

		return function cleanupLoadCheck() {
			clearTimeout(timeout);
		}
	}, []);

	/**
	 * listen for the loaded useState to become true, then start the count down
	 *
	 */
	useEffect(() => {
		setOpacity(1);

		let t = null;
		let i = null;

		if (loaded) {
			t = setTimeout(() => {
				props.onClose();
			}, time);

			setDisplayTime({n: time});
			i = setInterval(() => {
				setDisplayTime({n: displayTimeRef.current.n - 1000})
			}, 1000);
		}

		return function cleanupTimer() {
			clearTimeout(t);
			clearInterval(i);
		}
	}, [loaded]);

	function handleLoad(): void {
		setLoaded(true);
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			contentClassName="bg-transparent border-transparent border-0"
		>
			<div className="w-100 d-flex flex-column justify-content-center" style={{maxHeight: "80vh"}}>
				{!loaded && (
					<div className="my-4">
						<h5 className="text-center text-light font-italic">
							Loading... Please Wait.
						</h5>
					</div>
				)}

				<img
					src={src}
					style={{maxWidth: "100%", maxHeight: "70vh", objectFit: "contain", opacity: opacity}}
					onLoad={handleLoad}
				/>

				{(loaded === true && displayTime.n > 0) && (
					<div className="my-4">
						<h6 className="text-center text-light font-italic">
							{`Closing in ${displayTime.n / 1000} second${displayTime.n > 1000 ? "s" : ""}.`}
						</h6>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default TimedAd;
