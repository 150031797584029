import React, {ReactElement, ReactNode, useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import AnimatedPageVideoBackground from "../components/AnimatedPageVideoBackground";
import AnimatedPageHeader from "../components/AnimatedPageHeader";
import {useQuery} from "../utils/useQuery";
import {Asset, PlayerGroupings, TeamWithPlayersWithCalculatedHandicap, TournamentApi, Tournament, TournamentStartType} from "client";
import getConfig from "../utils/getConfig";
import {addError} from "../redux/meta/MetaActions";
import {chunk} from "lodash";
import AnimatedLeaderBoard2Row from "../components/AnimatedLeaderBoard2Row";
import AnimatedPlayerDetailsRow from "../components/AnimatedPlayerDetailsRow";
import {parseTournamentType} from "../utils/parseTournamentType";
import moment from "moment";
import classNames from "classnames";

interface IProps {
	dispatch?: any;
}

function AnimatedPlayerDetails(props: IProps): ReactElement {

	const query = useQuery();
	const tournamentID: string = query.get("tournamentID");
	const animationDisabled: string = query.get("animationDisabled"); // used to disabled animation & height calculations in the TO portal due to iframe & doc height issues.
	const tableRowsRef = useRef<HTMLDivElement>();

	const [animatedPageBannerAd, setAnimatedPageBannerAd] = useState<Asset & { url: string }>(undefined);
	const [tournament, setTournament] = useState<Tournament>(undefined);
	const [initialTableRowsHeight, setInitialTableRowsHeight] = useState(0);
	const [playerGroupings, setPlayerGroupings] = useState<PlayerGroupings>();
	const [renderGroups, setRenderGroups] = useState<Array<Array<TeamWithPlayersWithCalculatedHandicap>>>([]);
	const isShotgun = playerGroupings?.tournamentStartType === TournamentStartType.SHOTGUN;

	/**
	 * On initial render request data from our api.
	 *
	 */
	useEffect(() => {
		if (tournamentID) {
			void getPlayerStartInfo();
		}
	}, []);

	/**
	 * Make a reference to the "original" height of the content container, which should be the entire available space
	 * underneath the top, "fixed" content of this UI. This will give us the real value before the content renders in
	 * dynamically & alters the height of the ref'd div (the content will overflow, but be hidden, but if we check the
	 * height at that point the returned value will include all the content & the overflow, or "true" height so to speak.
	 *
	 */
	useEffect(() => {
		if (tableRowsRef && initialTableRowsHeight === 0) {
			setInitialTableRowsHeight(tableRowsRef.current.clientHeight);
		}
	}, [tableRowsRef]);

	/**
	 * When the player teams are set in state from the api, divide the teams into "groups" we can cycle through in the UI.
	 *
	 */
	useEffect(() => {
		const teams = playerGroupings?.teams;
		if (teams?.length > 0) {
			const _renderGroups = chunk<TeamWithPlayersWithCalculatedHandicap>(teams, Math.floor(initialTableRowsHeight / 99) - 1); //todo use css variable
			setRenderGroups(_renderGroups);
		}
	}, [JSON.stringify(playerGroupings)]);

	/**
	 * Call our api to get the leaderboard data that we will display.
	 *
	 */
	async function getPlayerStartInfo(): Promise<void> {
		try {
			const unauthedLeaderboardsRes = await new TournamentApi(getConfig()).unauthenticatedGetLeaderboards({tournamentID});
			const playersRes = await new TournamentApi(getConfig()).getPlayers({tournamentID});

			setAnimatedPageBannerAd(unauthedLeaderboardsRes?.animatedLeaderboardBanner as Asset & { url: string });
			setTournament(unauthedLeaderboardsRes?.leaderboardCollection?.tournament);
			setPlayerGroupings(playersRes);
		} catch (e) {
			props.dispatch(addError(e));
		}
	}

	/**
	 * Render each table row & save refs to array for height calculations.
	 * Last item will proc calculations via the useEffect knowing that we have all the refs.
	 *
	 * @param team
	 * @param i
	 */
	function renderTableRow(team: TeamWithPlayersWithCalculatedHandicap, i: number): ReactNode {
		const rawTeams = playerGroupings.teams;
		const _teams: Array<TeamWithPlayersWithCalculatedHandicap> = [];

		for (let j = 0; j < renderGroups.length; j++) {
			_teams.push(renderGroups[j][i]);
		}

		return (
			<AnimatedPlayerDetailsRow
				teams={animationDisabled ? [rawTeams?.[i]] : _teams}
				teamsPerPage={animationDisabled ? rawTeams?.length : renderGroups[0].length}
				offset={i}
				animationDisabled={animationDisabled === "true"}
				isShotgun={isShotgun}
			/>
		);
	}

	return (
		<div className="animated-player-details">
			<AnimatedPageVideoBackground
				videoSrc={process.env.PUBLIC_URL + "/videos/animated-board-background.mp4"}
				videpType="video/mp4"
			/>

			<div className="animated-player-details_container">
				<div className="animated-player-details_content">
					<AnimatedPageHeader
						tournament={tournament}
						title={tournament?.golfCourse?.golfClub}
						name={tournament?.name}
						subtitle={parseTournamentType(tournament?.primaryGame?.tournamentType) + (isShotgun ? ` • ${moment(playerGroupings?.tournamentStartTime).format("hh:mm A")}` : "")}
						animatedLeaderboardBannerURL={animatedPageBannerAd?.url}
					/>

					<div className="animated-player-details_content_bottom">
						<div className="animated-player-details_content_bottom_table-labels">
							{!isShotgun && (
								<div className="animated-player-details_content_bottom_table-labels_tee-time">
									<p>TEE TIME</p>
								</div>
							)}

							<div
								className={classNames("animated-player-details_content_bottom_table-labels_hole", {
									"animated-player-details_content_bottom_table-labels_hole-shotgun": isShotgun,
								})}
							>
								<p>HOLE</p>
							</div>
						</div>

						{animationDisabled ? (
							<div
								ref={tableRowsRef}
								className="animated-player-details_content_bottom_table-rows"
							>
								{playerGroupings?.teams?.map(renderTableRow)}
							</div>
						) : (
							<div
								ref={tableRowsRef}
								className="animated-player-details_content_bottom_table-rows"
							>
								{renderGroups[0]?.map(renderTableRow)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
	}
})(AnimatedPlayerDetails);