import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import LeaderBoardHeader from "../components/LeaderBoardHeader";
import LeaderBoardTable from "../components/LeaderBoardTable";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {Leaderboard, LeaderboardCollection, Tournament, TournamentApi} from "client";
import {getReturnedLeaderBoardInfo} from "../utils/getReturnedLeaderBoardInfo";
import ToggleLeaderboardModal from "../components/ToggleLeaderboardModal";
import {omit} from "lodash";

export interface ILeaderboardCollectionNoTournamentDetails extends Omit<LeaderboardCollection, "tournament"> {

}

/**
 * Determine the default selected leaderboard based
 * on the keys that exist on the getLeaderboards response.
 *
 * @param boards
 */
export function findDefaultSelectedGameType(boards: LeaderboardCollection): keyof LeaderboardCollection {
	if (boards.tournament.primaryGame.defaultLeaderboard && boards[boards.tournament.primaryGame.defaultLeaderboard]) {
		return boards.tournament.primaryGame.defaultLeaderboard;
	}

	if (boards?.teamNetStableford && Object.keys(boards.teamNetStableford).length > 0) {
		return "teamNetStableford";
	} else if (boards?.teamGrossStableford && Object.keys(boards.teamGrossStableford).length > 0) {
		return "teamGrossStableford";
	} else if (boards?.teamNet && Object.keys(boards.teamNet).length > 0) {
		return "teamNet";
	} else {
		return "teamGross";
	}
}

interface IProps {
	token?: string;
	dispatch?: any;
}

const LeaderBoardPage: React.FC<IProps> = (props: IProps) => {

	const [leaderBoards, setLeaderBoards] = useState<ILeaderboardCollectionNoTournamentDetails>();
	const [tournament, setTournament] = useState<Tournament>();
	const [selectedGame, setSelectedGame] = useState<keyof LeaderboardCollection>(); // track which of the primary / secondary / gross / net is selected
	const [showToggleModal, setShowToggleModal] = useState(false);

	useEffect(() => {
		getLeaderBoardData().then().catch();
	}, []);

	/**
	 * Call api to get the leaderboard data
	 *
	 */
	async function getLeaderBoardData(): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			const res: LeaderboardCollection = await new TournamentApi(getConfig(props.token)).getLeaderboards();
			setLeaderBoards(omit(res, "tournament"));
			setTournament(res.tournament);
			setSelectedGame(findDefaultSelectedGameType(res));
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	function toggleShowToggleModal(): void {
		setShowToggleModal(!showToggleModal);
	}

	function onSelectNewBoard(selection: keyof LeaderboardCollection): void {
		setSelectedGame(selection);
		setShowToggleModal(false);
	}

	return (
		<React.Fragment>
			<ToggleLeaderboardModal
				currentGame={selectedGame}
				leaderboards={leaderBoards}
				isOpen={showToggleModal}
				onClose={toggleShowToggleModal}
				onSelect={onSelectNewBoard}
			/>

			<div className="indented-page">
				{leaderBoards && selectedGame && (
					<React.Fragment>
						<LeaderBoardHeader
							leftTitle={(leaderBoards[selectedGame] as Leaderboard)?.header?.leftTitle}
							rightTitleA={(leaderBoards[selectedGame] as Leaderboard)?.header?.rightTitleA}
							rightTitleB={(leaderBoards[selectedGame] as Leaderboard)?.header?.rightTitleB}
							enableToggle={getReturnedLeaderBoardInfo(leaderBoards) > 1}
							onToggle={toggleShowToggleModal}
						/>

						<LeaderBoardTable
							data={(leaderBoards[selectedGame] as Leaderboard)?.placings}
							labels={(leaderBoards[selectedGame] as Leaderboard)?.labels}
							showDetailedScores={true}
							tournament={tournament}
						/>
					</React.Fragment>
				)}
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(LeaderBoardPage);
