"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetScoresResponseToJSON = exports.GetScoresResponseFromJSONTyped = exports.GetScoresResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function GetScoresResponseFromJSON(json) {
    return GetScoresResponseFromJSONTyped(json, false);
}
exports.GetScoresResponseFromJSON = GetScoresResponseFromJSON;
function GetScoresResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'entries': (json['entries'].map(_1.PlayerEntryFromJSON)),
        'headerText': json['headerText'],
        'counterArray': json['counterArray'],
        'holeLabels': (json['holeLabels'].map(_1.HoleLabelFromJSON)),
        'openHole': json['openHole'],
        'scoreToPar': json['scoreToPar'],
        'isNet': json['isNet'],
        'showAd': json['showAd'],
        'isFinishedScoring': json['isFinishedScoring'],
        'closedScoring': json['closedScoring'],
        'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
    };
}
exports.GetScoresResponseFromJSONTyped = GetScoresResponseFromJSONTyped;
function GetScoresResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'entries': (value.entries.map(_1.PlayerEntryToJSON)),
        'headerText': value.headerText,
        'counterArray': value.counterArray,
        'holeLabels': (value.holeLabels.map(_1.HoleLabelToJSON)),
        'openHole': value.openHole,
        'scoreToPar': value.scoreToPar,
        'isNet': value.isNet,
        'showAd': value.showAd,
        'isFinishedScoring': value.isFinishedScoring,
        'closedScoring': value.closedScoring,
        'message': value.message,
    };
}
exports.GetScoresResponseToJSON = GetScoresResponseToJSON;
