"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HoleLabelToJSON = exports.HoleLabelFromJSONTyped = exports.HoleLabelFromJSON = void 0;
const runtime_1 = require("../runtime");
function HoleLabelFromJSON(json) {
    return HoleLabelFromJSONTyped(json, false);
}
exports.HoleLabelFromJSON = HoleLabelFromJSON;
function HoleLabelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'holeNumber': json['holeNumber'],
        'par': json['par'],
        'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
    };
}
exports.HoleLabelFromJSONTyped = HoleLabelFromJSONTyped;
function HoleLabelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'holeNumber': value.holeNumber,
        'par': value.par,
        'message': value.message,
    };
}
exports.HoleLabelToJSON = HoleLabelToJSON;
