import React, {useEffect} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import TopBanner from "./components/TopBanner";
import LandingPage from "./pages/LandingPage";
import AnimatedLeaderBoard from "./pages/AnimatedLeaderBoard";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import ScoreCardPage from "./pages/ScoreCardPage";
import LeaderBoardPage from "./pages/LeaderBoardPage";
import ChatPage from "./pages/ChatPage";
import NotFoundPage from "./pages/NotFoundPage";
import NavigationBar from "./components/NavigationBar";
import {connect} from "react-redux";
import {IStore} from "./redux/defaultStore";
import SponsorPage from "./pages/SponsorPage";
import AdChecker from "./components/AdChecker";
import ScrollToTop from "./components/ScrollToTop";
import LoggedInHandler from "./components/LoggedInHandler";
import AnimatedLeaderBoardPage2 from "./pages/AnimatedLeaderBoardPage2";
import AnimatedPlayerDetails from "./pages/AnimatedPlayerDetails";

export const navHeight: number = 70;

const routesToHideNav: string[] = [
	"/animated-leader-board",
];

interface IProps {
	token?: string;
	match?: any;
}

const AppContent: React.FC<IProps> = (props: IProps) => {

	let _navHeight: number = navHeight;
	const location = useLocation();
	if (routesToHideNav.includes(location.pathname)) {
		_navHeight = 0;
	}

	// const mainHeight: any = props.token ? `calc(100vh - ${_navHeight}px)` : "100%";
	const mainHeight: any = props.token ? `calc(100vh - ${_navHeight}px)` : `calc(100vh - 0px)`;

	return (
		<div style={{height: "100vh", overflowX: "hidden"}}>
			<ScrollToTop/>

			{props.token && (
				<AdChecker/>
			)}

			<TopBanner/>

			<Route path="*/:token" component={LoggedInHandler}/>

			<Switch>
				<Route exact path="/leader-board-view" component={AnimatedLeaderBoardPage2}/>
				<Route exact path="/team-start-details-view" component={AnimatedPlayerDetails}/>
				<Route exact path="/:token" component={LandingPage}/>
				{props.token && (
					<React.Fragment>
						<AuthenticatedRoute exact path="/score-card/:token" component={ScoreCardPage}/>
						<AuthenticatedRoute exact path="/leader-board/:token" component={LeaderBoardPage}/>
						<AuthenticatedRoute exact path="/chat/:token" component={ChatPage}/>
						<AuthenticatedRoute exact path="/sponsor/:token" component={SponsorPage}/>
					</React.Fragment>
				)}
				<Route exact path="/404" component={NotFoundPage}/>
				<Redirect to="/404"/>
			</Switch>

			{![`/${props.token}`, "/404", "/animated-leader-board",].includes(location.pathname) && (
				<div style={{height: navHeight * 2.75}}/>
			)}

			{location.pathname === "/leader-board-view" && (
				<div style={{height: navHeight}}/>
			)}

			{props.token && (
				<React.Fragment>
					{![`/${props.token}`, "/404", "/leader-board-view", "/team-start-details-view"].includes(location.pathname) && (
						<div className="w-100 bg-info"
							 style={{position: "fixed", bottom: 0, left: 0, height: navHeight}}>
							<NavigationBar height={navHeight}/>
						</div>
					)}
				</React.Fragment>
			)}
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(AppContent);
