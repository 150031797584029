"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TournamentWithActiveToJSON = exports.TournamentWithActiveFromJSONTyped = exports.TournamentWithActiveFromJSON = void 0;
const _1 = require("./");
function TournamentWithActiveFromJSON(json) {
    return TournamentWithActiveFromJSONTyped(json, false);
}
exports.TournamentWithActiveFromJSON = TournamentWithActiveFromJSON;
function TournamentWithActiveFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tournament': _1.TournamentFromJSON(json['tournament']),
        'isActive': json['isActive'],
    };
}
exports.TournamentWithActiveFromJSONTyped = TournamentWithActiveFromJSONTyped;
function TournamentWithActiveToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tournament': _1.TournamentToJSON(value.tournament),
        'isActive': value.isActive,
    };
}
exports.TournamentWithActiveToJSON = TournamentWithActiveToJSON;
