import {TournamentType} from "client";

export function parseTournamentType(tournamentType: TournamentType): string {
	switch (tournamentType) {
		case TournamentType.SCRAMBLE:
			return "Scramble";
		case TournamentType.BESTBALLSTROKEPLAY:
			return "Best Ball Stroke Play";
		case TournamentType.AUDI:
			return "Audi";
		case TournamentType.STROKEPLAY:
			return "Stroke Play";
		case TournamentType.MATCHPLAYINDIVIDUAL:
			return "Match Play Individual";
		case TournamentType.MATCHPLAYBETTERBALL:
			return "Match Play Better Ball";
		case TournamentType.MATCHPLAYGREENSOME:
			return "Match Play Greensome"
		case TournamentType.MATCHPLAYFOURSOME:
			return "Match Play Foursome";
		case TournamentType.REDVSBLUERYDERSTYLEEVENT:
			return "Red VS Blue Ryder Style Event";
	}
}