"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddPlayerBodyToJSON = exports.AddPlayerBodyFromJSONTyped = exports.AddPlayerBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
function AddPlayerBodyFromJSON(json) {
    return AddPlayerBodyFromJSONTyped(json, false);
}
exports.AddPlayerBodyFromJSON = AddPlayerBodyFromJSON;
function AddPlayerBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'male': !runtime_1.exists(json, 'male') ? undefined : json['male'],
        'handicap': !runtime_1.exists(json, 'handicap') ? undefined : json['handicap'],
        'handicapIndex': !runtime_1.exists(json, 'handicapIndex') ? undefined : json['handicapIndex'],
        'tournamentID': json['tournamentID'],
        'teamID': !runtime_1.exists(json, 'teamID') ? undefined : json['teamID'],
        'phoneNumber': !runtime_1.exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
    };
}
exports.AddPlayerBodyFromJSONTyped = AddPlayerBodyFromJSONTyped;
function AddPlayerBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'firstName': value.firstName,
        'lastName': value.lastName,
        'male': value.male,
        'handicap': value.handicap,
        'handicapIndex': value.handicapIndex,
        'tournamentID': value.tournamentID,
        'teamID': value.teamID,
        'phoneNumber': value.phoneNumber,
        'email': value.email,
    };
}
exports.AddPlayerBodyToJSON = AddPlayerBodyToJSON;
