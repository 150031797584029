import React, {useEffect, useRef} from "react";
import {withRouter} from "react-router";
import {useLocation} from "react-router-dom";

const ScrollToTop: React.FC<{history: any}> = ({history}) => {

	const location = useLocation();
	const scrollHelperRef: any = useRef();

	useEffect(() => {
		const f = history.listen(() => {
			if (!["/chat"].includes(location.pathname)) {
				scrollHelperRef.current.scrollIntoView();
			}
		});

		return () => {
			f();
		}

	}, [scrollHelperRef])

	return (
		<div
			ref={scrollHelperRef}
			style={{top: 0, left: 0}}
		/>
	);
};

export default withRouter(ScrollToTop);
