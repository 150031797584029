import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Placing, Score, Tournament} from "client";
import classNames from "classnames";
import {getParFieldForScoreCard} from "../utils/getParFieldForScoreCard";
import {chunk} from "lodash";

interface IProps {
	dispatch?: any;
	token?: string;
	isOpen: boolean;
	onClose: () => void;
	placing: Placing;
	tournament: Tournament;
}

const LeaderboardTableDetailedScoreModal: React.FC<IProps> = (props) => {

	/**
	 * Render 1 "section" of the detailed scorecard; 1 section represents holes 1-9 or 10-18 (or less than 9 if less than
	 * 9 holes are selected - the most holes represented in a single section should be 9).
	 *
	 * @param labelDoubleArray
	 */
	function renderScoreSection(labelDoubleArray: Array<Array<string | number>>): ReactNode {
		return labelDoubleArray.map((labelGroup, i: number) => {
			let scoreBackgroundClass: string;
			if (typeof labelGroup[1] === "number" && typeof labelGroup[2] === "number") {
				console.log("labelGroup[1]:", labelGroup[1]);
				console.log("labelGroup[2]:", labelGroup[2]);
				if (labelGroup[2] <= 1) {
					scoreBackgroundClass = "ltdsm_score_ace"; // ace - hole in 1
				} else if ((labelGroup[1] - labelGroup[2]) >= 2) {
					scoreBackgroundClass = "ltdsm_score_eagle"; // eagle - 2 under par
				} else if ((labelGroup[1] - labelGroup[2]) === 1) {
					scoreBackgroundClass = "ltdsm_score_birdie"; // birdie - 1 under par
				} else if ((labelGroup[1] - labelGroup[2]) === -1) {
					scoreBackgroundClass = "ltdsm_score_bogey"; // bogey - 1 over par
				} else if ((labelGroup[1] - labelGroup[2]) < -1) {
					scoreBackgroundClass = "ltdsm_score_double-bogey"; // double bogey - 2 over par
				}
			}

			return (
				<div
					className={classNames("ltdsm_scorecard_section_col", {
						"ltdsm_scorecard_section_col_0": i === 0,
					})}
				>
					<div className="ltdsm_scorecard_section_col_cell ltdsm_scorecard_section_col_cell_top">
						{labelGroup[0]}
					</div>

					<div className="ltdsm_scorecard_section_col_cell ltdsm_scorecard_section_col_cell_mid">
						{labelGroup[1]}
					</div>

					<div className={classNames("ltdsm_scorecard_section_col_cell ltdsm_scorecard_section_col_cell_bottom", scoreBackgroundClass)}>
						{labelGroup[2]}
					</div>
				</div>
			);
		});
	}

	/**
	 * Generate the array for displaying the score info, generated the full array of hole labels, pars, and scores,
	 * and then chunk into groups of 9, reverse it, and manually add the "HOLE", "PAR", "Score" labels to the start
	 * of each chunked array.
	 *
	 */
	function generateLabels(): Array<Array<Array<string | number>>> {
		let labelGroupArray: Array<Array<Array<string | number>>>;
		const labelGroup: Array<Array<string | number>> = [];

		Object.keys(props.tournament.golfCourse.holes).forEach((hole) => {
			const scoreCardHole: Score = props.placing.scoreCard.holes[hole];
			labelGroup.push([
				hole,
				getParFieldForScoreCard(props.tournament.golfCourse.holes[hole], props.tournament.primaryGame.tournamentType, props.tournament.isMixedGender, props.placing.male),
				scoreCardHole?.gross !== undefined ? (props.tournament.primaryGame.useHandicap ? scoreCardHole?.net : scoreCardHole?.gross) : "-",
			]);
		})

		labelGroupArray = chunk(labelGroup, 9).reverse().map((g) => {
			g.splice(0, 0, ["HOLE", "PAR", "Score"]);
			return g;
		});

		return labelGroupArray;
	}

	return (
		<Modal
			isOpen={props.isOpen}
			centered={true}
			fade={true}
			toggle={props.onClose}
		>
			<ModalHeader toggle={props.onClose}>
				{props.tournament?.name || "Scorecard"}
			</ModalHeader>

			<ModalBody className="ltdsm">
				<div className="ltdsm_team-info">
					<p className="mb-0 font-weight-semi-bold">{props.placing?.name?.label}</p>
					<p className="mb-0 font-weight-light">{props.placing?.name?.subtitle}</p>
				</div>

				<div className="ltdsm_scorecard">
					{generateLabels().map((labelGroup) => {
						return (
							<div className="ltdsm_scorecard_section">
								{renderScoreSection(labelGroup)}
							</div>
						);
					})}
				</div>

				<div className="ltdsm_score-info">
					<div className="ltdsm_score-info_item">
						<div className="ltdsm_score-info_item_ball ltdsm_score-info_item_ball_ace"/>
						<div className="ltdsm_score-info_item_label">
							Aces
						</div>
					</div>

					<div className="ltdsm_score-info_item">
						<div className="ltdsm_score-info_item_ball ltdsm_score-info_item_ball_eagle"/>
						<div className="ltdsm_score-info_item_label">
							Eagles
						</div>
					</div>

					<div className="ltdsm_score-info_item">
						<div className="ltdsm_score-info_item_ball ltdsm_score-info_item_ball_par"/>
						<div className="ltdsm_score-info_item_label">
							Pars
						</div>
					</div>

					<div className="ltdsm_score-info_item">
						<div className="ltdsm_score-info_item_ball ltdsm_score-info_item_ball_birdie"/>
						<div className="ltdsm_score-info_item_label">
							Birdies
						</div>
					</div>

					<div className="ltdsm_score-info_item">
						<div className="ltdsm_score-info_item_ball ltdsm_score-info_item_ball_bogey"/>
						<div className="ltdsm_score-info_item_label">
							Bogeys
						</div>
					</div>

					<div className="ltdsm_score-info_item">
						<div className="ltdsm_score-info_item_ball ltdsm_score-info_item_ball_double-bogey"/>
						<div className="ltdsm_score-info_item_label">
							Double Bogeys
						</div>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(LeaderboardTableDetailedScoreModal);
