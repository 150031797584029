import React, {ReactNode, useState} from "react";
import {Button, Modal} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {EnterScoreRequest, Score, TournamentApi} from "client";
import getConfig from "../utils/getConfig";
import CustomModalHeader from "./CustomModalHeader";

interface IProps {
	token?: string;
	dispatch?: any;
	isOpen: boolean;
	score?: Score;
	team: string;
	hole: number;
	playerID?: string;

	closeModal(): void;

	onDone(enterScoreRequest: EnterScoreRequest): Promise<void>;
}

const defaultLocalScoreDisplay: string = "...";

const ScoreEntryModal: React.FC<IProps> = (props: IProps) => {

	const {token, isOpen, score, team, hole, playerID} = props;
	const [localScore, setLocalScore] = useState(score ? score.gross : defaultLocalScoreDisplay as unknown as number);

	async function submit(): Promise<void> {
		// props.dispatch(incrementLoading());

		await props.onDone({
			enterScoreBody: {
				hole,
				score: localScore,
				playerID: playerID ? playerID : undefined,
			},
		});
		// try {
		// 	await new TournamentApi(getConfig(token)).enterScore({
		// 		enterScoreBody: {
		// 			hole,
		// 			score: localScore,
		// 			playerID: playerID ? playerID : undefined,
		// 		},
		// 	});
		// 	props.dispatch(decrementLoading());
		// 	props.onDone();
		// } catch (e) {
		// 	props.dispatch(decrementLoading());
		// 	props.dispatch(addError(e));
		// }

	}

	function closeHelper(): void {
		props.closeModal();
		setTimeout(() => {
			setLocalScore(score ? score.gross : ("" as unknown as number));
		}, 250)
	}

	function concatScore(n: number): void {
		if (n === 0 && !localScore) {
			return;
		}
		if (localScore === undefined || (localScore === defaultLocalScoreDisplay as unknown as number)) {
			setLocalScore(n);
		} else {
			let newValue: number = parseInt(localScore.toString() + n.toString());
			if (newValue > 19) {
				newValue = 19;
			} else if (newValue < 1) {
				newValue = 1;
			}
			setLocalScore(newValue);
		}
	}

	function clear(): void {
		setLocalScore(undefined);
	}

	function makeMainButtons(): ReactNode {
		return [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((n: number, i: number) => {

			function onClickHelper(): void {
				concatScore(n);
			}

			return (
				<div style={{width: "33%"}} className="score-entry-button">
					<Button
						color="lightBlue"
						className="font-weight-bold"
						onClick={onClickHelper}
					>
						{n}
					</Button>
				</div>
			);
		});
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			contentClassName="bg-transparent custom-modal-container modal-max-400 user-select-none"
		>
			<CustomModalHeader title={`Hole #${props.hole} - ${team}`} onClick={closeHelper}/>

			<div className="bg-white p-3">

				<div className="score-entry-input-display-container">
					<img
						src={`${process.env.PUBLIC_URL}/icons/borderhex.png`}
						style={{height: 90}}
						draggable={false}
					/>

					<h4 className="score-display">
						{localScore !== undefined ? localScore : "..."}
					</h4>
				</div>

				<div className="score-entry-buttons-row">
					{makeMainButtons()}

					<div style={{width: "66%"}} className="score-entry-button">
						<Button color="lightBlue" className="font-weight-bold" onClick={clear}>
							Clear
						</Button>
					</div>
				</div>

				<hr/>

				<Button color="darkBlue" className="w-100" style={{marginTop: "0.5rem"}} onClick={submit}>
					Submit
				</Button>

			</div>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(ScoreEntryModal);
