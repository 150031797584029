"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HoleInfoToJSON = exports.HoleInfoFromJSONTyped = exports.HoleInfoFromJSON = void 0;
const runtime_1 = require("../runtime");
function HoleInfoFromJSON(json) {
    return HoleInfoFromJSONTyped(json, false);
}
exports.HoleInfoFromJSON = HoleInfoFromJSON;
function HoleInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'par': !runtime_1.exists(json, 'par') ? undefined : json['par'],
        'malePar': !runtime_1.exists(json, 'malePar') ? undefined : json['malePar'],
        'femalePar': !runtime_1.exists(json, 'femalePar') ? undefined : json['femalePar'],
        'handicap': !runtime_1.exists(json, 'handicap') ? undefined : json['handicap'],
        'maleHandicap': !runtime_1.exists(json, 'maleHandicap') ? undefined : json['maleHandicap'],
        'femaleHandicap': !runtime_1.exists(json, 'femaleHandicap') ? undefined : json['femaleHandicap'],
        'selected': !runtime_1.exists(json, 'selected') ? undefined : json['selected'],
    };
}
exports.HoleInfoFromJSONTyped = HoleInfoFromJSONTyped;
function HoleInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'par': value.par,
        'malePar': value.malePar,
        'femalePar': value.femalePar,
        'handicap': value.handicap,
        'maleHandicap': value.maleHandicap,
        'femaleHandicap': value.femaleHandicap,
        'selected': value.selected,
    };
}
exports.HoleInfoToJSON = HoleInfoToJSON;
