"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerGroupingsToJSON = exports.PlayerGroupingsFromJSONTyped = exports.PlayerGroupingsFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PlayerGroupingsFromJSON(json) {
    return PlayerGroupingsFromJSONTyped(json, false);
}
exports.PlayerGroupingsFromJSON = PlayerGroupingsFromJSON;
function PlayerGroupingsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'teamSize': json['teamSize'],
        'useHandicap': json['useHandicap'],
        'isMixedGender': json['isMixedGender'],
        'showTeamHandicap': json['showTeamHandicap'],
        'tournamentStartType': !runtime_1.exists(json, 'tournamentStartType') ? undefined : _1.TournamentStartTypeFromJSON(json['tournamentStartType']),
        'teeTimeStartType': !runtime_1.exists(json, 'teeTimeStartType') ? undefined : _1.TeeTimeStartTypeFromJSON(json['teeTimeStartType']),
        'tournamentStartTime': !runtime_1.exists(json, 'tournamentStartTime') ? undefined : json['tournamentStartTime'],
        'tournamentTimeZone': !runtime_1.exists(json, 'tournamentTimeZone') ? undefined : json['tournamentTimeZone'],
        'teams': (json['teams'].map(_1.TeamWithPlayersWithCalculatedHandicapFromJSON)),
        'unassignedPlayers': !runtime_1.exists(json, 'unassignedPlayers') ? undefined : (json['unassignedPlayers'].map(_1.PlayerFromJSON)),
    };
}
exports.PlayerGroupingsFromJSONTyped = PlayerGroupingsFromJSONTyped;
function PlayerGroupingsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'teamSize': value.teamSize,
        'useHandicap': value.useHandicap,
        'isMixedGender': value.isMixedGender,
        'showTeamHandicap': value.showTeamHandicap,
        'tournamentStartType': _1.TournamentStartTypeToJSON(value.tournamentStartType),
        'teeTimeStartType': _1.TeeTimeStartTypeToJSON(value.teeTimeStartType),
        'tournamentStartTime': value.tournamentStartTime,
        'tournamentTimeZone': value.tournamentTimeZone,
        'teams': (value.teams.map(_1.TeamWithPlayersWithCalculatedHandicapToJSON)),
        'unassignedPlayers': value.unassignedPlayers === undefined ? undefined : (value.unassignedPlayers.map(_1.PlayerToJSON)),
    };
}
exports.PlayerGroupingsToJSON = PlayerGroupingsToJSON;
