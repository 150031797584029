"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlacingDataPointToJSON = exports.PlacingDataPointFromJSONTyped = exports.PlacingDataPointFromJSON = void 0;
const runtime_1 = require("../runtime");
function PlacingDataPointFromJSON(json) {
    return PlacingDataPointFromJSONTyped(json, false);
}
exports.PlacingDataPointFromJSON = PlacingDataPointFromJSON;
function PlacingDataPointFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'label': !runtime_1.exists(json, 'label') ? undefined : json['label'],
        'subtitle': !runtime_1.exists(json, 'subtitle') ? undefined : json['subtitle'],
        'colour': !runtime_1.exists(json, 'colour') ? undefined : json['colour'],
    };
}
exports.PlacingDataPointFromJSONTyped = PlacingDataPointFromJSONTyped;
function PlacingDataPointToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'label': value.label,
        'subtitle': value.subtitle,
        'colour': value.colour,
    };
}
exports.PlacingDataPointToJSON = PlacingDataPointToJSON;
