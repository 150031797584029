import React, {ReactNode} from "react";
import {ChatMessageResponseElement} from "client";
import {navHeight} from "../AppContent";

interface IProps {
	messages: Array<ChatMessageResponseElement>;
}

const MessageList: React.FC<IProps> = (props: IProps) => {

	function mapMessages(_messages: Array<ChatMessageResponseElement> = []): ReactNode {
		return _messages.map((_message: ChatMessageResponseElement) => {
			return (
				<div className="mx-2 my-2">
					{!_message.isOwnMessage ? (
						<div className="chat-bubble-width">
							<p className="mb-0 ml-1 text-muted">
								{_message.chatMessage.name}
							</p>
						</div>
					) : (
						<div className="d-flex justify-content-end">
							<p className="mb-0 mr-1 text-muted">You</p>
						</div>
					)}

					<div className={"d-flex " + (_message.isOwnMessage ? "justify-content-end" : "justify-content-start")}>
						<div className={"chat-bubble chat-bubble-width " + (_message.isOwnMessage ? "chat-bubble-own" : "chat-bubble-other")} lang="en">
							{_message.chatMessage.message}
						</div>
					</div>
				</div>
			);
		});
	}

	return (
		<React.Fragment>
			{mapMessages(props.messages)}
			{/*<div style={{height: navHeight}}/>*/}
		</React.Fragment>
	);
};

export default MessageList;
