export interface IMetaStore {
	token?: string;
	loadingIncrement?: number;
	errors?: Array<any>;
}

export default {
	loadingIncrement: 0,
	errors: [],
} as IMetaStore;
