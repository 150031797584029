import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {Asset, EnterScoreRequest, GetScoresResponse, TournamentApi} from "client";
import getConfig from "../utils/getConfig";
import ScoreCardHeader from "../components/ScoreCardHeader";
import ScoresDisplay from "../components/ScoresDisplay";
import TimedAd from "../components/TimedAd";
import ScoreCardFooter from "../components/ScoreCardFooter";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {toParChecker} from "../utils/toParChecker";
import {useHistory} from "react-router";
import ScoringInstructionModal from "../components/ScoringInstructionModal";

interface IProps {
	token?: string;
	dispatch?: any;
	timedAds?: { [key: number]: Asset };
	scoreCardAdURL?: string;
}

let pollingScoresInterval: any  = null;

const ScoreCardPage: React.FC<IProps> = (props: IProps) => {

	const history = useHistory();
	const [currentHole, setCurrentHole] = useState<number>(undefined); // track what hole the user is viewing, this is 1:1 with hole numbers; not array indexes
	const [scores, setScores] = useState<GetScoresResponse>();
	const [showAd, setShowAd] = useState(null);
	const [showMessageAlert, setShowMessageAlert] = useState<{ message: string, adToShow?: number }>(undefined);
	const [showFinishedScoring, setShowFinishedScoring] = useState<{ message: string, adToShow?: number }>(undefined);
	const [showBottomSubmitButton, setShowBottomSubmitButton] = useState(false);
	const [enableScoreEntry, setEnableScoreEntry] = useState(true);
	const [showScoreSubmissionSuccess, setShowScoreSubmissionSuccess] = useState(false);
	const [showClosedScoringMessage, setShowClosedScoringMessage] = useState(false);
	const [showInstructionVideo, setShowInstructionVideo] = useState(false);

	useEffect(() => {
		prepareInstructionVideo().then().catch();
	}, []);

	useEffect(() => {
		pollingScoresInterval = setInterval(() => {
			getScoresData(false, true).then().catch();
		}, 10000);

		return () => clearInterval(pollingScoresInterval);
	}, [currentHole, JSON.stringify(scores)]);

	async function prepareInstructionVideo(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new TournamentApi(getConfig(props.token)).hasPlayerSkippedIntroductionVideo();

			if (!res.skipIntroductionVideo) {
				setShowInstructionVideo(true);
			}

			await getScoresData(true, false);
		} catch (e) {
		}

		props.dispatch(decrementLoading());
	}


	async function getScoresData(showLoading: boolean, polling: boolean): Promise<void> {
		if (showLoading) {
			props.dispatch(incrementLoading());
		}

		try {
			let amountOfEntriesWithScores: number = 0;
			let currentHoleHasScore: boolean = true;
			if (scores && scores.entries) {
				for (const entry of scores.entries) {
					if (entry.dynamicValue[currentHole] === undefined) {
						currentHoleHasScore = false;
					} else {
						amountOfEntriesWithScores++;
					}
				}
			}

			const res = await new TournamentApi(getConfig(props.token)).getScores({
				isPolling: polling,
				currentHole: polling ? currentHole : undefined,
				currentHoleHasScore: polling ? currentHoleHasScore : undefined,
			});
			setScores(res);
			if (res.openHole !== undefined) {
				setCurrentHole(res.openHole);
			}

			if (res.isFinishedScoring && !res.closedScoring) {
				setShowBottomSubmitButton(true);
			}

			if (res.closedScoring) {
				setEnableScoreEntry(false);
				setShowClosedScoringMessage(true);
			}

		} catch (e) {
			// props.dispatch(addError(e));
			// clearInterval(pollingScoresInterval)
		}

		if (showLoading) {
			props.dispatch(decrementLoading());
		}
	}

	async function postScores(enterScoreRequest: EnterScoreRequest): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			const res = await new TournamentApi(getConfig(props.token)).enterScore(enterScoreRequest);

			setScores(res);

			const enableShowAd: boolean = res.showAd && props.timedAds[currentHole] !== undefined;

			if (res.isFinishedScoring) {
				setShowFinishedScoring({
					message: `You have completed the team's round round and entered all scores, your final score is ${toParChecker(res.scoreToPar).display}, click "Submit" to finish your round, or "Edit Scores" to update a hole.`,
					adToShow: enableShowAd ? currentHole : undefined,
				})
			} else if (res.message) {
				setShowMessageAlert({
					message: res.message,
					adToShow: enableShowAd ? currentHole : undefined,
				});
			} else {
				if (enableShowAd) {
					setShowAd(currentHole);
				}

				if (res.openHole !== undefined) {
					setCurrentHole(res.openHole);
				}
			}

		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	function setCurrentHoleHelper(newHole: number): void {
		setCurrentHole(newHole);
	}

	/**
	 * Close the timed ad
	 *
	 */
	function closeTimedAd(): void {
		setShowAd(null);

		// handle if finished scoring
		if (showFinishedScoring !== undefined) {
			setShowFinishedScoring(undefined);
			submitScores().then().catch();
		}
	}

	function onDismissMessageModal(): void {
		if (showMessageAlert.adToShow !== undefined) {
			setShowAd(showMessageAlert.adToShow);
		}

		setShowMessageAlert(undefined);

		if (scores.openHole !== undefined) {
			setCurrentHole(scores.openHole);
		}
	}

	function onDismissFinishedScoringModal(): void {
		if (showFinishedScoring.adToShow !== undefined) {
			setShowAd(showFinishedScoring.adToShow);
		}

		setShowFinishedScoring(undefined);
		setShowBottomSubmitButton(true);
	}

	function onSubmitFinishedScoring(): void {
		if (showFinishedScoring.adToShow !== undefined) {
			setShowAd(showFinishedScoring.adToShow);
		} else {
			submitScores().then().catch();
		}
	}

	async function submitScores(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new TournamentApi(getConfig(props.token)).closeScoring();
			setShowScoreSubmissionSuccess(true);
			setShowBottomSubmitButton(false);
		} catch (e) {
			props.dispatch(addError(e));
		}
		props.dispatch(decrementLoading());
	}

	function onDismissSuccessfulScoreSubmissionMessage(): void {
		setShowScoreSubmissionSuccess(false);
		history.push(`/leader-board/${props.token}`);
	}

	function dismissInstructionVideo(): void {
		setShowInstructionVideo(false);
	}

	return (
		<React.Fragment>
			<ScoringInstructionModal
				isOpen={showInstructionVideo}
				onClose={dismissInstructionVideo}
			/>

			<Modal
				isOpen={showMessageAlert !== undefined}
				centered={true}
				toggle={onDismissMessageModal}
			>
				<ModalHeader toggle={onDismissMessageModal}>Alert</ModalHeader>
				<ModalBody><p>{showMessageAlert?.message ? showMessageAlert.message : ""}</p></ModalBody>
				<ModalFooter>
					<Button color="darkBlue" onClick={onDismissMessageModal}>
						Dismiss
					</Button>
				</ModalFooter>
			</Modal>

			<Modal
				isOpen={showFinishedScoring !== undefined}
				centered={true}
				toggle={onDismissFinishedScoringModal}
			>
				<ModalHeader>Finished Scoring</ModalHeader>
				<ModalBody><p>{showFinishedScoring?.message ? showFinishedScoring.message : ""}</p></ModalBody>

				<div className="d-flex flex-column justify-content-center px-3 ">
					<Button color="lightBlue" className="mb-3" onClick={onSubmitFinishedScoring}>
						Submit
					</Button>

					<Button color="darkBlue" className="mb-3" onClick={onDismissFinishedScoringModal}>
						Edit Scores
					</Button>
				</div>
			</Modal>

			<Modal
				isOpen={showScoreSubmissionSuccess}
				centered={true}
			>
				<ModalHeader>Success</ModalHeader>
				<ModalBody>
					<p className="text-center">Your scores have been submitted successfully. Talk to your tournament organizer if you need to make changes.</p>

					<p className="text-center">You will now be taken to the leader board.</p>

					<p className="text-center">Thank you for playing golf today.</p>
				</ModalBody>

				<ModalFooter>
					<Button color="darkBlue" onClick={onDismissSuccessfulScoreSubmissionMessage}>
						Dismiss
					</Button>
				</ModalFooter>
			</Modal>

			{(scores !== undefined && currentHole !== undefined) && (
				<div className="indented-page">
					{((showAd !== null) && props.timedAds) && (
						<TimedAd
							isOpen={showAd}
							src={props.timedAds[showAd].url}
							time={5000}
							onClose={closeTimedAd}
						/>
					)}

					<ScoreCardHeader
						headerText={scores.headerText}
						currentHole={currentHole}
						holeLabels={scores.holeLabels}
						onCycle={setCurrentHoleHelper}
					/>

					<ScoresDisplay
						currentHole={currentHole}
						holeLabels={scores.holeLabels}
						entries={scores.entries}
						isNet={scores.isNet}
						enableScoreEntry={enableScoreEntry}
						onDone={postScores}
					/>

					{showBottomSubmitButton && (
						<div className="my-2">
							<Button
								color="lightBlue"
								onClick={submitScores}
								className="w-100"
							>
								Submit Scores
							</Button>
						</div>
					)}

					{showClosedScoringMessage && (
						<div className="my-2">
							<p className="text-center">
								You have already submitted your team's scores. Updating any of your scores at this point will require assistance of your tournament organizer.
							</p>
						</div>
					)}

					<ScoreCardFooter
						scoreToPar={scores.scoreToPar}
						isNet={scores.isNet}
						counters={scores.counterArray}
					/>

					{props.scoreCardAdURL && (
						<div className="w-100 d-flex justify-content-center">
							<div className="banner-container score-card-pr-container">
								<img
									className="w-100"
									style={{objectFit: "contain"}}
									src={props.scoreCardAdURL}
								/>
							</div>
						</div>
					)}
				</div>
			)}
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		timedAds: store.tournamentInformationStore.timedAds,
		scoreCardAdURL: store.tournamentInformationStore.scoreCardAdURL,
		...props,
	}
})(ScoreCardPage);
