"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerScoreCardAllOfToJSON = exports.PlayerScoreCardAllOfFromJSONTyped = exports.PlayerScoreCardAllOfFromJSON = void 0;
const _1 = require("./");
function PlayerScoreCardAllOfFromJSON(json) {
    return PlayerScoreCardAllOfFromJSONTyped(json, false);
}
exports.PlayerScoreCardAllOfFromJSON = PlayerScoreCardAllOfFromJSON;
function PlayerScoreCardAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'player': _1.PlayerFromJSON(json['player']),
    };
}
exports.PlayerScoreCardAllOfFromJSONTyped = PlayerScoreCardAllOfFromJSONTyped;
function PlayerScoreCardAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'player': _1.PlayerToJSON(value.player),
    };
}
exports.PlayerScoreCardAllOfToJSON = PlayerScoreCardAllOfToJSON;
