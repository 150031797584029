"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class ChatApi extends runtime.BaseAPI {
    /**
     */
    async adminCheckForNewMessagesRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling adminCheckForNewMessages.');
        }
        if (requestParameters.lastReadMessageDate === null || requestParameters.lastReadMessageDate === undefined) {
            throw new runtime.RequiredError('lastReadMessageDate', 'Required parameter requestParameters.lastReadMessageDate was null or undefined when calling adminCheckForNewMessages.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        if (requestParameters.lastReadMessageDate !== undefined) {
            queryParameters['lastReadMessageDate'] = requestParameters.lastReadMessageDate;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/chat/admin_check_for_new_messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.TextApiResponse(response);
    }
    /**
     */
    async adminCheckForNewMessages(requestParameters) {
        const response = await this.adminCheckForNewMessagesRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async adminDeleteMessageRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/chat/admin_delete_message`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async adminDeleteMessage(requestParameters) {
        await this.adminDeleteMessageRaw(requestParameters);
    }
    /**
     */
    async adminGetMessagesRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling adminGetMessages.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/chat/admin_get_messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ChatMessageResponseElementFromJSON));
    }
    /**
     */
    async adminGetMessages(requestParameters) {
        const response = await this.adminGetMessagesRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used by Admins to create chat messages
     */
    async adminPostMessageRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/chat/admin_post_message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.AdminPostMessageBodyToJSON(requestParameters.adminPostMessageBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by Admins to create chat messages
     */
    async adminPostMessage(requestParameters) {
        await this.adminPostMessageRaw(requestParameters);
    }
    /**
     */
    async checkForNewMessagesRaw(requestParameters) {
        if (requestParameters.lastReadMessageDate === null || requestParameters.lastReadMessageDate === undefined) {
            throw new runtime.RequiredError('lastReadMessageDate', 'Required parameter requestParameters.lastReadMessageDate was null or undefined when calling checkForNewMessages.');
        }
        const queryParameters = {};
        if (requestParameters.lastReadMessageDate !== undefined) {
            queryParameters['lastReadMessageDate'] = requestParameters.lastReadMessageDate;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Player", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/chat/check_for_new_messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.TextApiResponse(response);
    }
    /**
     */
    async checkForNewMessages(requestParameters) {
        const response = await this.checkForNewMessagesRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getMessagesRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Player", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/chat/get_messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ChatMessageResponseElementFromJSON));
    }
    /**
     */
    async getMessages() {
        const response = await this.getMessagesRaw();
        return await response.value();
    }
    /**
     * Used by players to create chat messages
     */
    async postMessageRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Player", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/chat/post_message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.PostMessageBodyToJSON(requestParameters.postMessageBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by players to create chat messages
     */
    async postMessage(requestParameters) {
        await this.postMessageRaw(requestParameters);
    }
    /**
     */
    async userCheckForNewMessagesRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling userCheckForNewMessages.');
        }
        if (requestParameters.lastReadMessageDate === null || requestParameters.lastReadMessageDate === undefined) {
            throw new runtime.RequiredError('lastReadMessageDate', 'Required parameter requestParameters.lastReadMessageDate was null or undefined when calling userCheckForNewMessages.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        if (requestParameters.lastReadMessageDate !== undefined) {
            queryParameters['lastReadMessageDate'] = requestParameters.lastReadMessageDate;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/chat/user_check_for_new_messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.TextApiResponse(response);
    }
    /**
     */
    async userCheckForNewMessages(requestParameters) {
        const response = await this.userCheckForNewMessagesRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async userDeleteMessageRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/chat/user_delete_message`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async userDeleteMessage(requestParameters) {
        await this.userDeleteMessageRaw(requestParameters);
    }
    /**
     */
    async userGetMessagesRaw(requestParameters) {
        if (requestParameters.tournamentID === null || requestParameters.tournamentID === undefined) {
            throw new runtime.RequiredError('tournamentID', 'Required parameter requestParameters.tournamentID was null or undefined when calling userGetMessages.');
        }
        const queryParameters = {};
        if (requestParameters.tournamentID !== undefined) {
            queryParameters['tournamentID'] = requestParameters.tournamentID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/chat/user_get_messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ChatMessageResponseElementFromJSON));
    }
    /**
     */
    async userGetMessages(requestParameters) {
        const response = await this.userGetMessagesRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used by TOs to create chat messages
     */
    async userPostMessageRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/chat/user_post_message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.UserPostMessageBodyToJSON(requestParameters.userPostMessageBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by TOs to create chat messages
     */
    async userPostMessage(requestParameters) {
        await this.userPostMessageRaw(requestParameters);
    }
}
exports.ChatApi = ChatApi;
