"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionOwnerTypeToJSON = exports.TransactionOwnerTypeFromJSONTyped = exports.TransactionOwnerTypeFromJSON = exports.TransactionOwnerType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var TransactionOwnerType;
(function (TransactionOwnerType) {
    TransactionOwnerType["Admin"] = "Admin";
    TransactionOwnerType["User"] = "User";
    TransactionOwnerType["Player"] = "Player";
})(TransactionOwnerType = exports.TransactionOwnerType || (exports.TransactionOwnerType = {}));
function TransactionOwnerTypeFromJSON(json) {
    return TransactionOwnerTypeFromJSONTyped(json, false);
}
exports.TransactionOwnerTypeFromJSON = TransactionOwnerTypeFromJSON;
function TransactionOwnerTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TransactionOwnerTypeFromJSONTyped = TransactionOwnerTypeFromJSONTyped;
function TransactionOwnerTypeToJSON(value) {
    return value;
}
exports.TransactionOwnerTypeToJSON = TransactionOwnerTypeToJSON;
