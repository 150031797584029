"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeamScoreCardToJSON = exports.TeamScoreCardFromJSONTyped = exports.TeamScoreCardFromJSON = void 0;
const _1 = require("./");
function TeamScoreCardFromJSON(json) {
    return TeamScoreCardFromJSONTyped(json, false);
}
exports.TeamScoreCardFromJSON = TeamScoreCardFromJSON;
function TeamScoreCardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.ScoreCardFromJSONTyped(json, ignoreDiscriminator),
        'teamWithPlayers': _1.TeamWithPlayersFromJSON(json['teamWithPlayers']),
    };
}
exports.TeamScoreCardFromJSONTyped = TeamScoreCardFromJSONTyped;
function TeamScoreCardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.ScoreCardToJSON(value),
        'teamWithPlayers': _1.TeamWithPlayersToJSON(value.teamWithPlayers),
    };
}
exports.TeamScoreCardToJSON = TeamScoreCardToJSON;
