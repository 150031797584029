import React, {useEffect} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import getConfig from "../utils/getConfig";
import {ChatApi} from "client";
import {updateUnreadMessageQuantity} from "../redux/tournamentInformation/TournamentInformationActions";

interface IProps {
	token?: string;
	dispatch?: any;
	lastReadMessageDate?: number;
}

const ChatChecker: React.FC<IProps> = (props: IProps) => {

	useEffect(() => {
		const interval = setInterval(() => {pollChatUpdates().then().catch()}, 15000);
		return () => clearInterval(interval);
	}, [props.lastReadMessageDate]);

	async function pollChatUpdates(): Promise<void> {
		try {
			const res = await new ChatApi(getConfig(props.token)).checkForNewMessages({
				lastReadMessageDate: props.lastReadMessageDate as unknown as string,
			});
			props.dispatch(updateUnreadMessageQuantity(res));
		} catch (e) {
		}
	}

	return null;
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		lastReadMessageDate: store.tournamentInformationStore.lastReadMessageDate,
	}
})(ChatChecker);
