"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaderboardHeaderToJSON = exports.LeaderboardHeaderFromJSONTyped = exports.LeaderboardHeaderFromJSON = void 0;
const runtime_1 = require("../runtime");
function LeaderboardHeaderFromJSON(json) {
    return LeaderboardHeaderFromJSONTyped(json, false);
}
exports.LeaderboardHeaderFromJSON = LeaderboardHeaderFromJSON;
function LeaderboardHeaderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'leftTitle': !runtime_1.exists(json, 'leftTitle') ? undefined : json['leftTitle'],
        'rightTitleA': !runtime_1.exists(json, 'rightTitleA') ? undefined : json['rightTitleA'],
        'rightTitleB': !runtime_1.exists(json, 'rightTitleB') ? undefined : json['rightTitleB'],
        'toggleButton': !runtime_1.exists(json, 'toggleButton') ? undefined : json['toggleButton'],
    };
}
exports.LeaderboardHeaderFromJSONTyped = LeaderboardHeaderFromJSONTyped;
function LeaderboardHeaderToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'leftTitle': value.leftTitle,
        'rightTitleA': value.rightTitleA,
        'rightTitleB': value.rightTitleB,
        'toggleButton': value.toggleButton,
    };
}
exports.LeaderboardHeaderToJSON = LeaderboardHeaderToJSON;
