"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TournamentDetailsToJSON = exports.TournamentDetailsFromJSONTyped = exports.TournamentDetailsFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TournamentDetailsFromJSON(json) {
    return TournamentDetailsFromJSONTyped(json, false);
}
exports.TournamentDetailsFromJSON = TournamentDetailsFromJSON;
function TournamentDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tournament': _1.TournamentFromJSON(json['tournament']),
        'players': (json['players'].map(_1.PlayerFromJSON)),
        'teams': (json['teams'].map(_1.TeamFromJSON)),
        'transactions': (json['transactions'].map(_1.TransactionFromJSON)),
        'playerGroupings': _1.PlayerGroupingsFromJSON(json['playerGroupings']),
        'playerScoreCards': !runtime_1.exists(json, 'playerScoreCards') ? undefined : (runtime_1.mapValues(json['playerScoreCards'], _1.PlayerScoreCardFromJSON)),
        'teamScoreCards': !runtime_1.exists(json, 'teamScoreCards') ? undefined : (runtime_1.mapValues(json['teamScoreCards'], _1.TeamScoreCardFromJSON)),
        'wasTransactionAnEdit': !runtime_1.exists(json, 'wasTransactionAnEdit') ? undefined : json['wasTransactionAnEdit'],
    };
}
exports.TournamentDetailsFromJSONTyped = TournamentDetailsFromJSONTyped;
function TournamentDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tournament': _1.TournamentToJSON(value.tournament),
        'players': (value.players.map(_1.PlayerToJSON)),
        'teams': (value.teams.map(_1.TeamToJSON)),
        'transactions': (value.transactions.map(_1.TransactionToJSON)),
        'playerGroupings': _1.PlayerGroupingsToJSON(value.playerGroupings),
        'playerScoreCards': value.playerScoreCards === undefined ? undefined : (runtime_1.mapValues(value.playerScoreCards, _1.PlayerScoreCardToJSON)),
        'teamScoreCards': value.teamScoreCards === undefined ? undefined : (runtime_1.mapValues(value.teamScoreCards, _1.TeamScoreCardToJSON)),
        'wasTransactionAnEdit': value.wasTransactionAnEdit,
    };
}
exports.TournamentDetailsToJSON = TournamentDetailsToJSON;
