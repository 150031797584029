"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdatePlayersBodyToJSON = exports.UpdatePlayersBodyFromJSONTyped = exports.UpdatePlayersBodyFromJSON = void 0;
const _1 = require("./");
function UpdatePlayersBodyFromJSON(json) {
    return UpdatePlayersBodyFromJSONTyped(json, false);
}
exports.UpdatePlayersBodyFromJSON = UpdatePlayersBodyFromJSON;
function UpdatePlayersBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'playerGroupings': _1.PlayerGroupingsFromJSON(json['playerGroupings']),
    };
}
exports.UpdatePlayersBodyFromJSONTyped = UpdatePlayersBodyFromJSONTyped;
function UpdatePlayersBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'playerGroupings': _1.PlayerGroupingsToJSON(value.playerGroupings),
    };
}
exports.UpdatePlayersBodyToJSON = UpdatePlayersBodyToJSON;
