"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayersArrayToJSON = exports.PlayersArrayFromJSONTyped = exports.PlayersArrayFromJSON = void 0;
const _1 = require("./");
function PlayersArrayFromJSON(json) {
    return PlayersArrayFromJSONTyped(json, false);
}
exports.PlayersArrayFromJSON = PlayersArrayFromJSON;
function PlayersArrayFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'players': (json['players'].map(_1.PlayerFromJSON)),
    };
}
exports.PlayersArrayFromJSONTyped = PlayersArrayFromJSONTyped;
function PlayersArrayToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'players': (value.players.map(_1.PlayerToJSON)),
    };
}
exports.PlayersArrayToJSON = PlayersArrayToJSON;
