import InitialTournamentInformationStore, {ITournamentInformationStore} from "./InitialTournamentInformationStore";
import {IAction} from "../IAction";
import {cloneDeep} from "lodash";

export enum TournamentInformationAction {
	UPDATE_CHAT_AND_SPONSOR = "UPDATE_CHAT_AND_SPONSOR",
	UPDATE_LAST_READ_MESSAGE_DATE = "UPDATE_LAST_READ_MESSAGE_DATE",
	UPDATE_AMOUNT_OF_UNREAD_MESSAGES = "UPDATE_AMOUNT_OF_UNREAD_MESSAGE",
	UPDATE_ADS = "UPDATE_ADS",
	CLEAR_ADS = "CLEAR_ADS",
}

export default function(store: ITournamentInformationStore = InitialTournamentInformationStore, a: IAction<TournamentInformationAction, any>): ITournamentInformationStore {

	const n: ITournamentInformationStore = cloneDeep(store);

	switch(a.type) {
		case TournamentInformationAction.UPDATE_CHAT_AND_SPONSOR:
			n.hasChat = a.payload.hasChat;
			n.sponsorLabel = a.payload.sponsorLabel;
			n.sponsorURL = a.payload.url;
			n.useIFrame = a.payload.useIFrame;
			break;
		case TournamentInformationAction.UPDATE_LAST_READ_MESSAGE_DATE:
			n.lastReadMessageDate = a.payload;
			break;
		case TournamentInformationAction.UPDATE_AMOUNT_OF_UNREAD_MESSAGES:
			n.unreadMessages = parseInt(a.payload);
			break;
		case TournamentInformationAction.UPDATE_ADS:
			n.bannerAdURL = a.payload.banner?.url;
			n.animatedLeaderboardBannerURL = a.payload.animatedLeaderboardBanner?.url;
			n.scoreCardAdURL = a.payload.scoreCardAd?.url;
			n.timedAds = a.payload.timedAds;
			break;
		case TournamentInformationAction.CLEAR_ADS:
			n.bannerAdURL = "";
			n.scoreCardAdURL = "";
			n.timedAds = {};
			break;
		default:
			break;
	}

	return n;
}
