"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameToJSON = exports.GameFromJSONTyped = exports.GameFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function GameFromJSON(json) {
    return GameFromJSONTyped(json, false);
}
exports.GameFromJSON = GameFromJSON;
function GameFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tournamentType': _1.TournamentTypeFromJSON(json['tournamentType']),
        'stableford': json['stableford'],
        'useHandicap': json['useHandicap'],
        'applyHandicapPercentage': !runtime_1.exists(json, 'applyHandicapPercentage') ? undefined : json['applyHandicapPercentage'],
        'teamHandicapPercentages': !runtime_1.exists(json, 'teamHandicapPercentages') ? undefined : json['teamHandicapPercentages'],
        'strokePlayPlayersCounted': !runtime_1.exists(json, 'strokePlayPlayersCounted') ? undefined : json['strokePlayPlayersCounted'],
        'redVsBluesMode': !runtime_1.exists(json, 'redVsBluesMode') ? undefined : json['redVsBluesMode'],
        'primaryTieBreakMethod': !runtime_1.exists(json, 'primaryTieBreakMethod') ? undefined : _1.PrimaryTieBreakMethodFromJSON(json['primaryTieBreakMethod']),
        'type': !runtime_1.exists(json, 'type') ? undefined : _1.TournamentGameTypeFromJSON(json['type']),
        'defaultLeaderboard': !runtime_1.exists(json, 'defaultLeaderboard') ? undefined : _1.LeaderboardTypeFromJSON(json['defaultLeaderboard']),
    };
}
exports.GameFromJSONTyped = GameFromJSONTyped;
function GameToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tournamentType': _1.TournamentTypeToJSON(value.tournamentType),
        'stableford': value.stableford,
        'useHandicap': value.useHandicap,
        'applyHandicapPercentage': value.applyHandicapPercentage,
        'teamHandicapPercentages': value.teamHandicapPercentages,
        'strokePlayPlayersCounted': value.strokePlayPlayersCounted,
        'redVsBluesMode': value.redVsBluesMode,
        'primaryTieBreakMethod': _1.PrimaryTieBreakMethodToJSON(value.primaryTieBreakMethod),
        'type': _1.TournamentGameTypeToJSON(value.type),
        'defaultLeaderboard': _1.LeaderboardTypeToJSON(value.defaultLeaderboard),
    };
}
exports.GameToJSON = GameToJSON;
