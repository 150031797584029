import {TournamentInformationAction} from "./TournamentInformationReducer";
import {IAction} from "../IAction";
import {ChatAndURL, GetPairedTournamentAssetsResponse} from "client";

export function updateChatAndSponsor(res: ChatAndURL): IAction<TournamentInformationAction.UPDATE_CHAT_AND_SPONSOR, ChatAndURL> {
	return {
		type: TournamentInformationAction.UPDATE_CHAT_AND_SPONSOR,
		payload: res,
	}
}

export function updateLastReadMessageDate(date: number): IAction<TournamentInformationAction.UPDATE_LAST_READ_MESSAGE_DATE, number> {
	return {
		type: TournamentInformationAction.UPDATE_LAST_READ_MESSAGE_DATE,
		payload: date,
	}
}

export function updateUnreadMessageQuantity(quantity: string): IAction<TournamentInformationAction.UPDATE_AMOUNT_OF_UNREAD_MESSAGES, string> {
	return {
		type: TournamentInformationAction.UPDATE_AMOUNT_OF_UNREAD_MESSAGES,
		payload: quantity,
	}
}

export function updateTournamentAds(assets: GetPairedTournamentAssetsResponse): IAction<TournamentInformationAction.UPDATE_ADS, GetPairedTournamentAssetsResponse> {
	return {
		type: TournamentInformationAction.UPDATE_ADS,
		payload: assets,
	}
}

export function clearTournamentAds(): IAction<TournamentInformationAction.CLEAR_ADS, undefined> {
	return {
		type: TournamentInformationAction.CLEAR_ADS,
	}
}
