import {HoleInfo, TournamentType} from "client";

/**
 * Dynamically generate the par for a hole based on a bunch of variables.
 *
 * @param holeInfo
 * @param tournamentType
 * @param isMixedGender
 * @param male
 */
export function getParFieldForScoreCard(holeInfo: HoleInfo, tournamentType: TournamentType, isMixedGender: boolean, male?: boolean): number {
	switch(tournamentType) {
		case TournamentType.SCRAMBLE:
			return holeInfo.par;
		case TournamentType.BESTBALLSTROKEPLAY:
			if (isMixedGender) {
				if (male) {
					return holeInfo.malePar ?? holeInfo.par;
				} else {
					return holeInfo.femalePar ?? holeInfo.par;
				}
			} else {
				return holeInfo.par;
			}
		default:
			return holeInfo.par ?? holeInfo.malePar;
	}
}
