"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatMessageToJSON = exports.ChatMessageFromJSONTyped = exports.ChatMessageFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ChatMessageFromJSON(json) {
    return ChatMessageFromJSONTyped(json, false);
}
exports.ChatMessageFromJSON = ChatMessageFromJSON;
function ChatMessageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'posterType': !runtime_1.exists(json, 'posterType') ? undefined : _1.PosterTypeFromJSON(json['posterType']),
        'tournament': _1.TournamentFromJSON(json['tournament']),
        'poster': json['poster'],
        'name': json['name'],
        'message': json['message'],
        'creationDate': json['creationDate'],
    };
}
exports.ChatMessageFromJSONTyped = ChatMessageFromJSONTyped;
function ChatMessageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'posterType': _1.PosterTypeToJSON(value.posterType),
        'tournament': _1.TournamentToJSON(value.tournament),
        'poster': value.poster,
        'name': value.name,
        'message': value.message,
        'creationDate': value.creationDate,
    };
}
exports.ChatMessageToJSON = ChatMessageToJSON;
