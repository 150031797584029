import React from "react";
import {FiCopy, FiMenu} from "react-icons/all";
import {Nav, Navbar} from "reactstrap";

interface IProps {
	leftTitle: string[];
	rightTitleA: string;
	rightTitleB: string;
	enableToggle: boolean;
	onToggle(): void;
}

const LeaderBoardHeader: React.FC<IProps> = (props: IProps) => {

	const {leftTitle, rightTitleA, rightTitleB, enableToggle} = props;

	/**
	 * handle the preventDefault on the <a> link for toggling,
	 * then call function from props
	 *
	 * @param e
	 */
	function toggleHelper(e): void {
		if (e) {
			e.preventDefault();
		}

		props.onToggle();
	}

	return (
		<div
			className="w-100 d-flex text-light score-card-header rounded-score-card-header user-select-none"
			style={{fontSize: "0.8rem", marginBottom: "0.5rem"}}
		>
			<div className="w-50 d-flex flex-column" style={{padding: "0.75rem"}}>
				{leftTitle.map((t: string, i: number) => {
					return (
						<p key={`left-title_${i}`} className="w-100 mb-1 text-center font-weight-bold">
							{t}
						</p>
					);
				})}
			</div>

			<div className="score-card-header-divider"/>

			<div className="w-50 d-flex flex-row align-items-center" style={{padding: "0.75rem"}}>
				<div className="w-100 pr-3">
					<p className="w-100 mb-0 text-center font-weight-bold">
						{rightTitleA}
					</p>
					<p className="w-100 mb-0 text-center">
						{rightTitleB}
					</p>
				</div>

				{enableToggle && (
					<div className="d-block">
						<div className="score-hard-header-toggle-leader-board-button-container">
							<a
								href="#"
								onClick={toggleHelper}
								className="p-0 nav-link d-flex flex-column align-items-center justify-content-center text-center"
								// style={{fontSize: "0.6rem", width: 40, height: 40}}
								style={{fontSize: "0.6rem", width: 30, height: 30}}
							>
								<FiMenu
									size="1.2rem"
									style={{marginBottom: 3}}
									className="text-yellow"
								/>

								{/*<span*/}
								{/*	style={{marginTop: -3, fontSize: "0.7rem", marginBottom: -5, lineHeight: 1.1}}*/}
								{/*	className="text-dark"*/}
								{/*>*/}
								{/*	Toggle*/}
								{/*</span>*/}
							</a>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default LeaderBoardHeader;
