"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GolfCourseApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class GolfCourseApi extends runtime.BaseAPI {
    /**
     */
    async addNewRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/golf_course/add_new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.AddNewGolfCourseBodyToJSON(requestParameters.addNewGolfCourseBody),
        });
        return new runtime.TextApiResponse(response);
    }
    /**
     */
    async addNew(requestParameters) {
        const response = await this.addNewRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async adminSearchGolfCourseRaw(requestParameters) {
        if (requestParameters.searchString === null || requestParameters.searchString === undefined) {
            throw new runtime.RequiredError('searchString', 'Required parameter requestParameters.searchString was null or undefined when calling adminSearchGolfCourse.');
        }
        if (requestParameters.useHandicap === null || requestParameters.useHandicap === undefined) {
            throw new runtime.RequiredError('useHandicap', 'Required parameter requestParameters.useHandicap was null or undefined when calling adminSearchGolfCourse.');
        }
        if (requestParameters.isMixedGender === null || requestParameters.isMixedGender === undefined) {
            throw new runtime.RequiredError('isMixedGender', 'Required parameter requestParameters.isMixedGender was null or undefined when calling adminSearchGolfCourse.');
        }
        if (requestParameters.isSearchable === null || requestParameters.isSearchable === undefined) {
            throw new runtime.RequiredError('isSearchable', 'Required parameter requestParameters.isSearchable was null or undefined when calling adminSearchGolfCourse.');
        }
        if (requestParameters.isDemo === null || requestParameters.isDemo === undefined) {
            throw new runtime.RequiredError('isDemo', 'Required parameter requestParameters.isDemo was null or undefined when calling adminSearchGolfCourse.');
        }
        const queryParameters = {};
        if (requestParameters.searchString !== undefined) {
            queryParameters['searchString'] = requestParameters.searchString;
        }
        if (requestParameters.useHandicap !== undefined) {
            queryParameters['useHandicap'] = requestParameters.useHandicap;
        }
        if (requestParameters.isMixedGender !== undefined) {
            queryParameters['isMixedGender'] = requestParameters.isMixedGender;
        }
        if (requestParameters.isSearchable !== undefined) {
            queryParameters['isSearchable'] = requestParameters.isSearchable;
        }
        if (requestParameters.isDemo !== undefined) {
            queryParameters['isDemo'] = requestParameters.isDemo;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/golf_course/admin_search_golf_course`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.GolfCourseFromJSON));
    }
    /**
     */
    async adminSearchGolfCourse(requestParameters) {
        const response = await this.adminSearchGolfCourseRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async deleteGolfCourseRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/golf_course/delete_golf_course`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteGolfCourse(requestParameters) {
        await this.deleteGolfCourseRaw(requestParameters);
    }
    /**
     */
    async getAllGolfCoursesRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/golf_course/get_all_golf_courses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.GolfCourseFromJSON));
    }
    /**
     */
    async getAllGolfCourses() {
        const response = await this.getAllGolfCoursesRaw();
        return await response.value();
    }
    /**
     */
    async getGolfCourseRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getGolfCourse.');
        }
        const queryParameters = {};
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/golf_course/get_golf_course`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GolfCourseFromJSON(jsonValue));
    }
    /**
     */
    async getGolfCourse(requestParameters) {
        const response = await this.getGolfCourseRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getGolfCourseAsTemplateRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getGolfCourseAsTemplate.');
        }
        if (requestParameters.golfCourseType === null || requestParameters.golfCourseType === undefined) {
            throw new runtime.RequiredError('golfCourseType', 'Required parameter requestParameters.golfCourseType was null or undefined when calling getGolfCourseAsTemplate.');
        }
        const queryParameters = {};
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.golfCourseType !== undefined) {
            queryParameters['golfCourseType'] = requestParameters.golfCourseType;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/golf_course/get_golf_course_as_template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GolfCourseFromJSON(jsonValue));
    }
    /**
     */
    async getGolfCourseAsTemplate(requestParameters) {
        const response = await this.getGolfCourseAsTemplateRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getGolfCourseForTournamentRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getGolfCourseForTournament.');
        }
        const queryParameters = {};
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/golf_course/get_golf_course_for_tournament`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GolfCourseFromJSON(jsonValue));
    }
    /**
     */
    async getGolfCourseForTournament(requestParameters) {
        const response = await this.getGolfCourseForTournamentRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async searchGolfCourseRaw(requestParameters) {
        if (requestParameters.searchString === null || requestParameters.searchString === undefined) {
            throw new runtime.RequiredError('searchString', 'Required parameter requestParameters.searchString was null or undefined when calling searchGolfCourse.');
        }
        if (requestParameters.useHandicap === null || requestParameters.useHandicap === undefined) {
            throw new runtime.RequiredError('useHandicap', 'Required parameter requestParameters.useHandicap was null or undefined when calling searchGolfCourse.');
        }
        if (requestParameters.isMixedGender === null || requestParameters.isMixedGender === undefined) {
            throw new runtime.RequiredError('isMixedGender', 'Required parameter requestParameters.isMixedGender was null or undefined when calling searchGolfCourse.');
        }
        if (requestParameters.isScramble === null || requestParameters.isScramble === undefined) {
            throw new runtime.RequiredError('isScramble', 'Required parameter requestParameters.isScramble was null or undefined when calling searchGolfCourse.');
        }
        const queryParameters = {};
        if (requestParameters.searchString !== undefined) {
            queryParameters['searchString'] = requestParameters.searchString;
        }
        if (requestParameters.useHandicap !== undefined) {
            queryParameters['useHandicap'] = requestParameters.useHandicap;
        }
        if (requestParameters.isMixedGender !== undefined) {
            queryParameters['isMixedGender'] = requestParameters.isMixedGender;
        }
        if (requestParameters.isScramble !== undefined) {
            queryParameters['isScramble'] = requestParameters.isScramble;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/golf_course/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.GolfCourseFromJSON));
    }
    /**
     */
    async searchGolfCourse(requestParameters) {
        const response = await this.searchGolfCourseRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async toggleSeachableGolfCourseRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/golf_course/toggle_searchable_golf_course`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async toggleSeachableGolfCourse(requestParameters) {
        await this.toggleSeachableGolfCourseRaw(requestParameters);
    }
    /**
     */
    async updateGolfCourseRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/golf_course/update_golf_course`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.UpdateGolfCourseBodyToJSON(requestParameters.updateGolfCourseBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async updateGolfCourse(requestParameters) {
        await this.updateGolfCourseRaw(requestParameters);
    }
}
exports.GolfCourseApi = GolfCourseApi;
