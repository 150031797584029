import React, {ReactElement} from "react";
import {Tournament} from "client";

interface IProps {
	tournament: Tournament;
	title: string;
	name: string;
	subtitle: string;
	animatedLeaderboardBannerURL?: string;
}

function AnimatedPageHeader(props: IProps): ReactElement {

	return (
		<div className="animated-page-header">
			{props.animatedLeaderboardBannerURL && (
				<div className="animated-page-header_brand">
					<img
						src={props.animatedLeaderboardBannerURL}
						alt={`${props.tournament?.name} banner`}
					/>
				</div>
			)}

			<div className="animated-page-header_details">
				<div className="animated-page-header_details_a">
					<p className="animated-page-header_details_c-name">
						{props.title}
					</p>

					<p className="animated-page-header_details_t-name">
						{props.name}
					</p>
				</div>

				<div className="animated-page-header_details_a">
					<p className="animated-page-header_details_par">
						{props.subtitle}
					</p>
				</div>
			</div>
		</div>
	);
}

export default AnimatedPageHeader;