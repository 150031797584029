import React from "react";
import {BrowserRouter} from "react-router-dom";
import ErrorManager from "./components/ErrorManager";
import LoadingManager from "./components/LoadingManager";
import AppContent from "./AppContent";

const App: React.FC = () => {

	return (
		<BrowserRouter>

			<ErrorManager/>
			<LoadingManager/>

			<AppContent/>
		</BrowserRouter>
	);
};

export default App;
