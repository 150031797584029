import React, {ReactElement} from "react";

interface IProps {
	videoSrc: string;
	videpType: string;
}

function AnimatedPageVideoBackground(props: IProps): ReactElement {

	return (
		<div className="animated-page-video-background">
			<video
				controls={false}
				autoPlay={true}
				muted={true}
				loop={true}
			>
				<source
					src={props.videoSrc}
					type={props.videpType}
				/>
			</video>
		</div>
	);
}

export default AnimatedPageVideoBackground;