import React, {useState} from "react";
import {Button, Modal} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import useWindowDimensions from "../utils/useWindowDimensions";
import {decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {TournamentApi} from "client";
import getConfig from "../utils/getConfig";

interface IProps {
	dispatch?: any;
	token?: string;
	isOpen: boolean;
	onClose(): void;
}

const ScoringInstructionModal: React.FC<IProps> = (props) => {

	const [checked, setChecked] = useState<boolean>(false);
	const [skipOpeningVideo, setSkipOpeningVideo] = useState<boolean>();

	new TournamentApi(getConfig(props.token))
		.isStarted()
		.then(res => setSkipOpeningVideo(res.skipOpeningVideo))
		.catch(console.error);

	let {width} = useWindowDimensions();
	width = width * 0.75;
	if (width > 315) {
		width = 315;
	}

	const height: number = width * (16 / 9);

	function toggleChecked(): void {
		setChecked(!checked);
	}

	async function onDismiss(): Promise<void> {
		if (checked) {
			props.dispatch(incrementLoading());

			try {
				await new TournamentApi(getConfig(props.token)).skipIntroductionVideo();
			} catch (e) {
				// console.log("e:", e);
			}

			props.onClose();
			props.dispatch(decrementLoading());
		} else {
			props.onClose();
		}
	}

	return (skipOpeningVideo == null || skipOpeningVideo) ? null : (
		<Modal
			isOpen={props.isOpen}
			fade={true}
			centered={true}
			contentClassName="d-flex align-items-center justify-content-center bg-transparent border-0"
		>
			<iframe
				width={width}
				height={height}
				src="https://www.youtube.com/embed/Efo3js0nz10?autoplay=1"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen={false}
			/>

			<div
				style={{width}}
				className="bg-white p-2"
			>
				<div className="d-flex flex-column align-items-center">
					<p className="mb-1">
						<input
							type="checkbox"
							name="dontShowInstructionsAgain"
							id="dontShowInstructionsAgain"
							checked={checked}
							onChange={toggleChecked}
						/>
						<label htmlFor="dontShowInstructionsAgain">Don't show again</label>
					</p>

					<Button
						color="lightBlue"
						size="sm"
						className="w-100"
						onClick={onDismiss}
					>
						Dismiss
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(ScoringInstructionModal);
