"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * IScoreGolfPlus Development
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.56-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPairedTournamentAssetsResponseToJSON = exports.GetPairedTournamentAssetsResponseFromJSONTyped = exports.GetPairedTournamentAssetsResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function GetPairedTournamentAssetsResponseFromJSON(json) {
    return GetPairedTournamentAssetsResponseFromJSONTyped(json, false);
}
exports.GetPairedTournamentAssetsResponseFromJSON = GetPairedTournamentAssetsResponseFromJSON;
function GetPairedTournamentAssetsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'banner': !runtime_1.exists(json, 'banner') ? undefined : _1.AssetFromJSON(json['banner']),
        'animatedLeaderboardBanner': !runtime_1.exists(json, 'animatedLeaderboardBanner') ? undefined : _1.AssetFromJSON(json['animatedLeaderboardBanner']),
        'scoreCardAd': !runtime_1.exists(json, 'scoreCardAd') ? undefined : _1.AssetFromJSON(json['scoreCardAd']),
        'timedAds': !runtime_1.exists(json, 'timedAds') ? undefined : (runtime_1.mapValues(json['timedAds'], _1.AssetFromJSON)),
        'selectedHoles': !runtime_1.exists(json, 'selectedHoles') ? undefined : json['selectedHoles'],
    };
}
exports.GetPairedTournamentAssetsResponseFromJSONTyped = GetPairedTournamentAssetsResponseFromJSONTyped;
function GetPairedTournamentAssetsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'banner': _1.AssetToJSON(value.banner),
        'animatedLeaderboardBanner': _1.AssetToJSON(value.animatedLeaderboardBanner),
        'scoreCardAd': _1.AssetToJSON(value.scoreCardAd),
        'timedAds': value.timedAds === undefined ? undefined : (runtime_1.mapValues(value.timedAds, _1.AssetToJSON)),
        'selectedHoles': value.selectedHoles,
    };
}
exports.GetPairedTournamentAssetsResponseToJSON = GetPairedTournamentAssetsResponseToJSON;
