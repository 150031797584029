import React, {ReactNode, ReactNodeArray} from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {Leaderboard, LeaderboardCollection} from "client";
import {ILeaderboardCollectionNoTournamentDetails} from "../pages/LeaderBoardPage";

interface IProps {
	currentGame: keyof LeaderboardCollection;
	leaderboards: ILeaderboardCollectionNoTournamentDetails;
	isOpen: boolean;
	onClose: () => void;
	onSelect: (selection: keyof LeaderboardCollection) => void;
}

const ToggleLeaderboardModal: React.FC<IProps> = (props) => {

	function makeButtons(boards: ILeaderboardCollectionNoTournamentDetails): ReactNode {
		if (!boards) {
			return null;
		}

		return Object.entries(boards).map((board: [string, Leaderboard]) => {
			if (!board[1]) {
				return null;
			}

			const isSelected: boolean = props.currentGame === board[0];

			function selectHelper(): void {
				if (isSelected) {
					props.onClose();
				} else {
					props.onSelect(board[0] as keyof ILeaderboardCollectionNoTournamentDetails);
				}
			}

			return (
				<div
					key={`leaderboard-button_${board[0]}`}
					className="mb-4"
					style={{width: "75%"}}
				>
					<Button
						onClick={selectHelper}
						color={isSelected ? "darkBlue" : "lightBlue"}
						className="w-100 toggle-leaderboards-button"
					>
						{board[1].header?.toggleButton}
					</Button>
				</div>
			);
		})
	}

	return (
		<Modal
			isOpen={props.isOpen}
			centered={true}
			fade={true}
			toggle={props.onClose}
		>
			<ModalHeader toggle={props.onClose}>Select Leaderboard</ModalHeader>

			<ModalBody>
				<div className="d-flex flex-column align-items-center">
					{makeButtons(props.leaderboards)}
				</div>
			</ModalBody>
		</Modal>
	);
};

export default ToggleLeaderboardModal;
